import { axiosInstance as axios } from "@/plugins/axios";

const railway = {
  find(include = "") {
    return include
      ? axios.get(`/railway`, { params: { include } })
      : axios.get(`/railway`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/railway", data, { params: { include } })
      : axios.post("/railway", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/railway/${id}`, data, { params: { include } })
      : axios.patch(`/railway/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/railway/${id}`);
  },
};

export default railway;
