/**
 * This file contains unified api methods for all lists (metro, railway, project_statuses, etc.).
 * Backend have corresponding controller that handles CRUD operations for all lists.
 */

import { axiosInstance as axios } from "@/plugins/axios";

const lists = {
  findBatchForEntity(entity) {
    return axios.get(`/lists/batch/${entity}`);
  },

  subjectsOfRussia() {
    return axios.get("/lists/other/subjects-of-russia");
  },
};

export default lists;
