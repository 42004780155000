<template>
  <div class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2">
    <ValidationObserver ref="observer" slim>
      <form
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        autocorrect="off"
      >
        <fieldset :disabled="awaitResponse" class="no-border q-px-none">
          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Название</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Название"
                rules="required|min:2|max:191"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="name"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>

            <div class="col-9 col-md-6 text-grey-8">Коды</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Коды"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <q-select
                  v-model="codes"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  multiple
                  use-input
                  use-chips
                  new-value-mode="add-unique"
                  label="Коды"
                />
              </ValidationProvider>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>

    <div class="row">
      <q-btn
        label="Добавить"
        color="primary"
        :loading="awaitCreating"
        :disable="awaitCreating"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "BizRegionAdd",

    meta() {
      return {
        title: this.title,
      };
    },

    data() {
      return {
        awaitResponse: false,
        awaitCreating: false,
        name: null,
        codes: null,
      };
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitCreating = true;

            let data = {
              name: this.name,
              codes: this.codes,
            };

            this.$api.bizRegion
              .create(data)
              .then(
                (res) => {
                  this.$q.notify({
                    color: "positive",
                    message: res.data.message,
                  });

                  this.$router.push({ name: "BizRegionList" });
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });

                  if (error.response.status === 422) {
                    Object.keys(error.response.data.errors).forEach(
                      (name, i) => {
                        this.$q.notify({
                          color: "negative",
                          message: error.response.data.errors[name][i],
                          timeout: 10000,
                        });
                      }
                    );
                  }
                }
              )
              .then(() => {
                this.awaitCreating = false;
              });
          }
        });
      },
    },
  };
</script>