<template>
  <div class="row q-col-gutter-md">
    <div v-for="item in cadastrals" class="col-24 col-md-8">
      <div class="rounded-borders shadow-2 bg-white q-pa-md">
        <div class="row q-mb-md">
          <div class="col-16 flex items-center">
            <a
              v-if="!checkCoordsIsEmpty(coordinates)"
              :href="getPkkLink(item)"
              class="l-link"
              target="_blank"
              title="Показать на карте PKK"
            >
              {{ item.number }}
            </a>
            <span v-else>{{ item.number }}</span>
          </div>
          <div class="col-8 flex justify-end">
            <q-btn
              :disable="awaitResponse"
              dense
              flat
              icon="mdi-delete-outline"
              round
              title="Удалить"
              @click="remove(item)"
            />
          </div>
        </div>

        <div class="flex column">
          <div
            v-for="column in columns"
            class="row flex items-center q-py-xs"
            style="border-bottom: 1px solid lightgray"
          >
            <div class="col-24 col-md-6 text-body3 text-grey-8">
              {{ column.title }}
            </div>
            <div class="col-24 col-md-18 text-body2 flex items-center q-pl-md">
              {{ column.formatFn(item[column.field]) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-24 col-md-8">
      <div class="rounded-borders shadow-2 bg-white q-pa-md">
        <q-input
          v-model.trim="tempCadastral"
          bottom-slots
          label="Кадастровый номер"
        />

        <q-btn
          :disable="awaitResponse"
          color="primary"
          icon="mdi-plus"
          label="Добавить"
          @click="addCadastral(tempCadastral)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { rubFormat, numberFormat } from "@/utils/numberFormatter";
  import { normalizeNumber } from "@/utils/batch";
  import { coordsToString } from "@/utils/coords";
  import cloneDeep from "lodash.clonedeep";

  export default {
    name: "ProjectCadastrals",

    props: {
      cadastralData: {
        type: Array,
        default: [],
      },
      coordinates: {
        type: Array,
        required: false,
      },
    },

    data() {
      return {
        awaitResponse: false,
        cadastrals: [],
        tempCadastral: null,
        columns: [
          {
            title: "Статус",
            field: "status",
            formatFn: (val) => (!val ? "-" : val),
          },
          {
            title: "Адрес",
            field: "address",
            formatFn: (val) => (!val ? "-" : val),
          },
          {
            title: "Категория земель",
            field: "category",
            formatFn: (val) => (!val ? "-" : val),
          },
          {
            title: "Форма собственности",
            field: "ownership_type",
            formatFn: (val) => (!val ? "-" : val),
          },
          {
            title: "Стоимость",
            field: "price",
            formatFn: (val) => (!val ? "-" : rubFormat(val)),
          },
          {
            title: "Площадь, м²",
            field: "square",
            formatFn: (val) => (!val ? "-" : numberFormat(val)),
          },
          {
            title: "Разрешенное использование",
            field: "permitted_usage",
            formatFn: (val) => (!val ? "-" : val),
          },
          {
            title: "Разрешенное использование уточненное",
            field: "permitted_usage_ext",
            formatFn: (val) => (!val ? "-" : val),
          },
        ],
      };
    },

    created() {
      this.cadastrals = cloneDeep(this.cadastralData);
    },

    methods: {
      addCadastral(val) {
        const cadastralRegex = /^[0-9]{2}:[0-9]{2}:[0-9]{6,7}:[0-9]{1,35}$/;

        if (!cadastralRegex.test(val)) {
          this.$q.notify({
            color: "negative",
            message: "Неверный формат кадастра.",
          });

          return;
        }

        if (!this.cadastrals) {
          this.cadastrals = [];
        }

        let item = {
          number: val,
          polygon: null,
          status: null,
          address: null,
          category: null,
          ownership_type: null,
          price: null,
          square: null,
          permitted_usage: null,
          permitted_usage_ext: null,
          meta: null,
        };

        this.cadastrals.push(item);
        this.updateProjectCadastrals();
        this.tempCadastral = null;
      },

      remove(item) {
        const index = this.cadastrals.indexOf(item);

        this.cadastrals.splice(index, 1);

        this.updateProjectCadastrals();
      },

      updateProjectCadastrals() {
        this.$emit("update-project-cadastrals", {
          cadastrals: this.cadastrals,
        });
      },

      /**
       * @param {Array} coords
       * @return {boolean}
       */
      checkCoordsIsEmpty(coords) {
        return !Boolean(coords && coords[0] && coords[1]);
      },

      getPkkLink(item) {
        const text = encodeURIComponent(item.number);
        const opened = encodeURIComponent(normalizeNumber(item.number));
        const coords = coordsToString(cloneDeep(this.coordinates), true);

        return `https://pkk.rosreestr.ru/#/search/${coords}/17/@470200?text=${text}&type=1&opened=${opened}`;
      },
    },
  };
</script>