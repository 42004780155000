import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import api from "./api";
import { canAbility } from "./utils/ability";
import centrifuge from "./plugins/centrifuge";

import BlankLayout from "./layouts/BlankLayout";
import MainLayout from "./layouts/MainLayout";

Vue.component("blank-layout", BlankLayout);
Vue.component("main-layout", MainLayout);

// other plugins
import "./plugins/quasar";
import "./plugins/axios";
import "./plugins/sentry";
import "./plugins/veeValidate";
import "./plugins/localforage";
import "./plugins/filters";
import "./plugins/lodash";
import "./plugins/qMarkdown";

// config
Vue.config.productionTip = false;

// custom properties
Vue.prototype.$api = api;
Vue.prototype.$can = canAbility;
Vue.prototype.$centrifuge = centrifuge;

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
