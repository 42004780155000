<template>
  <q-dialog
    ref="dialog"
    maximized
    transition-hide="slide-up"
    transition-show="slide-down"
  >
    <q-card>
      <div class="flex items-center justify-between q-px-md">
        <div>Детализированная история на {{ history.created_at }}</div>
        <q-btn v-close-popup flat icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <div class="row q-col-gutter-x-md">
          <div class="col-24 col-sm-8">
            <span>
              Слепок
              <q-icon
                class="q-ml-sm"
                color="primary"
                name="mdi-information-outline"
                size="sm"
              />
              <q-tooltip>
                Служит в качестве единого универсального и детализированного
                отображения исторических данных.<br />
                Отображает данные как они есть в базе данных.
              </q-tooltip>
            </span>
            <div
              class="shadow-3 q-mt-md q-pa-md"
              style="white-space: pre; overflow-y: auto; max-height: 800px"
            >
              {{ history.dump }}
            </div>
          </div>

          <div class="col-24 col-sm-8">
            <span>
              Форматированный слепок
              <q-icon
                class="q-ml-sm"
                color="primary"
                name="mdi-information-outline"
                size="sm"
              />
              <q-tooltip>
                Отображение текущего форматированного слепка.<br />
                В форматированное представление слепка попадает только то, что
                нас интересует больше всего.
              </q-tooltip>
            </span>
            <div
              class="shadow-3 q-mt-md q-pa-md"
              style="white-space: pre; overflow-y: auto; max-height: 800px"
            >
              {{ history.formatted }}
            </div>
          </div>

          <div class="col-24 col-sm-8">
            <span>
              Разница форматированных слепков (БЫЛО)
              <q-icon
                class="q-ml-sm"
                color="primary"
                name="mdi-information-outline"
                size="sm"
              />
              <q-tooltip>
                Отображение изменений из предыдущего по дате форматированного
                слепка.
              </q-tooltip>
            </span>
            <div
              class="shadow-3 q-mt-md q-pa-md"
              style="white-space: pre; overflow-y: auto; max-height: 800px"
            >
              {{ history.formatted_diff_old }}
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "ProjectHistoryDetailsDialog",

    props: {
      history: {
        type: Object,
        required: true,
      },
    },

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },
    },
  };
</script>