import { axiosInstance as axios } from "@/plugins/axios";

const permission = {
  find() {
    return axios.get("/permissions");
  },

  create(data) {
    return axios.post("/permissions", data);
  },

  delete(id) {
    return axios.delete(`/permissions/${id}`);
  },
};

export default permission;
