<template>
    <div v-if="!loading">
        <ValidationObserver ref="observer" slim>
            <q-table
                    :columns="columns"
                    :visible-columns="visibleColumns"
                    :data="listData"
                    :filter="filter"
                    :loading="loading"
                    :pagination.sync="pagination"
                    dense
                    row-key="id"
                    class="q-py-sm"
            >
                <template v-slot:top-left="props">
                    <q-btn
                            v-if="+$can([entity + '.add'])"
                            color="positive"
                            icon="mdi-plus"
                            label="Добавить"
                            @click="isDialogOpen = true"
                    />
                </template>

                <template v-slot:top-right>
                    <q-input v-model="filter" debounce="300" dense outlined>
                        <template v-slot:append>
                            <q-icon name="mdi-magnify" />
                        </template>
                    </q-input>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td
                                v-if="visibleColumns.includes('name')"
                                key="name"
                                :props="props"
                        >
                            {{ props.row.name }}
                            <q-popup-edit
                                    v-if="+$can([entity + '.update'])"
                                    v-model="props.row.name"
                                    :disable="awaitSaving"
                                    buttons
                                    @save="update(props.row)"
                            >
                                <q-input v-model="props.row.name" autofocus dense />
                            </q-popup-edit>
                        </q-td>

                        <q-td
                                v-if="visibleColumns.includes('alias')"
                                key="alias"
                                :props="props"
                        >
                            {{ props.row.alias }}
                            <q-popup-edit
                                    v-if="+$can([entity + '.update'])"
                                    v-model="props.row.alias"
                                    :disable="awaitSaving"
                                    buttons
                                    @save="update(props.row)"
                            >
                                <q-input v-model="props.row.alias" autofocus dense />
                            </q-popup-edit>
                        </q-td>

                        <q-td
                                v-if="visibleColumns.includes('region_code')"
                                key="region_code"
                                :props="props"
                        >
                            {{ props.row.region_code }}
                            <q-popup-edit
                                    v-if="+$can([entity + '.update'])"
                                    v-model="props.row.region_code"
                                    :disable="awaitSaving"
                                    buttons
                                    @save="update(props.row)"
                            >
                                <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Код региона"
                                        rules="required|min:1|max:100"
                                >
                                    <q-select
                                            v-model="props.row.region_code"
                                            :options="$store.getters['auth/user'].region_codes"
                                            :error="errors && !!errors.length"
                                            :error-message="errors[0]"
                                            autofocus
                                            bottom-slots
                                            dense
                                            label="Код региона"
                                    />
                                </ValidationProvider>
                            </q-popup-edit>
                        </q-td>

                        <q-td
                                v-if="visibleColumns.includes('order')"
                                key="order"
                                :props="props"
                        >
                            {{ props.row.order }}
                            <q-popup-edit
                                    v-if="+$can([entity + '.update'])"
                                    v-model.number="props.row.order"
                                    :disable="awaitSaving"
                                    buttons
                                    @save="update(props.row)"
                            >
                                <q-input
                                        v-model="props.row.order"
                                        autofocus
                                        dense
                                        type="number"
                                />
                            </q-popup-edit>
                        </q-td>

                        <q-td
                                v-if="visibleColumns.includes('actions')"
                                key="actions"
                                :props="props"
                        >
                            <q-btn
                                    v-if="+$can([entity + '.delete'])"
                                    :disable="awaitDeleting"
                                    flat
                                    icon="mdi-delete-outline"
                                    @click="remove(props.row)"
                            />
                        </q-td>
                    </q-tr>
                </template>
            </q-table>

            <q-dialog
                    v-if="+$can([entity + '.add'])"
                    v-model="isDialogOpen"
                    position="top"
            >
                <q-card style="width: 500px">
                    <q-card-section>
                        <ValidationProvider
                                v-if="visibleColumns.includes('name')"
                                v-slot="{ errors }"
                                name="Значение"
                                rules="required|min:1|max:100"
                        >
                            <q-input
                                    v-model="newItemName"
                                    :error="errors && !!errors.length"
                                    :error-message="errors[0]"
                                    autofocus
                                    bottom-slots
                                    dense
                                    label="Значение"
                            />
                        </ValidationProvider>

                        <ValidationProvider
                                v-if="visibleColumns.includes('region_code')"
                                v-slot="{ errors }"
                                name="Код региона"
                                rules="required|min:1|max:100"
                        >
                            <q-select
                                    v-model="newItemRegionCode"
                                    :options="$store.getters['auth/user'].region_codes"
                                    :error="errors && !!errors.length"
                                    :error-message="errors[0]"
                                    bottom-slots
                                    dense
                                    label="Код региона"
                            />
                        </ValidationProvider>
                    </q-card-section>

                    <q-separator />

                    <q-card-actions>
                        <q-btn
                                :disable="awaitSaving"
                                :loading="awaitSaving"
                                color="primary"
                                flat
                                label="Сохранить"
                                @click="create"
                        />
                    </q-card-actions>
                </q-card>
            </q-dialog>
        </ValidationObserver>
    </div>
</template>

<script>
import { camelCase } from "@/utils/batch";

const ENTITY_HAS_REGION_CODE = [
    "area",
    "city-area",
    "city-district",
    "city",
    "metro",
    "railway",
    "region",
    "specific-region",
];

const ENTITY_HAS_ALIAS = ["specific-region"];

export default {
    name: "ListsTable",

    props: {
        entity: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            loading: true,
            awaitSaving: false,
            awaitDeleting: false,
            isDialogOpen: false,
            listData: [],
            filter: "",
            newItemName: null,
            newItemRegionCode: null,
            columns: [
                {
                    name: "name",
                    required: true,
                    label: "Значение",
                    align: "left",
                    field: (row) => row.name,
                    sortable: true,
                    visible: true,
                },
                {
                    name: "alias",
                    label: "Алиас",
                    align: "left",
                    field: (row) => row.alias,
                    sortable: true,
                    visible: ENTITY_HAS_ALIAS.includes(this.entity),
                },
                {
                    name: "region_code",
                    label: "Код региона",
                    align: "left",
                    field: (row) => row.region_code,
                    sortable: true,
                    visible: ENTITY_HAS_REGION_CODE.includes(this.entity),
                },
                {
                    name: "order",
                    label: "Порядок",
                    align: "left",
                    field: (row) => row.order,
                    sortable: true,
                    visible: true,
                },
                {
                    name: "actions",
                    label: "Действия",
                    align: "right",
                    visible: true,
                },
            ],
            visibleColumns: ["name", "alias", "region_code", "order", "actions"],
            pagination: {
                rowsPerPage: 15,
            },
        };
    },

    computed: {
        entityCamelCase() {
            return camelCase(this.entity);
        },
    },

    async mounted() {
        this.visibleColumns = this.columns
            .filter((column) => column.visible)
            .map((i) => i.name);

        await this.setListData();
    },

    methods: {
        async setListData() {
            const res = await this.$api[this.entityCamelCase].find();
            this.listData = res.data;
            this.loading = false;
        },

        create() {
            this.$refs.observer.validate().then((valid) => {
                if (valid) {
                    this.awaitSaving = true;

                    let data = {
                        name: this.newItemName,
                        region_code: this.newItemRegionCode,
                    };

                    this.$api[this.entityCamelCase]
                        .create(data)
                        .then(
                            (res) => {
                                if (res.status === 200) {
                                    this.listData.push(res.data.entity);
                                }

                                this.isDialogOpen = false;
                                this.newItemName = this.newItemRegionCode = null;
                            },
                            (error) => {
                                this.$q.notify({
                                    color: "negative",
                                    message: error.response.data.message,
                                });

                                if (
                                    error.response.status === 422 &&
                                    error.response.data.errors
                                ) {
                                    Object.keys(error.response.data.errors).forEach(
                                        (name, i) => {
                                            this.$q.notify({
                                                color: "negative",
                                                message: error.response.data.errors[name][i],
                                                timeout: 5000,
                                            });
                                        }
                                    );
                                }
                            }
                        )
                        .then(() => {
                            this.awaitSaving = false;
                        });
                }
            });
        },

        update(row) {
            this.awaitSaving = true;

            let data = {
                name: row.name,
                alias: row.alias,
                order: row.order,
                region_code: row.region_code,
            };

            if (this.regionCodeVisible) {
                data.region_code = row.region_code;
            }

            this.$api[this.entityCamelCase]
                .update(row.id, data)
                .then(
                    (res) => {
                        this.$q.notify({
                            color: "positive",
                            message: res.data.message,
                        });
                    },
                    (error) => {
                        this.$q.notify({
                            color: "negative",
                            message: error.response.data.message,
                        });
                    }
                )
                .then(() => {
                    this.awaitSaving = false;
                });
        },

        remove(row) {
            this.awaitDeleting = true;

            this.$api[this.entityCamelCase]
                .delete(row.id)
                .then(
                    (res) => {
                        const index = this.listData.indexOf(row);
                        this.listData.splice(index, 1);
                    },
                    (error) => {
                        this.$q.notify({
                            color: "negative",
                            message: error.response.data.message,
                        });
                    }
                )
                .then(() => {
                    this.awaitDeleting = false;
                });
        },
    },
};
</script>