import { axiosInstance as axios } from "@/plugins/axios";

const cityArea = {
  find(include = "") {
    return include
      ? axios.get(`/city-areas`, { params: { include } })
      : axios.get(`/city-areas`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/city-areas", data, { params: { include } })
      : axios.post("/city-areas", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/city-areas/${id}`, data, { params: { include } })
      : axios.patch(`/city-areas/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/city-areas/${id}`);
  },
};

export default cityArea;
