import { axiosInstance as axios } from "@/plugins/axios";

const projectStatus = {
  find(include = "") {
    return include
      ? axios.get(`/project-statuses`, { params: { include } })
      : axios.get(`/project-statuses`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/project-statuses", data, { params: { include } })
      : axios.post("/project-statuses", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/project-statuses/${id}`, data, { params: { include } })
      : axios.patch(`/project-statuses/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/project-statuses/${id}`);
  },
};

export default projectStatus;
