<template>
  <div class="col col-md-10 shadow-2 bg-white">
    <div class="q-pa-md">
      <ValidationObserver ref="observer" slim>
        <form
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
        >
          <fieldset :disabled="awaitSaving" class="no-border q-px-none">
            <ValidationProvider
              v-slot="{ errors }"
              name="Название"
              rules="required|max:100"
            >
              <q-input
                v-model="name"
                :error="errors && !!errors.length"
                :error-message="errors[0]"
                autofocus
                bottom-slots
                hint="например, entity.action.some.value"
                label="Название"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Аннотация"
              rules="required|max:100"
            >
              <q-input
                v-model="readable_name"
                :error="errors && !!errors.length"
                :error-message="errors[0]"
                bottom-slots
                label="Аннотация"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Описание"
              rules="max:200"
            >
              <q-input
                v-model="description"
                :error="errors && !!errors.length"
                :error-message="errors[0]"
                bottom-slots
                label="Описание"
                outlined
                type="textarea"
              />
            </ValidationProvider>
          </fieldset>
        </form>
      </ValidationObserver>

      <q-btn
        :disable="awaitSaving"
        :loading="awaitSaving"
        class="full-width"
        color="primary"
        icon="mdi-content-save"
        label="Сохранить"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "PermissionAdd",

    meta: {
      title: "Добавить разрешение",
    },

    data() {
      return {
        awaitSaving: false,
        readable_name: null,
        name: null,
        description: null,
      };
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            this.$api.permission
              .create({
                readable_name: this.readable_name,
                name: this.name,
                description: this.description,
              })
              .then(
                (res) => {
                  if (res.status === 201) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },
    },
  };
</script>