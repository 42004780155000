<template>
  <ValidationObserver ref="observer" slim>
    <div class="row q-pb-lg justify-center">
      <div class="col-24">
        <ProjectHeader :project="project" :viewers="viewers">
          <template v-slot:buttons>
            <q-btn
              v-if="+$can(['address.update'])"
              :disable="awaitSaving"
              :loading="awaitSaving"
              class="q-ml-md"
              color="positive"
              icon="mdi-content-save"
              label="Сохранить Местоположение"
              no-caps
              @click="save"
            />
          </template>
        </ProjectHeader>

        <q-tabs
          v-model="tab"
          active-color="primary"
          align="left"
          class="text-black q-mb-md"
          dense
          indicator-color="primary"
          narrow-indicator
        >
          <q-tab
            :ripple="false"
            class="q-px-none q-mr-md"
            label="Основное"
            name="common"
          />
          <q-tab
            :ripple="false"
            class="q-px-none"
            label="Кадастры"
            name="cadastrals"
          />
        </q-tabs>
      </div>

      <div class="col-24">
        <div v-show="tab === 'common'" class="row q-col-gutter-x-md">
          <div class="col-24 col-md-11">
            <div class="rounded-borders shadow-2 q-pa-md q-mb-md bg-white">
              <form
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
              >
                <fieldset
                  :disabled="awaitSaving || awaitGeocoding || awaitResponse"
                  class="no-border q-pa-none q-ma-none"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Адрес"
                    rules="required|max:100"
                  >
                    <q-input
                      v-model="address.name"
                      :error="errors && !!errors.length"
                      :error-message="errors[0]"
                      bottom-slots
                      dense
                      label="Адрес"
                    />
                  </ValidationProvider>

                  <div class="col-24">
                    <q-select
                      v-model="address.federal_region"
                      :options="federalRegions"
                      bottom-slots
                      clearable
                      dense
                      label="Федеральный округ"
                      option-label="name"
                      option-value="id"
                      options-dense
                    />
                  </div>

                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <q-select
                        v-model="address.region"
                        :options="regions"
                        bottom-slots
                        clearable
                        dense
                        label="Регион"
                        option-label="name"
                        option-value="id"
                        options-dense
                      >
                        <template v-slot:option="scope">
                          <q-item
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                          >
                            <q-item-section>
                              <q-item-label>
                                <span>{{ scope.opt.name }}</span>
                                <span class="text-body2 text-grey-5"
                                  >&nbsp код: {{ scope.opt.region_code }}</span
                                >
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Локация"
                        rules="required"
                      >
                        <q-select
                          v-model="address.specific_region"
                          :options="specificRegions"
                          :error="errors && !!errors.length"
                          :error-message="errors[0]"
                          bottom-slots
                          dense
                          label="Локация"
                          option-label="name"
                          option-value="id"
                          options-dense
                        >
                          <template v-slot:option="scope">
                            <q-item
                              v-bind="scope.itemProps"
                              v-on="scope.itemEvents"
                            >
                              <q-item-section>
                                <q-item-label>
                                  <span>{{ scope.opt.name }}</span>
                                  <span class="text-body2 text-grey-5"
                                    >&nbsp регион:
                                    {{ scope.opt.region_code }}</span
                                  >
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                          </template>
                        </q-select>
                      </ValidationProvider>
                    </div>

                    <div class="col-24">
                      <q-select
                        v-model="address.area"
                        :options="areas"
                        bottom-slots
                        clearable
                        dense
                        label="Городской округ"
                        option-label="name"
                        option-value="id"
                        options-dense
                        use-input
                        @filter="filterAreas"
                      >
                        <template v-slot:option="scope">
                          <q-item
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                          >
                            <q-item-section>
                              <q-item-label>
                                <span>{{ scope.opt.name }}</span>
                                <span class="text-body2 text-grey-5"
                                  >&nbsp регион:
                                  {{ scope.opt.region_code }}</span
                                >
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-24">
                      <q-select
                        v-model="address.city"
                        :options="cities"
                        bottom-slots
                        clearable
                        dense
                        label="Город"
                        option-label="name"
                        option-value="id"
                        options-dense
                        use-input
                        @filter="filterCities"
                      >
                        <template v-slot:option="scope">
                          <q-item
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                          >
                            <q-item-section>
                              <q-item-label>
                                <span>{{ scope.opt.name }}</span>
                                <span class="text-body2 text-grey-5"
                                  >&nbsp регион:
                                  {{ scope.opt.region_code }}</span
                                >
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div
                      v-if="project.biz_region.settings.address_city_area"
                      class="col-24"
                    >
                      <q-select
                        v-model="address.city_area"
                        :options="cityAreas"
                        bottom-slots
                        clearable
                        dense
                        label="Административный округ"
                        option-label="name"
                        option-value="id"
                        options-dense
                      >
                        <template v-slot:option="scope">
                          <q-item
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                          >
                            <q-item-section>
                              <q-item-label>
                                <span>{{ scope.opt.name }}</span>
                                <span class="text-body2 text-grey-5"
                                  >&nbsp регион:
                                  {{ scope.opt.region_code }}</span
                                >
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-24">
                      <q-select
                        v-model="address.city_district"
                        :options="cityDistricts"
                        bottom-slots
                        clearable
                        dense
                        label="Район"
                        option-label="name"
                        option-value="id"
                        options-dense
                        use-input
                        @filter="filterCityDistricts"
                      >
                        <template v-slot:option="scope">
                          <q-item
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                          >
                            <q-item-section>
                              <q-item-label>
                                <span>{{ scope.opt.name }}</span>
                                <span class="text-body2 text-grey-5"
                                  >&nbsp регион:
                                  {{ scope.opt.region_code }}</span
                                >
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Дом"
                        rules="max:10"
                      >
                        <q-input
                          v-model="address.house"
                          :error="errors && !!errors.length"
                          :error-message="errors[0]"
                          bottom-slots
                          dense
                          label="Дом"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="col-12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Участок"
                        rules="max:10"
                      >
                        <q-input
                          v-model="address.land"
                          :error="errors && !!errors.length"
                          :error-message="errors[0]"
                          bottom-slots
                          dense
                          label="Участок"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>

            <div
              v-if="
                project.biz_region.settings.metro ||
                project.biz_region.settings.railway
              "
              class="rounded-borders shadow-2 q-pa-md bg-white q-mb-md"
            >
              <form
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
              >
                <fieldset
                  :disabled="awaitSaving || awaitResponse"
                  class="no-border q-pa-none q-ma-none"
                >
                  <q-select
                    v-if="project.biz_region.settings.metro"
                    v-model="metroSelected"
                    :option-value="(opt) => opt"
                    :options="metro"
                    filled
                    hide-dropdown-icon
                    label="Станции метро"
                    multiple
                    options-dense
                    use-chips
                    use-input
                    @filter="filterMetro"
                  >
                    <template v-slot:option="scope">
                      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                        <q-item-section avatar>
                          <div
                            :style="getBgColorStyleForMetroLine(scope.opt)"
                            class="l-metro-line-circle"
                          />
                        </q-item-section>
                        <q-item-section>
                          <q-item-label>
                            {{ scope.opt.name
                            }}<span v-if="scope.opt.short_name"
                              >&nbsp({{ scope.opt.short_name }})</span
                            >
                          </q-item-label>
                          <q-item-label caption
                            >{{ scope.opt.line }}
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-icon
                            v-if="scope.opt.status === 'строится'"
                            name="mdi-sign-caution"
                            title="Строится"
                          />
                        </q-item-section>
                      </q-item>
                    </template>

                    <template v-slot:selected-item="scope">
                      <q-chip
                        :tabindex="scope.tabindex"
                        color="secondary"
                        outline
                        removable
                        @remove="scope.removeAtIndex(scope.index)"
                      >
                        <div
                          :style="getBgColorStyleForMetroLine(scope.opt)"
                          class="l-metro-line-circle q-mr-sm"
                        />
                        {{ scope.opt.name | shortness(30) }}
                        <span v-if="scope.opt.short_name" class="q-ml-sm"
                          >({{ scope.opt.short_name }})</span
                        >
                        <q-icon
                          v-if="scope.opt.status === 'строится'"
                          class="q-ml-sm"
                          name="mdi-sign-caution"
                          title="Строится"
                        />
                      </q-chip>
                    </template>
                  </q-select>

                  <q-select
                    v-if="project.biz_region.settings.railway"
                    v-model="railwaySelected"
                    :option-value="(opt) => opt"
                    :options="railway"
                    filled
                    hide-dropdown-icon
                    label="ЖД станции"
                    multiple
                    option-label="name"
                    options-dense
                    use-chips
                    use-input
                    @filter="filterRailway"
                  >
                    <template v-slot:selected-item="scope">
                      <q-chip
                        :tabindex="scope.tabindex"
                        color="secondary"
                        outline
                        removable
                        @remove="scope.removeAtIndex(scope.index)"
                      >
                        {{ scope.opt.name | shortness(30) }}
                      </q-chip>
                    </template>
                  </q-select>
                </fieldset>
              </form>
            </div>
          </div>

          <div class="col-24 col-md-13">
            <div class="q-pa-md rounded-borders bg-white shadow-2 q-mb-md">
              <form
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
              >
                <fieldset
                  :disabled="awaitSaving || awaitGeocoding || awaitResponse"
                  class="no-border q-pa-none q-ma-none"
                >
                  <div class="row q-col-gutter-x-md items-center">
                    <div class="col">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="max:100"
                        name="Координаты"
                      >
                        <q-input
                          v-model="coordinatesString"
                          :error="errors && !!errors.length"
                          :error-message="errors[0]"
                          debounce="500"
                          dense
                          hide-bottom-space
                          hint="широта, долгота"
                          label="Координаты"
                          @input="onInputCoordinatesString"
                        >
                          <template v-slot:after>
                            <q-btn
                              dense
                              flat
                              icon="mdi-content-duplicate"
                              title="Скопировать реверсивные координаты"
                              @click="
                                copyCoordinatesReversed(coordinatesString)
                              "
                            />
                          </template>
                        </q-input>
                      </ValidationProvider>
                    </div>

                    <div v-if="+$can(['address.update'])" class="col">
                      <div class="flex justify-between items-center">
                        <q-btn
                          :disable="isRefreshAddressByCoordinatesBtnDisabled"
                          :loading="awaitGeocoding"
                          icon="mdi-find-replace"
                          text-color="grey-9"
                          title="Обновить адрес по координатам через сервис геокодирования"
                          @click="refreshAddressByCoordinates"
                        />

                        <q-btn
                          :disable="awaitGeocoding || denySendGeocodingRequest"
                          :loading="awaitGeocoding"
                          icon="mdi-map-marker"
                          label="Указать на карте"
                          text-color="grey-9"
                          title="Указать координаты точкой на карте через сервис геокодирования"
                          @click="specifyPointOnMap"
                        />

                        <q-btn
                          :color="isPolygonChanging ? 'red-6' : 'grey-7'"
                          dense
                          flat
                          icon="mdi-vector-square"
                          round
                          title="Нарисовать полигон участка на карте"
                          @click="toggleEditPolygon"
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>

            <div
              id="map-address"
              style="width: 100%; height: 600px; border: 1px solid lightgray"
            ></div>
          </div>
        </div>

        <ProjectCadastrals
          v-show="tab === 'cadastrals'"
          :cadastralData="project.cadastrals"
          :coordinates="project.address && project.address.coordinates"
          @update-project-cadastrals="onUpdateProjectCadastrals"
        />
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import ProjectHeader from "@/components/projects/ProjectHeader";
  import ProjectCadastrals from "@/components/projects/edit/ProjectCadastrals";
  import cloneDeep from "lodash.clonedeep";
  import { copyToClipboard } from "quasar";
  import { getPointHintContent } from "@/utils/map";
  import { METRO_LINE_COLOR_MAPPING, customSort } from "@/utils/batch";
  import {
    MOSCOW_CENTER_COORDINATES,
    coordsToArray,
    coordsToString,
  } from "@/utils/coords";

  // initial values for v-model's if address is empty
  const ADDRESS_DEFAULT_STATE = {
    name: "",
    house: "",
    land: "",
    region: null,
    specific_region: null,
    federal_region: null,
    area: null,
    city: null,
    city_area: null,
    city_district: null,
    coordinates: [null, null], // lng, lat
  };

  export default {
    name: "ProjectAddress",

    props: {
      project: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    components: {
      ProjectHeader,
      ProjectCadastrals,
    },

    data() {
      return {
        awaitResponse: false,
        awaitGeocoding: false,
        awaitSaving: false,
        isPolygonChanging: false,
        address: cloneDeep(ADDRESS_DEFAULT_STATE),
        countries: [],
        federalRegions: [],
        specificRegions: [],
        regions: [],
        areas: [],
        cities: [],
        cityAreas: [],
        cityDistricts: [],
        metro: [],
        railway: [],
        metroSelected: [],
        railwaySelected: [],
        nearProjects: [],
        coordinatesString: "",
        tab: "common",
      };
    },

    async created() {
      await Promise.all([this.setAddressLists(), this.setOtherLists()]);

      if (this.project.address) {
        this.address = cloneDeep(this.project.address);
      }

      if (this.project.transport_stations) {
        this.metroSelected = this.project.transport_stations
          .filter((item) => item.station.entity === "Metro")
          .map((item) => item.station);
        this.railwaySelected = this.project.transport_stations
          .filter((item) => item.station.entity === "Railway")
          .map((item) => item.station);
      }

      // dont move to mounted, coordinates will not initialize
      await this.initMap();

      if (
        this.project.address &&
        !this.checkCoordsIsEmpty(this.project.address.coordinates)
      ) {
        await this.findAndDrawNearProjects(
          this.project.address.coordinates[0],
          this.project.address.coordinates[1]
        );
      }
    },

    beforeDestroy() {
      this.project.address = cloneDeep(this.address);
    },

    computed: {
      mapControls() {
        return [
          "zoomControl",
          "rulerControl",
          "typeSelector",
          "fullscreenControl",
        ];
      },

      mapOptions() {
        return {
          maxZoom: 17,
          minZoom: 2,
        };
      },

      isRefreshAddressByCoordinatesBtnDisabled() {
        return (
          this.checkCoordsIsEmpty(this.address.coordinates) ||
          this.awaitGeocoding ||
          this.denySendGeocodingRequest
        );
      },

      denySendGeocodingRequest() {
        return Boolean(this.address.name);
      },
    },

    methods: {
      /**
       * @return {Promise}
       */
      initMap() {
        return new Promise((resolve) => {
          ymaps.ready(() => {
            // if user clicks menu fast
            if (!document.getElementById("map-address")) {
              return;
            }

            this.myMap = new ymaps.Map(
              "map-address",
              {
                center: MOSCOW_CENTER_COORDINATES,
                zoom: 10,
                controls: this.mapControls,
              },
              this.mapOptions
            );

            this.myNearPlacemarkGeoObjectCollection =
              new ymaps.GeoObjectCollection();
            this.myPlacemarkGeoObjectCollection =
              new ymaps.GeoObjectCollection();
            this.myPolygonGeoObjectCollection = new ymaps.GeoObjectCollection();
            this.myMap.geoObjects.add(this.myNearPlacemarkGeoObjectCollection);
            this.myMap.geoObjects.add(this.myPlacemarkGeoObjectCollection);
            this.myMap.geoObjects.add(this.myPolygonGeoObjectCollection);

            this.initPolygon();

            if (!this.checkCoordsIsEmpty(this.address.coordinates)) {
              this.updateCoordinates(this.address.coordinates);
            }

            resolve();
          });
        });
      },

      /**
       * @return {void}
       */
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            const coordsIsEmpty = this.checkCoordsIsEmpty(
              this.address.coordinates
            );

            const isPolygonEmpty =
              this.project.polygon &&
              this.project.polygon[0] &&
              !this.project.polygon[0].length;

            let addressData = {
              area_id: this.address.area && this.address.area.id,
              city_id: this.address.city && this.address.city.id,
              city_area_id: this.address.city_area && this.address.city_area.id,
              city_district_id:
                this.address.city_district && this.address.city_district.id,
              region_id: this.address.region && this.address.region.id,
              specific_region_id:
                this.address.specific_region && this.address.specific_region.id,
              federal_region_id:
                this.address.federal_region && this.address.federal_region.id,
              name: this.address.name,
              house: this.address.house,
              land: this.address.land,
              longitude: coordsIsEmpty ? null : this.address.coordinates[0],
              latitude: coordsIsEmpty ? null : this.address.coordinates[1],
              metro_ids: this.metroSelected.map((i) => i.id),
              railway_ids: this.railwaySelected.map((i) => i.id),
              polygon: !isPolygonEmpty ? this.project.polygon : null,
              cadastrals: this.project.cadastrals,
            };

            let include = [
              "address.region",
              "address.federal_region",
              "address.region",
              "address.specific_region",
              "address.area",
              "address.city",
              "address.city_area",
              "address.city_district",
              "transport_stations.station",
              "cadastrals",
            ];

            this.awaitSaving = true;

            return this.$api.project
              .updateAddress(this.project.id, addressData, include.join(","))
              .then((res) => {
                // default values for v-model
                if (!res.data.project.address.coordinates) {
                  res.data.project.address.coordinates = [null, null];
                }

                this.address = res.data.project.address;
                this.project.address = res.data.project.address;
                this.project.polygon = addressData.polygon;
                this.project.transport_stations =
                  res.data.project.transport_stations;

                this.isPolygonChanging = false;
                this.stopPolygonDrawingAndEditing();

                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              })
              .catch((error) => {
                this.$q.notify({
                  color: "negative",
                  message: error.response ? error.response.data.message : error,
                });

                if (
                  error.response.status === 422 &&
                  error.response.data.errors
                ) {
                  Object.keys(error.response.data.errors).forEach((name, i) => {
                    this.$q.notify({
                      color: "negative",
                      message: error.response.data.errors[name][i],
                      timeout: 5000,
                    });
                  });
                }
              })
              .finally(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      /**
       * @return {void}
       */
      resetMapState() {
        this.myNearPlacemarkGeoObjectCollection.removeAll();
        this.myPlacemarkGeoObjectCollection.removeAll();
        this.myPolygonGeoObjectCollection.removeAll();
        this.myMap.setCenter(MOSCOW_CENTER_COORDINATES);
        this.myMap.setZoom(10);
      },

      /**
       * @return {void}
       */
      async refreshAddressByCoordinates() {
        if (!this.checkCoordsIsEmpty(this.address.coordinates)) {
          await this.setAddressByCoordinates(
            this.address.coordinates[0],
            this.address.coordinates[1]
          );
        } else {
          this.$q.notify({
            color: "negative",
            message: "Сначала укажите широту и долготу.",
          });
        }
      },

      /**
       * @param {Number} longitude
       * @param {Number} latitude
       * @return {Promise<void>}
       */
      async setAddressByCoordinates(longitude, latitude) {
        if (this.denySendGeocodingRequest) {
          this.$q.notify({
            color: "negative",
            message: "При заполненном адресе запрос отправить нельзя.",
          });

          return;
        }

        let include = [
          "region",
          "federal_region",
          "region",
          "specific_region",
          "area",
          "city",
          "city_area",
          "city_district",
        ];

        this.awaitGeocoding = true;

        try {
          const res = await this.$api.address.geocodeByCoordinates(
            longitude,
            latitude,
            include.join(",")
          );

          // records in lists creating on the fly, so we need to update it to show new values in inputs
          await this.setAddressLists();

          this.address.name = res.data.name;
          this.address.house = res.data.house;
          this.address.land = res.data.land;
          this.address.postcode = res.data.postcode;
          this.address.area = res.data.area;
          this.address.city = res.data.city;
          this.address.city_area = res.data.city_area;
          this.address.city_district = res.data.city_district;
          this.address.federal_region = res.data.federal_region;
          this.address.region = res.data.region;
          this.address.coordinates = res.data.coordinates;

          await this.updateCoordinates([longitude, latitude]);
        } catch (error) {
          this.$q.notify({
            color: "negative",
            message: error.response.data.message,
          });
        }

        this.awaitGeocoding = false;
      },

      /**
       * @param {Number} longitude
       * @param {Number} latitude
       * @return {Promise<void>}
       */
      async findAndDrawNearProjects(longitude, latitude) {
        let options = {
          q: {
            coordinates: {
              longitude,
              latitude,
              max_distance: 1000,
            },
          },
        };

        const res = await this.$api.project.find(options, "address");

        if (res.status === 200) {
          this.nearProjects = res.data;
        }

        if (res.status === 204) {
          this.nearProjects = [];
        }

        this.drawNearProjects();
      },

      /**
       * @return {void}
       */
      drawNearProjects() {
        this.myNearPlacemarkGeoObjectCollection.removeAll();

        this.nearProjects.forEach((item) => {
          if (
            !(
              item.address &&
              item.address.coordinates &&
              item.address.coordinates.length
            )
          ) {
            return;
          }

          // dont override main project
          if (this.project.id === item.id) {
            return;
          }

          let placemark = this.createNearProjectPlacemark(item);

          this.myNearPlacemarkGeoObjectCollection.add(placemark);
        });
      },

      /**
       * @param {Object} item
       * @return Placemark
       */
      createNearProjectPlacemark(item) {
        const hintContent = getPointHintContent(
          item.name,
          item.address && item.address.name
        );
        const balloonContentBody = `
        <a type="button" href="/projects/${
          item.id || ""
        }/edit" target="_blank" class="l-link">Редактировать</a>`;

        return new ymaps.Placemark(
          [item.address.coordinates[0], item.address.coordinates[1]],
          {
            hintContent: hintContent,
            balloonContentBody: balloonContentBody,
          },
          {
            preset: "islands#icon",
            iconColor: "#0055d4",
          }
        );
      },

      /**
       * @return {void}
       */
      initPolygon() {
        let coordinates = !this.$_isEmpty(this.project.polygon)
          ? this.project.polygon
          : [];
        let polygon = this.createPolygon(coordinates);

        this.myPolygonGeoObjectCollection.add(polygon);
      },

      /**
       * @return {void}
       */
      moveMapViewToPlacemark() {
        if (this.myPlacemarkGeoObjectCollection.getBounds()) {
          this.myMap.setBounds(this.myPlacemarkGeoObjectCollection.getBounds());
          this.myMap.setZoom(16);
        }
      },

      /**
       * @param {Array} coordinates
       * @return Polygon
       */
      createPolygon(coordinates = []) {
        return new ymaps.Polygon(
          coordinates,
          {},
          {
            editorDrawingCursor: "crosshair",
            strokeWidth: 5,
            interactivityModel: "default#transparent",
          }
        );
      },

      /**
       * @return {void}
       */
      stopPolygonDrawingAndEditing() {
        this.myPolygonGeoObjectCollection.each((item) => {
          item.editor.stopDrawing();
          item.editor.stopEditing();
        });
      },

      /**
       * @param {Number} longitude
       * @param {Number} latitude
       * @return {void}
       */
      updatePlacemark(longitude, latitude) {
        let placemark = new ymaps.Placemark(
          [longitude, latitude],
          {},
          {
            preset: "islands#redIcon",
            draggable: false,
          }
        );

        this.myPlacemarkGeoObjectCollection.removeAll();
        this.myPlacemarkGeoObjectCollection.add(placemark);
      },

      /**
       * @return {Promise<void>}
       */
      specifyPointOnMap() {
        this.myMap.events.once("click", async (event) => {
          const coords = event.get("coords");

          await this.setAddressByCoordinates(coords[0], coords[1]); // lng, lat
        });
      },

      /**
       * @return {void}
       */
      toggleEditPolygon() {
        // toggle
        this.isPolygonChanging = !this.isPolygonChanging;

        let polygon = this.myPolygonGeoObjectCollection.get(0);

        if (!polygon) {
          polygon = this.createPolygon();
        }

        // stop drawing and editing
        if (!this.isPolygonChanging) {
          polygon.editor.stopDrawing();
          polygon.editor.stopEditing();

          return;
        }

        let stateMonitor = new ymaps.Monitor(polygon.editor.state);

        stateMonitor.add("drawing", (newValue) => {
          polygon.options.set("strokeColor", newValue ? "#ff3c42" : "#0066FF");
        });

        polygon.events.add("geometrychange", () => {
          this.project.polygon = polygon.geometry.getCoordinates();
        });

        polygon.editor.startDrawing();
      },

      /**
       * @return {void}
       */
      async setAddressLists() {
        this.awaitResponse = true;

        const res = await this.$api.lists.findBatchForEntity("address");

        this.federalRegions = res.data.federalRegions;
        this.specificRegions = res.data.specificRegions;
        this.regions = res.data.regions;
        this.areas = res.data.areas;
        this.cities = res.data.cities;
        this.cityAreas = res.data.cityAreas;
        this.cityDistricts = res.data.cityDistricts;
        // non-reactive for filtering
        this.areasData = res.data.areas;
        this.citiesData = res.data.cities;
        this.cityDistrictsData = res.data.cityDistricts;

        this.awaitResponse = false;
      },

      /**
       * @return {void}
       */
      async setOtherLists() {
        const [resMetro, resRailway] = await Promise.all([
          this.$api.metro.find(),
          this.$api.railway.find(),
        ]);

        this.metroData = customSort(resMetro.data, "line_alias");
        this.metro = this.metroData;
        this.railwayData = customSort(resRailway.data, "name");
        this.railway = this.railwayData;
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterMetro(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.metro = this.metroData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterRailway(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.railway = this.railwayData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterCities(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.cities = this.citiesData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterCityDistricts(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.cityDistricts = this.cityDistrictsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterAreas(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.areas = this.areasData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {Object} metro
       * @return {String|void}
       */
      getBgColorStyleForMetroLine(metro) {
        if (!metro.line_alias) {
          return;
        }

        return "background-color:" + METRO_LINE_COLOR_MAPPING[metro.line_alias];
      },

      /**
       * @param {String} val
       * @return {Promise<void>}
       */
      async onInputCoordinatesString(val) {
        let coords = coordsToArray(val, true);
        await this.updateCoordinates(coords);
      },

      /**
       * @param {Array} coords
       * @return {Promise<void>}
       */
      async updateCoordinates(coords) {
        if (this.checkCoordsIsEmpty(coords)) {
          this.coordinatesString = "";
          this.address.coordinates = null;
          this.resetMapState();

          return;
        }

        this.coordinatesString = coordsToString(coords, true);
        this.address.coordinates = coords.reverse();

        this.updatePlacemark(coords[0], coords[1]);
        this.moveMapViewToPlacemark();
        await this.findAndDrawNearProjects(coords[0], coords[1]);
      },

      /**
       * @param {Array} coords
       * @return {boolean}
       */
      checkCoordsIsEmpty(coords) {
        return !Boolean(coords && coords[0] && coords[1]);
      },

      copyCoordinatesReversed(stringCoords) {
        const coords = coordsToArray(stringCoords, true);

        if (coords[0] > coords[1]) {
          this.$q.notify({
            color: "negative",
            message: "Проверьте формат координат перед копированием.",
          });

          return;
        }

        const result = coordsToString(coords);

        copyToClipboard(result).then(() => {
          this.$q.notify({
            color: "positive",
            message: "Реверсивные координаты скопированы в буфер обмена.",
          });
        });
      },

      onUpdateProjectCadastrals(payload) {
        this.project.cadastrals = payload.cadastrals;
      },
    },
  };
</script>