<template>
  <div>
    <div class="flex items-center q-gutter-x-md">
      <span class="text-grey-10 text-h6">{{
        project.name || "нет данных"
      }}</span>
      <q-btn
        v-if="+$can(['project.delete'])"
        color="white"
        dense
        flat
        icon="mdi-dots-vertical"
        round
        text-color="grey-10"
      >
        <q-menu>
          <q-list>
            <q-item
              v-close-popup
              :disable="awaitDeleting"
              clickable
              @click="deleteProject"
            >
              <q-item-section>
                <q-item-label class="text-negative"
                  >Удалить проект
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon color="negative" name="mdi-delete-outline" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>

      <div v-if="address" class="flex items-center">
        <q-icon class="q-mr-sm" name="mdi-map-marker" />
        <span class="text-grey-8">{{ address | shortness(50) }}</span>
      </div>
    </div>

    <div class="flex">
      <div class="text-body3">
        <span> Создан {{ project.created_at.split(" ")[0] }} </span>
        |
        <span>
          Актуализирован {{ project.actualization.actualized_at.split(" ")[0] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ProjectTitle",

    props: {
      project: {
        type: Object,
        required: true,
      },
    },

    computed: {
      address() {
        return this.project.address && this.project.address.name;
      },
    },

    data() {
      return {
        awaitDeleting: false,
      };
    },

    methods: {
      deleteProject() {
        this.$q
          .dialog({
            title: "Удаление проекта",
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await this.$api.project
              .delete(this.project.id)
              .then(
                (res) => {
                  this.$router.push({ name: "ProjectList" });
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },
    },
  };
</script>