import multiguard from "vue-router-multiguard";
import auth from "../../middleware/auth";
import checkPermissions from "../../middleware/checkPermissions";

import Projects from "../../pages/projects/Projects";
import ProjectList from "../../pages/projects/ProjectList";
import ProjectEdit from "../../pages/projects/ProjectEdit";
import ProjectAdd from "../../pages/projects/ProjectAdd";

const projectRoutes = [
  {
    path: "/projects",
    component: Projects,
    beforeEnter: multiguard([auth, checkPermissions]),
    children: [
      {
        path: "",
        component: ProjectList,
        name: "ProjectList",
        meta: {
          layout: "main",
          permissions: ["project.view"],
          crumbs: "/Проекты",
          preventScroll: true,
        },
      },
      {
        path: "add",
        component: ProjectAdd,
        name: "ProjectAdd",
        meta: {
          layout: "main",
          permissions: ["project.add"],
          crumbs: "/Проекты/Добавить",
        },
      },
      {
        path: ":id",
        redirect: ":id/edit",
      },
      {
        path: ":id/edit",
        component: ProjectEdit,
        name: "ProjectEdit",
        beforeEnter(to, from, next) {
          to.params.id ? next() : next({ name: "NotFound" });
        },
        meta: {
          layout: "main",
          permissions: ["project.view"],
          crumbs: "/Проекты/{:}/Изменить",
        },
      },
    ],
  },
];

export default projectRoutes;
