import { axiosInstance as axios } from "@/plugins/axios";

const city = {
  find(include = "") {
    return include
      ? axios.get(`/cities`, { params: { include } })
      : axios.get(`/cities`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/cities", data, { params: { include } })
      : axios.post("/cities", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/cities/${id}`, data, { params: { include } })
      : axios.patch(`/cities/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/cities/${id}`);
  },
};

export default city;
