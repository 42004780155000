<template>
  <div class="col">
    <div class="row justify-center">
      <div v-if="!awaitResponse" class="col-24 col-md-8">
        <div class="rounded-borders shadow-2 q-pa-md bg-white">
          <ValidationObserver ref="observer" slim>
            <form
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
            >
              <fieldset
                :disabled="awaitSaving"
                class="no-border q-pa-none q-ma-none"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Название"
                  rules="max:255"
                >
                  <q-input
                    v-model="name"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    label="Название"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Статус"
                  rules="required"
                >
                  <q-select
                    v-model="projectStatusSelected"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    :options="projectStatuses"
                    bottom-slots
                    label="Статус"
                    option-label="name"
                    option-value="id"
                    options-dense
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Уровень конфиденциальности"
                  rules="required"
                >
                  <q-select
                    v-model="privacyLevelSelected"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    :options="privacyLevels"
                    bottom-slots
                    label="Уровень конфиденциальности"
                    option-label="name"
                    option-value="id"
                    options-dense
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Регион (бизнес)"
                  rules="required"
                >
                  <q-select
                    v-model="bizRegionSelected"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    :options="authUserBizRegions"
                    bottom-slots
                    label="Регион (бизнес)"
                    option-label="name"
                    option-value="id"
                    options-dense
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Локация"
                  rules="required"
                >
                  <q-select
                    v-model="specificRegionSelected"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    :options="specificRegions"
                    bottom-slots
                    label="Локация"
                    option-label="name"
                    option-value="id"
                    options-dense
                  />
                </ValidationProvider>
              </fieldset>
            </form>
          </ValidationObserver>

          <q-btn
            :disable="awaitSaving"
            :loading="awaitSaving"
            class="q-mt-lg"
            color="primary"
            label="Сохранить"
            type="submit"
            @click="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ProjectAdd",

    meta: {
      title: "Добавить проект",
    },

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        name: "",
        projectStatusSelected: null,
        privacyLevelSelected: null,
        bizRegionSelected: null,
        specificRegionSelected: null,
        projectStatuses: [],
        privacyLevels: [],
        bizRegions: [],
        specificRegions: [],
      };
    },

    computed: {
      authUserBizRegions() {
        return this.$store.getters["auth/user"].biz_regions;
      },
    },

    async mounted() {
      await this.setLists();
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            let data = {
              name: this.name,
              project_status_id: this.projectStatusSelected.id,
              privacy_level_id: this.privacyLevelSelected.id,
              biz_region_id: this.bizRegionSelected.id,
              specific_region_id: this.specificRegionSelected.id,
            };

            this.$api.project
              .create(data)
              .then(
                (res) => {
                  if (res.status === 200 && res.data.project) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.$router.push({
                      name: "ProjectEdit",
                      params: { id: res.data.project.id },
                    });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      async setLists() {
        this.awaitResponse = true;

        const [resBatch, resSpecificRegion] = await Promise.all([
          this.$api.lists.findBatchForEntity("project"),
          this.$api.specificRegion.find(),
        ]);

        this.projectStatuses = resBatch.data.projectStatuses;
        this.privacyLevels = resBatch.data.privacyLevels;
        this.bizRegionSelected = this.authUserBizRegions[0];
        this.specificRegions = resSpecificRegion.data;

        this.awaitResponse = false;
      },
    },
  };
</script>