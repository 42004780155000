<template>
  <q-layout v-if="$store.getters['auth/isAuth']" view="hHh lpR fFf">
    <q-header bordered class="bg-white text-grey-9" style="height: 50px">
      <q-toolbar style="height: 50px">
        <q-btn
          v-if="$q.screen.xs || $q.screen.sm"
          dense
          flat
          icon="mdi-menu"
          round
          @click="minimizeOrShowDrawer"
        />

        <div class="flex">
          <q-btn flat to="/">
            <img alt="logo" style="height: 40px" src="@/static/img/logo.png" />
          </q-btn>

          <div v-if="!($q.screen.xs || $q.screen.sm)" class="flex items-center">
            <q-btn
              :to="{ name: 'ProjectList' }"
              label="Проекты"
              flat
              no-caps
              :ripple="false"
              icon="mdi-home"
            />

            <q-btn
              :to="{ name: 'Map' }"
              label="Карта"
              flat
              no-caps
              :ripple="false"
              icon="mdi-map"
            />

            <q-btn
              label="Справочники"
              icon="mdi-book-open-variant"
              flat
              no-caps
              :ripple="false"
            >
              <q-menu>
                <q-list style="min-width: 150px">
                  <q-item clickable>
                    <q-item-section>Общие</q-item-section>
                    <q-item-section side>
                      <q-icon name="mdi-chevron-right" />
                    </q-item-section>

                    <q-menu anchor="top end" self="top start">
                      <q-list>
                        <q-item
                          v-for="item in commonListItems"
                          :key="item.to"
                          v-close-popup
                          :to="item.to"
                          class="items-center"
                          clickable
                          dense
                        >
                          <q-item-label>{{ item.text }}</q-item-label>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-item>

                  <q-item clickable>
                    <q-item-section>Адрес</q-item-section>
                    <q-item-section side>
                      <q-icon name="mdi-chevron-right" />
                    </q-item-section>

                    <q-menu anchor="top end" self="top start">
                      <q-list>
                        <q-item
                          v-for="item in addressListItems"
                          :key="item.to"
                          v-close-popup
                          :to="item.to"
                          class="items-center"
                          clickable
                          dense
                        >
                          <q-item-label>{{ item.text }}</q-item-label>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>

            <q-btn
              label="Настройки"
              icon="mdi-cog"
              flat
              no-caps
              :ripple="false"
            >
              <q-menu>
                <q-list style="min-width: 150px">
                  <q-item
                    v-for="item in settingsItems"
                    :key="item.to"
                    :to="item.to"
                    v-close-popup
                    clickable
                  >
                    <q-item-section>
                      <q-item-label>{{ item.text }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>

        <q-space />

        <q-btn-dropdown
          :label="$store.getters['auth/user'].name"
          auto-close
          class="absolute-right"
          color="grey-9"
          flat
          icon="mdi-account-circle-outline"
          no-caps
        >
          <q-list>
            <q-item v-close-popup clickable @click="logout">
              <q-item-section side>
                <q-icon name="mdi-power" size="1.25rem" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Выйти</q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section
                class="text-body2 text-grey-7"
                style="white-space: pre-line"
              >
                {{
                  $store.getters["auth/user"].biz_regions
                    .map((r) => r.name)
                    .join("\n")
                }}
              </q-item-section>
            </q-item>
            <q-item class="text-body3 text-grey-7">
              <q-item-section>
                роль: {{ $store.getters["auth/firstRole"].readable_name }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="$q.screen.xs || $q.screen.sm"
      v-model="isDrawerLeftShown"
      bordered
      side="left"
    >
      <q-list>
        <q-item :to="{ name: 'ProjectList' }" clickable>
          <q-item-section avatar>
            <q-icon name="mdi-home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Проекты</q-item-label>
          </q-item-section>
        </q-item>

        <q-item :to="{ name: 'Map' }" clickable>
          <q-item-section avatar>
            <q-icon name="mdi-map" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Карта</q-item-label>
          </q-item-section>
        </q-item>

        <q-expansion-item
          v-if="
            commonListItems.some((i) => i.visible) ||
            addressListItems.some((i) => i.visible)
          "
          dense-toggle
          default-opened
          expand-icon="mdi-chevron-down"
          expand-separator
          icon="mdi-book-open-variant"
          label="Справочники"
        >
          <q-expansion-item
            :content-inset-level="1"
            expand-icon="mdi-menu-down"
            expand-separator
            label="Общие"
            switch-toggle-side
          >
            <q-item
              v-for="item in commonListItems"
              :key="item.to"
              v-close-popup
              :to="item.to"
              class="items-center"
              clickable
              dense
            >
              <q-item-label>{{ item.text }}</q-item-label>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            :content-inset-level="1"
            expand-icon="mdi-menu-down"
            expand-separator
            label="Адрес"
            switch-toggle-side
          >
            <q-item
              v-for="item in addressListItems"
              :key="item.to"
              v-close-popup
              :to="item.to"
              class="items-center"
              clickable
              dense
            >
              <q-item-label>{{ item.text }}</q-item-label>
            </q-item>
          </q-expansion-item>
        </q-expansion-item>

        <q-expansion-item
          v-if="+$can(['settings.view'])"
          :content-inset-level="1"
          default-opened
          expand-icon="mdi-chevron-down"
          expand-separator
          icon="mdi-cog"
          label="Настройки"
        >
          <q-item
            v-for="item in settingsItems"
            :key="item.to"
            v-close-popup
            :to="item.to"
            clickable
          >
            <q-item-section>
              <q-item-label>{{ item.text }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page>
        <Breadcrumbs
          v-if="
            !($q.screen.xs || $q.screen.sm) &&
            !disableBreadcrumbsForRoutes.includes($route.name)
          "
        />
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
  import { actions as authActions } from "@/store/auth/actions";
  import Breadcrumbs from "@/components/Breadcrumbs";

  export default {
    name: "MainLayout",

    components: {
      Breadcrumbs,
    },

    data() {
      return {
        isDrawerLeftShown: false,
      };
    },

    computed: {
      disableBreadcrumbsForRoutes() {
        return ["Map", "ProjectEdit"];
      },

      commonListItems() {
        return [
          {
            to: "/lists/development-stage",
            text: "Стадии проработки",
            visible: +this.$can(["development-stage.view"]),
          },
          {
            to: "/lists/estate-class",
            text: "Классы недвижимости",
            visible: +this.$can(["estate-class.view"]),
          },
          {
            to: "/lists/estate-subject",
            text: "Форматы проекта",
            visible: +this.$can(["estate-subject.view"]),
          },
          {
            to: "/lists/estate-type",
            text: "Типы недвижимости",
            visible: +this.$can(["estate-type.view"]),
          },
          {
            to: "/lists/privacy-level",
            text: "Уровни конфиденц.",
            visible: +this.$can(["privacy-level.view"]),
          },
          {
            to: "/lists/project-status",
            text: "Статусы проекта",
            visible: +this.$can(["project-status.view"]),
          },
          {
            to: "/lists/source-info",
            text: "Источники информации",
            visible: +this.$can(["source-info.view"]),
          },
          {
            to: "/lists/developer",
            text: "Девелоперы",
            visible: +this.$can(["developer.view"]),
          },
          {
            to: "/lists/project-kind",
            text: "Типы проекта",
            visible: +this.$can(["project-kind.view"]),
          },
        ];
      },

      addressListItems() {
        return [
          {
            to: "/lists/country",
            text: "Страны",
            visible: +this.$can(["country.view"]),
          },
          {
            to: "/lists/specific-region",
            text: "Локации",
            visible: +this.$can(["specific-region.view"]),
          },
          {
            to: "/lists/federal-region",
            text: "Федеральные округа",
            visible: +this.$can(["federal-region.view"]),
          },
          {
            to: "/lists/region",
            text: "Регионы",
            visible: +this.$can(["region.view"]),
          },
          {
            to: "/lists/city-area",
            text: "Администр. округа",
            visible: +this.$can(["city-area.view"]),
          },
          {
            to: "/lists/area",
            text: "Городские округа",
            visible: +this.$can(["area.view"]),
          },
          {
            to: "/lists/city",
            text: "Города",
            visible: +this.$can(["city.view"]),
          },
          {
            to: "/lists/city-district",
            text: "Районы",
            visible: +this.$can(["city-district.view"]),
          },
          {
            to: "/lists/metro",
            text: "Метро",
            visible: +this.$can(["metro.view"]),
          },
          {
            to: "/lists/railway",
            text: "ЖД станции",
            visible: +this.$can(["railway.view"]),
          },
        ];
      },

      settingsItems() {
        let items = [
          {
            to: "/settings/biz-regions",
            text: "Регионы",
            visible: +this.$can(["biz-region.update"]),
          },
          {
            to: "/settings/users",
            text: "Пользователи",
            visible: +this.$can(["user.list.view"]),
          },
          {
            to: "/settings/roles",
            text: "Роли",
            visible: +this.$can(["role.update"]),
          },
          {
            to: "/settings/permissions",
            text: "Разрешения",
            visible: +this.$can(["permission.update"]),
          },
        ];

        return items.filter((item) => item.visible);
      },
    },

    methods: {
      logout() {
        this.$store.dispatch(`auth/${authActions.logoutUser}`).then((res) => {
          if (res.status === 204) {
            this.$router.push({ name: "Login" }).catch((err) => {
              console.warn(err);
            });
          }
        });
      },

      minimizeOrShowDrawer() {
        this.isDrawerLeftShown = !this.isDrawerLeftShown;
      },
    },
  };
</script>
