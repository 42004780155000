<template>
  <div
    v-if="Object.keys(user).length"
    class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2"
  >
    <ValidationObserver ref="observer" slim>
      <form
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        autocorrect="off"
      >
        <fieldset :disabled="awaitResponse" class="no-border q-px-none">
          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Имя</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Имя"
                rules="required|min:2|max:50"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="user.name"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Роль</div>
            <div class="col-15 col-md-18">
              <q-select
                v-model="user.roles[0]"
                :options="roles"
                :option-value="(opt) => opt"
                option-label="readable_name"
                bottom-slots
                dense
              />
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Статус</div>
            <div class="col-15 col-md-18">
              <q-select
                v-model="user.status"
                :options="userStatuses"
                bottom-slots
                map-options
                emit-value
                dense
              />
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Пароль</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Пароль"
                rules="required|min:5|max:256"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="user.password"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  :type="isPwd ? 'password' : 'text'"
                  hint="пароль отображается в зашифрованном виде. Чтобы его обновить, просто пропишите желаемый и сохраните"
                  bottom-slots
                  dense
                  class="q-mb-md"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd ? 'mdi-eye-off' : 'mdi-eye'"
                      class="cursor-pointer"
                      @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Регионы (бизнес)</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Регионы (бизнес)"
                rules="required"
                v-slot="{ errors }"
              >
                <q-select
                  v-model="user.biz_regions"
                  :options="$store.getters['auth/user'].biz_regions"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  map-options
                  dense
                  options-dense
                  use-chips
                  multiple
                  option-label="name"
                  option-value="id"
                />
              </ValidationProvider>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>

    <div class="row">
      <q-btn
        label="Сохранить"
        color="primary"
        :loading="awaitSaving"
        :disable="awaitSaving || authUserNotHavePermission"
        @click="save"
      />

      <q-space />

      <q-btn
        :loading="awaitDeleting"
        :disable="awaitDeleting || authUserNotHavePermission"
        icon="mdi-delete-outline"
        outline
        round
        color="negative"
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserEdit",

    meta() {
      return {
        title: this.title,
      };
    },

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        awaitDeleting: false,
        title: "",
        user: {},
        roles: [],
        isPwd: true,
      };
    },

    computed: {
      isOwner() {
        return this.$store.getters["auth/userId"] === this.user.id;
      },

      authUserNotHavePermission() {
        return !+this.$can(["user.update"]).andLevelGreater(
          this.user.roles[0].level
        );
      },

      userStatuses() {
        return [
          {
            label: "Активен",
            value: 3,
          },
          {
            label: "Заблокирован",
            value: 5,
          },
        ];
      },
    },

    async created() {
      this.awaitResponse = true;

      let [resUser, resRoles] = await Promise.all([
        this.$api.user.findUserById(
          this.$route.params.id,
          "roles,biz_regions",
          "UserDetailsWithPasswordTransformer"
        ),
        this.$api.role.find(),
      ]);

      this.user = resUser.data;
      this.title = "Редактирование " + resUser.data.name;

      this.roles = resRoles.data.filter(
        (role) => this.$store.getters["auth/firstRole"].level > role.level
      );

      this.awaitResponse = false;
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            let data = {
              name: this.user.name,
              role_id: this.user.roles[0].id,
              status: this.user.status,
              password: this.user.password,
              biz_region_ids: this.user.biz_regions.map((r) => r.id),
            };

            this.$api.user
              .update(
                this.$route.params.id,
                data,
                "roles,biz_regions",
                "UserDetailsWithPasswordTransformer"
              )
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.isPwd = true;
                    this.user = res.data.user;
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      remove() {
        this.$q
          .dialog({
            title: `Удаление пользователя ${this.user.name}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await this.$api.user
              .delete(this.user.id)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.$router.push({ name: "UserList" });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },
    },
  };
</script>