import { axiosInstance as axios } from "@/plugins/axios";

const estateSubject = {
  find(include = "") {
    return include
      ? axios.get(`/estate-subjects`, { params: { include } })
      : axios.get(`/estate-subjects`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/estate-subjects", data, { params: { include } })
      : axios.post("/estate-subjects", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/estate-subjects/${id}`, data, { params: { include } })
      : axios.patch(`/estate-subjects/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/estate-subjects/${id}`);
  },
};

export default estateSubject;
