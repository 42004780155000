<template>
  <div class="row q-pb-lg">
    <div class="col-24">
      <ProjectHeader :project="project" :viewers="viewers" />
    </div>

    <div v-if="!loading" class="col-24">
      <div class="row q-col-gutter-x-md">
        <div class="col-24 col-md-14">
          <div class="rounded-borders shadow-2 bg-white">
            <div>
              <q-list class="l-comments q-py-sm">
                <div v-if="comments">
                  <Comment
                    v-for="item in comments"
                    :key="item.id"
                    :comment="item"
                    :entityId="project.id"
                    :insetLevel="0"
                    entity="project"
                  />
                </div>

                <div v-if="!comments" class="text-grey-6 q-ml-md">
                  Нет данных
                </div>
              </q-list>
            </div>

            <q-separator />

            <div
              v-if="+$can(['comment.add']).andLevelGreater(50)"
              class="q-px-md q-py-sm"
            >
              <WriteComment
                :category="1"
                :entityId="project.id"
                :showAttachFilesBtn="true"
                entity="project"
              />
            </div>
          </div>
        </div>

        <div class="col-24 col-md-10">
          <div class="l-files rounded-borders shadow-2 q-pa-md bg-white">
            <div class="row flex items-center">
              <div class="col">
                Файлы
                <q-badge :label="cdnFiles ? cdnFiles.length : 0" />
              </div>
              <div class="col flex justify-end">
                <q-btn
                  v-if="+$can(['cdn-file.add'])"
                  icon="mdi-plus"
                  label="Загрузить"
                  @click="showUploadFilesDialog"
                />
              </div>
            </div>

            <div v-for="file in cdnFiles" class="l-file q-my-md text-body2">
              <div class="row flex items-center items-stretch">
                <div class="col-4 flex justify-center cursor-pointer">
                  <a
                    :alt="file.name | shortness(50)"
                    :href="file.url"
                    :title="file.name"
                    class="l-file__thumb flex text-h6 bg-grey-4"
                    target="_blank"
                  >
                    <q-img
                      v-if="fileIsImage(file.name)"
                      :src="file.url"
                      basic
                    />
                    <span v-else>{{ getFileExtension(file.name) }}</span>
                  </a>
                </div>

                <div class="col-20 q-pl-md">
                  <div class="l-file__title">
                    <a
                      :alt="file.name | shortness(50)"
                      :href="file.url"
                      :title="file.name"
                      target="_blank"
                    >
                      {{ file.name | shortness(50) }}
                    </a>
                  </div>
                  <div class="l-file__body">
                    {{ file.created_at }} <b>{{ file.creator && file.creator.name }}</b>
                    <div
                      v-if="fileEditing.state && fileEditing.id === file.id"
                      class="q-gutter-sm q-mb-sm"
                    >
                      <q-input v-model="file.source" label="Источник" />

                      <q-input
                        v-model="file.relevance_date"
                        clearable
                        filled
                        label="Дата актуальности"
                        mask="####-##-##"
                      >
                        <template v-slot:append>
                          <q-icon class="cursor-pointer" name="mdi-calendar">
                            <q-popup-proxy>
                              <q-date
                                v-model="file.relevance_date"
                                mask="YYYY-MM-DD"
                              >
                                <div
                                  class="row items-center justify-end q-gutter-sm"
                                >
                                  <q-btn
                                    v-close-popup
                                    color="primary"
                                    flat
                                    label="Ок"
                                  />
                                </div>
                              </q-date>
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                      </q-input>

                      <q-btn
                        :disable="awaitSaving"
                        :loading="awaitSaving"
                        dense
                        flat
                        label="Отмена"
                        type="button"
                        @click="toggleEditing(file)"
                      />

                      <q-btn
                        :disable="awaitSaving"
                        :loading="awaitSaving"
                        color="positive"
                        dense
                        label="Сохранить"
                        type="button"
                        @click="submitEditFileForm(file)"
                      />
                    </div>

                    <div v-else>
                      <div v-if="file.source || file.relevance_date">
                        <span v-if="file.source" class="q-mr-sm"
                        >Источник: {{ file.source }}</span
                        >
                        <span v-if="file.relevance_date"
                        >Дата актуальности: {{ file.relevance_date }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="l-file__actions">
                    <span
                      v-if="+$can(['comment.add']).andLevelGreater(50)"
                      class="text-primary cursor-pointer q-mr-sm"
                      @click="$root.$emit('comment-file', { file })"
                    >
                      Комментировать
                    </span>
                    <span
                      v-if="+$can(['cdn-file.update'])"
                      class="text-primary cursor-pointer q-mr-sm"
                      @click="toggleEditing(file)"
                    >
                      Изменить
                    </span>
                    <span
                      v-if="+$can(['cdn-file.delete'])"
                      class="text-primary cursor-pointer"
                      @click="deleteFile(file)"
                    >
                      Удалить
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectHeader from "@/components/projects/ProjectHeader";
  import Comment from "@/components/comment/Comment";
  import WriteComment from "@/components/comment/WriteComment";
  import ProjectUploadFilesDialog from "@/components/dialogs/ProjectUploadFilesDialog";
  import { extractFileExtension, isImage } from "@/utils/batch";

  export default {
    name: "ProjectDocument",

    props: {
      project: {
        type: Object,
        required: true
      },
      viewers: {
        type: Array,
        required: true
      }
    },

    components: {
      ProjectHeader,
      Comment,
      WriteComment,
      ProjectUploadFilesDialog
    },

    data() {
      return {
        loading: false,
        awaitFileDeleting: false,
        awaitSaving: false,
        comments: null,
        cdnFiles: null,
        fileEditing: {
          state: false,
          id: null
        }
      };
    },

    async created() {
      this.$root.$on("comment-deleted", this.onCommentDeleted);
      this.$root.$on("comment-added", this.onCommentAdded);
      this.$root.$on("comment-updated", this.onCommentUpdated);
      this.$root.$on("files-uploaded", this.onFilesUploaded);

      this.loading = true;

      await this.setComments();
      await this.setCdnFiles();

      this.loading = false;
    },

    destroyed() {
      this.$root.$off("comment-deleted", this.onCommentDeleted);
      this.$root.$off("comment-added", this.onCommentAdded);
      this.$root.$off("comment-updated", this.onCommentUpdated);
      this.$root.$off("files-uploaded", this.onFilesUploaded);
    },

    methods: {
      setComments() {
        return this.$api.project
          .findById(this.$route.params.id, "comments")
          .then((res) => {
            let comments = res.data.comments
              ? res.data.comments.filter((item) => item.category === 1)
              : null;

            if (comments && comments.length) {
              this.comments = comments;
            }
          });
      },

      setCdnFiles() {
        return this.$api.project
          .findById(this.$route.params.id, "cdn_files.creator")
          .then((res) => {
            if (res.status === 200) {
              this.cdnFiles = res.data.cdn_files;
            }
          });
      },

      onCommentDeleted() {
        this.setComments();
      },

      onCommentAdded() {
        this.setComments();
      },

      onCommentUpdated() {
        this.setComments();
      },

      onFilesUploaded(payload) {
        if (!this.cdnFiles) {
          this.cdnFiles = [];
        }

        this.cdnFiles.unshift(...payload.files);
      },

      deleteFile(file) {
        this.$q
          .dialog({
            title: "Удаление файла",
            message: "Вы уверены?",
            ok: {
              label: "Удалить"
            }
          })
          .onOk(async () => {
            this.awaitFileDeleting = true;

            await this.$api.project
              .deleteFiles(this.$route.params.id, file.id)
              .then((res) => {
                this.$q.notify({
                  color: "positive",
                  message: res.data.message
                });

                const index = this.cdnFiles.indexOf(file);

                this.cdnFiles.splice(index, 1);
              })
              .then(() => {
                this.awaitFileDeleting = false;
              });
          });
      },

      showUploadFilesDialog() {
        this.$q.dialog({
          component: ProjectUploadFilesDialog,
          parent: this,
          // props
          projectId: this.project.id
        });
      },

      getFileExtension(fileName) {
        return extractFileExtension(fileName);
      },

      fileIsImage(fileName) {
        return isImage(fileName);
      },

      toggleEditing(file) {
        if (this.fileEditing.id !== file.id) {
          this.fileEditing.state = true;
          this.fileEditing.id = file.id;

          return;
        }

        this.fileEditing.state = !this.fileEditing.state;

        if (this.fileEditing.state) {
          this.fileEditing.id = file.id;
        } else {
          this.fileEditing.id = null;
        }
      },

      submitEditFileForm(file) {
        let data = {
          source: file.source,
          relevance_date: file.relevance_date
        };

        this.awaitSaving = true;

        this.$api.project
          .updateFile(this.project.id, file.id, data)
          .then((res) => {
            this.$q.notify({
              color: "positive",
              message: res.data.message
            });

            this.toggleEditing(file);
          })
          .then(() => {
            this.awaitSaving = false;
          });
      }
    }
  };
</script>

<style lang="scss">
  .l-comments {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .l-files {
    max-height: 800px;
    overflow-y: auto;
  }

  .l-file {
    a {
      color: black;
    }

    &__thumb {
      text-decoration: none;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
</style>