<template>
  <div class="q-gutter-x-md">
    <q-avatar
      v-for="(item, index) in viewers"
      :key="item.id"
      :style="'background-color:' + getHexColor(index)"
      size="32px"
      text-color="white"
    >
      {{
        item.name
          .split(" ")
          .map((i) => i.charAt(0).toUpperCase())
          .join("")
      }}
      <q-tooltip>
        {{ item.name }}
      </q-tooltip>
    </q-avatar>
  </div>
</template>

<script>
  import { hexColors } from "@/utils/batch";

  export default {
    name: "ProjectViewers",

    props: {
      viewers: {
        type: Array,
        required: true,
      },
    },

    methods: {
      getHexColor(index) {
        return hexColors[index];
      },
    },
  };
</script>