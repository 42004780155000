import multiguard from "vue-router-multiguard";
import auth from "../../middleware/auth";
import checkPermissions from "../../middleware/checkPermissions";

import Lists from "../../pages/lists/Lists";
import DevelopmentStageList from "../../pages/lists/DevelopmentStageList";
import EstateClassList from "../../pages/lists/EstateClassList";
import EstateSubjectList from "../../pages/lists/EstateSubjectList";
import EstateTypeList from "../../pages/lists/EstateTypeList";
import PrivacyLevelList from "../../pages/lists/PrivacyLevelList";
import ProjectStatusList from "../../pages/lists/ProjectStatusList";
import SourceInfoList from "../../pages/lists/SourceInfoList";
import DeveloperList from "../../pages/lists/DeveloperList";
import ProjectKindList from "../../pages/lists/ProjectKindList";

// Address lists
import MetroList from "../../pages/lists/MetroList";
import RailwayList from "../../pages/lists/RailwayList";
import AreaList from "../../pages/lists/AreaList";
import CityAreaList from "../../pages/lists/CityAreaList";
import CityDistrictList from "../../pages/lists/CityDistrictList";
import CityList from "../../pages/lists/CityList";
import CountryList from "../../pages/lists/CountryList";
import FederalRegionList from "../../pages/lists/FederalRegionList";
import RegionList from "../../pages/lists/RegionList";
import SpecificRegionList from "../../pages/lists/SpecificRegionList";

const listRoutes = [
  {
    path: "/lists",
    component: Lists,
    beforeEnter: multiguard([auth, checkPermissions]),
    children: [
      {
        path: "",
        redirect: "development-stage",
      },
      {
        path: "development-stage",
        component: DevelopmentStageList,
        name: "DevelopmentStageList",
        meta: {
          layout: "main",
          permissions: ["development-stage.view"],
          crumbs: "/Справочники/Стадии проработки",
        },
      },
      {
        path: "estate-class",
        component: EstateClassList,
        name: "EstateClassList",
        meta: {
          layout: "main",
          permissions: ["estate-class.view"],
          crumbs: "/Справочники/Классы недвижимости",
        },
      },
      {
        path: "estate-subject",
        component: EstateSubjectList,
        name: "EstateSubjectList",
        meta: {
          layout: "main",
          permissions: ["estate-subject.view"],
          crumbs: "/Справочники/Форматы проекта",
        },
      },
      {
        path: "estate-type",
        component: EstateTypeList,
        name: "EstateTypeList",
        meta: {
          layout: "main",
          permissions: ["estate-type.view"],
          crumbs: "/Справочники/Типы недвижимости",
        },
      },
      {
        path: "privacy-level",
        component: PrivacyLevelList,
        name: "PrivacyLevelList",
        meta: {
          layout: "main",
          permissions: ["privacy-level.view"],
          crumbs: "/Справочники/Уровни конфиденциальности",
        },
      },
      {
        path: "project-status",
        component: ProjectStatusList,
        name: "ProjectStatusList",
        meta: {
          layout: "main",
          permissions: ["project-status.view"],
          crumbs: "/Справочники/Статусы проекта",
        },
      },
      {
        path: "source-info",
        component: SourceInfoList,
        name: "SourceInfoList",
        meta: {
          layout: "main",
          permissions: ["source-info.view"],
          crumbs: "/Справочники/Источники информации",
        },
      },
      {
        path: "developer",
        component: DeveloperList,
        name: "DeveloperList",
        meta: {
          layout: "main",
          permissions: ["developer.view"],
          crumbs: "/Справочники/Девелоперы",
        },
      },
      {
        path: "project-kind",
        component: ProjectKindList,
        name: "ProjectKindList",
        meta: {
          layout: "main",
          permissions: ["project-kind.view"],
          crumbs: "/Справочники/Типы проекта",
        },
      },
      // address
      {
        path: "metro",
        component: MetroList,
        name: "MetroList",
        meta: {
          layout: "main",
          permissions: ["metro.view"],
          crumbs: "/Справочники/Метро",
        },
      },
      {
        path: "railway",
        component: RailwayList,
        name: "RailwayList",
        meta: {
          layout: "main",
          permissions: ["railway.view"],
          crumbs: "/Справочники/ЖД станции",
        },
      },
      {
        path: "area",
        component: AreaList,
        name: "AreaList",
        meta: {
          layout: "main",
          permissions: ["area.view"],
          crumbs: "/Справочники/Городские округа",
        },
      },
      {
        path: "city-area",
        component: CityAreaList,
        name: "CityAreaList",
        meta: {
          layout: "main",
          permissions: ["city-area.view"],
          crumbs: "/Справочники/Администр. округа",
        },
      },
      {
        path: "city-district",
        component: CityDistrictList,
        name: "CityDistrictList",
        meta: {
          layout: "main",
          permissions: ["city-district.view"],
          crumbs: "/Справочники/Районы",
        },
      },
      {
        path: "city",
        component: CityList,
        name: "CityList",
        meta: {
          layout: "main",
          permissions: ["city.view"],
          crumbs: "/Справочники/Города",
        },
      },
      {
        path: "country",
        component: CountryList,
        name: "CountryList",
        meta: {
          layout: "main",
          permissions: ["country.view"],
          crumbs: "/Справочники/Страны",
        },
      },
      {
        path: "federal-region",
        component: FederalRegionList,
        name: "FederalRegionList",
        meta: {
          layout: "main",
          permissions: ["federal-region.view"],
          crumbs: "/Справочники/Федеральные округа",
        },
      },
      {
        path: "region",
        component: RegionList,
        name: "RegionList",
        meta: {
          layout: "main",
          permissions: ["region.view"],
          crumbs: "/Справочники/Регионы",
        },
      },
      {
        path: "specific-region",
        component: SpecificRegionList,
        name: "SpecificRegionList",
        meta: {
          layout: "main",
          permissions: ["specific-region.view"],
          crumbs: "/Справочники/Локации",
        },
      },
    ],
  },
];

export default listRoutes;
