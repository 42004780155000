import api from "../api";
import Centrifuge from "centrifuge";
import Notify from 'quasar/src/plugins/Notify.js';;
import { lf } from "./localforage";

const centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGO_URL, {
  debug: Boolean(Number(process.env.VUE_APP_CENTRIFUGO_CLIENT_DEBUG)),
  refreshEndpoint: process.env.VUE_APP_API_URL + "/centrifuge/token",
  refreshInterval: 3000,
  refreshHeaders: async function () {
    const token = await lf.getItem("access_token");

    return {
      Authorization: `Bearer ${token}`,
    };
  },
  onRefresh: function (ctx, cb) {
    api.centrifugo.token().then((res) => {
      let data = {
        status: 200,
        data: {
          token: res.data.access_token,
        },
      };

      cb(data);
    });
  },
  refreshAttempts: 5,
  onRefreshFailed: () => {
    Notify.create({
      color: "negative",
      message: "Ошибка Центрифуги.",
      timeout: 60000,
    });
  },
});

export default centrifuge;
