import { axiosInstance as axios } from "@/plugins/axios";

const developmentStage = {
  find(include = "") {
    return include
      ? axios.get(`/development-stages`, { params: { include } })
      : axios.get(`/development-stages`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/development-stages", data, { params: { include } })
      : axios.post("/development-stages", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/development-stages/${id}`, data, { params: { include } })
      : axios.patch(`/development-stages/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/development-stages/${id}`);
  },
};

export default developmentStage;
