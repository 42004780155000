import { axiosInstance as axios } from "@/plugins/axios";

const country = {
  find(include = "") {
    return include
      ? axios.get(`/countries`, { params: { include } })
      : axios.get(`/countries`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/countries", data, { params: { include } })
      : axios.post("/countries", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/countries/${id}`, data, { params: { include } })
      : axios.patch(`/countries/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/countries/${id}`);
  },
};

export default country;
