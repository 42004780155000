<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card
      class="lq-project-upload-images-dialog bg-grey-9 text-grey-4"
      style="width: 900px; max-width: 900px"
    >
      <div class="flex items-center justify-between bg-grey-8 q-px-md">
        <div>Загрузка изображений для проекта</div>
        <q-btn v-close-popup flat icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-uploader
          ref="uploader"
          :disable="awaitUploading"
          :filter="filterCheck"
          :max-file-size="50000000"
          :max-total-size="100000000"
          accept=".jpg, .png, .jpeg, .bmp, image/!*"
          class="lq-project-upload-images-dialog__uploader bg-grey-9 full-width"
          dark
          flat
          multiple
          @added="onAdded"
        >
          <template v-slot:header="scope">
            <div
              class="row no-wrap items-center justify-between q-py-sm q-px-md"
            >
              <q-btn
                :disable="isCompressingPreviews || awaitUploading"
                flat
                icon="mdi-plus"
                label="Выбрать фото"
                text-color="white"
                title="Загрузить фото с диска"
              >
                <q-uploader-add-trigger v-if="scope.canAddFiles" />
              </q-btn>

              <q-btn
                :disable="$_isEmpty(scope.queuedFiles) || isCompressingPreviews"
                flat
                icon="mdi-delete-outline"
                label="Очистить список"
                no-caps
                text-color="white"
                title="Убрать все фото из списка"
                @click="deleteAll"
              />
            </div>
          </template>

          <template v-slot:list="scope">
            <transition appear enter-active-class="animated fadeIn">
              <q-banner v-if="isCompressingPreviews" class="q-mb-md bg-grey-8">
                <template v-slot:avatar>
                  <q-icon name="mdi-refresh" />
                </template>
                Подготовка превью изображений. Пожалуйста, подождите до 1 мин
                (зависит от размера и кол-ва фото).
              </q-banner>
            </transition>

            <div class="row q-col-gutter-md">
              <div
                v-for="(img, index) in compressedImages"
                :key="img.upload_name"
                class="col-12 col-md-6"
              >
                <q-img
                  v-if="img.__img"
                  :alt="img.name"
                  :ratio="1"
                  :src="img.__img.src"
                  :title="img.name"
                  basic
                >
                  <q-btn
                    :disable="awaitUploading"
                    class="absolute-top-right q-ma-xs shadow-7"
                    color="white"
                    dense
                    icon="mdi-delete"
                    round
                    text-color="black"
                    title="Убрать из списка"
                    @click="deleteFile(img, index)"
                  />
                </q-img>
              </div>
            </div>
          </template>
        </q-uploader>
      </q-card-section>

      <q-card-actions class="q-pa-md justify-between">
        <q-btn
          v-close-popup
          :disable="awaitUploading"
          color="grey"
          label="Отмена"
          outline
          title="Закрыть окно загрузки изображений"
        />
        <q-btn
          :disable="awaitUploading || isCompressingPreviews"
          :loading="awaitUploading"
          color="primary"
          label="Загрузить"
          title="Загрузить изображения"
          @click="uploadNow"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import Compressor from "compressorjs";

  export default {
    name: "ProjectUploadImagesDialog",

    props: {
      project: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        awaitUploading: false,
        isCompressingPreviews: false,
        compressedImages: [],
      };
    },

    methods: {
      uploadNow() {
        // just additional guard
        if (this.awaitUploading) {
          return;
        }

        if (this.$_isEmpty(this.$refs.uploader.files)) {
          this.$q.notify({
            color: "negative",
            message: "Пожалуйста, выберите фотографии.",
          });
        } else {
          this.awaitUploading = true;

          let formData = new FormData();

          this.compressedImages.forEach((item, i) => {
            formData.append("images[]", this.compressedImages[i]);
          });

          this.$api.project
            .uploadImages(this.project.id, formData)
            .then(
              (res) => {
                this.$root.$emit("project-images-uploaded", {
                  media: res.data.media,
                });

                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });

                this.hide();
              },
              (error) => {
                if (error.response.status !== 500) {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              }
            )
            .then(() => {
              this.awaitUploading = false;
            });
        }
      },

      onAdded(files) {
        if (this.$refs.uploader.files.length > 12) {
          this.$q.notify({
            color: "negative",
            message: "Можно прикрепить не более 12 фото за раз.",
          });

          Array.from(files).forEach((item) => {
            this.$refs.uploader.removeFile(item);
          });
        } else {
          // rename files for backend check for primary image
          Array.from(files).forEach((file) => {
            // file.name read only
            file.upload_name =
              Math.random().toString(36).substr(2, 4) + "_" + file.name;
          });

          this.isCompressingPreviews = true;

          let promises = [];

          for (let i = 0; i < files.length; i++) {
            promises.push(
              new Promise((resolve, reject) => {
                new Compressor(files[i], {
                  quality: 0.7,
                  maxWidth: 1440,
                  maxHeight: 900,
                  checkOrientation: files[i].size < 6000000, // 6 mb
                  convertSize: 100000, // 100 kb
                  success(result) {
                    result.upload_name = files[i].upload_name;
                    resolve(result);
                  },
                  error(err) {
                    reject(err);
                  },
                });
              })
            );
          }

          Promise.all(promises)
            .then((blobs) => {
              this.isCompressingPreviews = false;

              for (let i = 0; i < blobs.length; i++) {
                let image = new Image();
                image.src = URL.createObjectURL(blobs[i]);

                let file = new File([blobs[i]], blobs[i].name, {
                  type: blobs[i].type,
                });
                file.__img = image;
                file.upload_name = blobs[i].upload_name;

                this.compressedImages.push(file);
              }
            })
            .catch((error) => {
              this.$q.notify({
                color: "negative",
                message: "Ошибка формирования превью.",
              });
            });
        }
      },

      filterCheck(files) {
        return files.filter((file) => {
          if (file.size > 30000000) {
            this.$q.notify({
              color: "negative",
              message: `Размер ${file.name} слишком большой. Максимум 30 мб.`,
            });

            return false;
          }

          return true;
        });
      },

      deleteFile(img, index) {
        this.$refs.uploader.removeFile(img);
        this.compressedImages.splice(index, 1);
      },

      deleteAll() {
        if (this.$refs.uploader) {
          this.$refs.uploader.removeQueuedFiles();
        }

        this.compressedImages = [];
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.deleteAll();
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.deleteAll();
        this.$emit("hide");
      },
    },
  };
</script>

<style lang="scss">
  .lq-project-upload-images-dialog {
    &__uploader {
      max-height: 900px !important;

      &__list {
        padding: 16px 0 !important;
      }

      &__header {
        border-radius: 4px;
      }
    }
  }
</style>