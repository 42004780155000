<template>
  <div class="col">
    <div class="row justify-center items-start q-gutter-md">
      <q-card v-for="role in roles" :key="role.id" style="max-width: 300px">
        <q-card-section>
          <div class="row items-center no-wrap">
            <div class="col">
              <div class="text-h6">{{ role.readable_name }}</div>
            </div>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-section>
          {{ role.description }}
        </q-card-section>

        <q-card-section>
          Разрешений: {{ getPermissionsCount(role.permissions) }}
        </q-card-section>

        <q-separator />

        <q-card-actions>
          <q-btn
            v-if="+$can(['role.update'])"
            :to="{ name: 'RoleEdit', params: { id: role.id } }"
            class="full-width"
            color="primary"
            flat
            icon="mdi-pencil"
            label="Изменить"
          />
        </q-card-actions>
      </q-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RoleList",

    meta: {
      title: "Роли",
    },

    data() {
      return {
        roles: [],
      };
    },

    created() {
      this.getRoles();
    },

    methods: {
      async getRoles() {
        let res = await this.$api.role.find("permissions");

        if (res.status === 200) {
          this.roles = res.data;
        }
      },

      getPermissionsCount(permissions) {
        return permissions ? permissions.length : 0;
      },
    },
  };
</script>