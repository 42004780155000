import router from "../router";
import { canAbility as can } from "../utils/ability";

export default function checkPermissions(to, from, next) {
  if (!to.meta.hasOwnProperty("permissions")) {
    return next();
  }

  if (+can(to.meta.permissions)) {
    return next();
  } else {
    return router.replace({ name: "NotFound" });
  }
}
