/**
 * Children of Settings Routes
 */

import UserEdit from "../../../pages/settings/users/UserEdit";
import UserList from "../../../pages/settings/users/UserList";
import UserDetails from "../../../pages/settings/users/UserDetails";
import UserAdd from "../../../pages/settings/users/UserAdd";
import multiguard from "vue-router-multiguard";
import checkPermissions from "../../../middleware/checkPermissions";

const userRoutes = [
  {
    path: "",
    name: "UserList",
    component: UserList,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["user.list.view"],
      crumbs: "/Настройки/Пользователи",
    },
  },
  {
    path: "add",
    name: "UserAdd",
    component: UserAdd,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["user.add"],
      crumbs: "/Настройки/Пользователи/Добавить",
    },
  },
  {
    path: ":id",
    name: "UserDetails",
    component: UserDetails,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["user.view"],
      crumbs: "/Настройки/Пользователи/{:}",
    },
  },
  {
    path: ":id/edit",
    name: "UserEdit",
    component: UserEdit,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["user.update"],
      crumbs: "/Настройки/Пользователи/{:}/Изменить",
    },
  },
];

export default userRoutes;
