<template>
  <div class="row justify-center">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "ListIndex",

    meta: {
      title: "Справочники",
    },
  };
</script>