<template>
  <div class="flex justify-center q-px-md">
    <div class="row q-pa-xl bg-white rounded-borders" style="width: 500px">
      <div class="col-24 flex flex-center">
        <img alt="logo" src="@/static/img/logo.png" />
      </div>
      <div class="col-24 q-mt-md">
        <SignIn />
      </div>
    </div>
  </div>
</template>

<script>
  import SignIn from "@/components/SignIn";

  export default {
    name: "Login",

    components: {
      SignIn,
    },

    meta: {
      title: "Вход",
    },
  };
</script>