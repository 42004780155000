<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
  import {
    reloadPageIfTokenExpireSoonInterval,
    removeAuthDataFromStorage,
  } from "@/utils/batch";
  import { configureScope as SentryConfigureScope } from "@sentry/vue";
  import { debounce } from "quasar";
  import { mutations as rootMutations } from "@/store/mutations";

  const DEFAULT_LAYOUT = "main";

  export default {
    name: "App",

    meta: {
      title: "Перспективные проекты",
      script: {
        ymaps: {
          type: "text/javascript",
          src: "https://api-maps.yandex.ru/2.1/?lang=ru_RU&coordorder=longlat",
        },
      },
    },

    computed: {
      layout() {
        return (this.$route.meta.layout || DEFAULT_LAYOUT) + "-layout";
      },
    },

    created() {
      window.addEventListener("storage", this.syncAuth);
    },

    async mounted() {
      this.windowInnerHeight();
      window.addEventListener("resize", debounce(this.windowInnerHeight, 50));
      reloadPageIfTokenExpireSoonInterval();
    },

    destroyed() {
      window.removeEventListener("storage", this.syncAuth);
    },

    methods: {
      /**
       * Sync auth between browser's opened pages (windows) on user login/logout.
       *
       * Pros:
       * + prevent unauth access to backend resources if user logout from some page;
       * + auto login for all pages;
       * + keep one access token for all windows.
       *
       * NOTE: localStorage is universal cross-browser realisation.
       * @param event
       * @return {Promise<void>}
       */
      async syncAuth(event) {
        if (event.key === "logout" && event.newValue === "true") {
          await removeAuthDataFromStorage();

          this.$centrifuge.disconnect();
          this.$store.commit("auth/UNSET_USER");
          this.$store.commit("auth/UNSET_PERMISSIONS");

          SentryConfigureScope((scope) => {
            scope.setUser({});
          });

          await this.$router.push({ name: "Login" });
        }

        if (event.key === "login" && event.newValue === "true") {
          // no need to copy-paste code from store's login action, cause
          // we update auth in reloadPageIfTokenExpireSoonInterval() in the same way.
          window.location.reload();
        }
      },

      windowInnerHeight() {
        this.$store.commit(
          rootMutations.SET_WINDOW_INNER_HEIGHT,
          window.innerHeight
        );
      },
    },
  };
</script>
