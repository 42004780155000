<template>
  <div class="col col-md-18">
    <div class="bg-white">
      <q-table
        :data="users"
        :columns="columns"
        :filter="filter"
        :loading="loading"
        :pagination.sync="pagination"
        row-key="id"
        dense
      >
        <template v-slot:top-left>
          <q-btn
            v-if="+$can(['user.add'])"
            label="Добавить"
            icon="mdi-plus"
            @click="$router.push({ name: 'UserAdd' })"
          />
        </template>

        <template v-slot:top-right>
          <q-input
            v-model="filter"
            dense
            outlined
            debounce="300"
            color="primary"
          >
            <template v-slot:append>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="id" :props="props">{{ props.row.id }}</q-td>
            <q-td key="name" :props="props">
              <q-btn
                :label="props.row.name"
                :to="{ name: 'UserDetails', params: { id: props.row.id } }"
                flat
                no-caps
                dense
                color="primary"
                class="q-px-none"
              />
            </q-td>
            <q-td key="email" :props="props">{{ props.row.email }}</q-td>
            <q-td key="role" :props="props"
              >{{ props.row.roles[0].readable_name }}
            </q-td>
            <q-td key="biz_regions" :props="props" style="white-space: pre-line"
              >{{
                props.row.biz_regions &&
                props.row.biz_regions.map((r) => r.name).join("\n")
              }}
            </q-td>
            <q-td key="actions" :props="props">
              <q-btn
                v-if="
                  +$can(['user.update']).andLevelGreater(
                    props.row.roles[0].level
                  )
                "
                :to="{ name: 'UserEdit', params: { id: props.row.id } }"
                flat
                dense
                icon="mdi-pencil"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserList",

    meta: {
      title: "Пользователи",
    },

    async created() {
      this.loading = true;
      const res = await this.$api.user.find("roles,biz_regions");
      this.users = res.data;
      this.loading = false;
    },

    computed: {
      columns() {
        return [
          {
            name: "id",
            required: true,
            label: "ID",
            align: "left",
            field: (row) => row.id,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Имя",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
          {
            name: "email",
            required: true,
            label: "Email",
            align: "left",
            field: (row) => row.email,
            sortable: true,
          },
          {
            name: "role",
            required: true,
            label: "Роль",
            align: "left",
            field: (row) => row.roles[0].readable_name,
            sortable: true,
          },
          {
            name: "biz_regions",
            required: true,
            label: "Регионы",
            align: "left",
            field: (row) => row.biz_regions,
            sortable: true,
          },
          {
            name: "actions",
            label: "Действия",
            align: "right",
          },
        ];
      },
    },

    data() {
      return {
        loading: false,
        filter: "",
        pagination: {
          rowsPerPage: 10,
        },
        users: [],
      };
    },
  };
</script>