<template>
  <div class="row q-px-md">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "Settings",

    meta: {
      title: "Настройки",
    },
  };
</script>