<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <form @submit.prevent="passes(submitSignInForm)">
      <fieldset :disabled="awaitSignIn" class="no-border q-px-none">
        <ValidationProvider
          ref="emailProvider"
          v-slot="{ errors }"
          name="Email"
          rules="required|email"
        >
          <q-input
            v-model.trim="user.email"
            :error="errors && !!errors.length"
            :error-message="errors[0]"
            autofocus
            bottom-slots
            label="Email"
          />
        </ValidationProvider>

        <ValidationProvider
          ref="passwordProvider"
          v-slot="{ errors }"
          name="Пароль"
          rules="required|min:3"
        >
          <q-input
            v-model="user.password"
            :error="errors && !!errors.length"
            :error-message="errors[0]"
            :type="isPwd ? 'password' : 'text'"
            bottom-slots
            label="Пароль"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'mdi-eye-off' : 'mdi-eye'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </ValidationProvider>
      </fieldset>

      <q-btn
        :disable="awaitSignIn"
        :loading="awaitSignIn"
        class="q-mt-lg full-width"
        color="primary"
        label="Войти"
        type="submit"
      />
    </form>
  </ValidationObserver>
</template>

<script>
  import { actions as authActions } from "@/store/auth/actions";

  export default {
    name: "SignIn",

    data() {
      return {
        user: {
          email: null,
          password: null,
        },
        isPwd: true,
        awaitSignIn: false,
      };
    },

    methods: {
      submitSignInForm() {
        this.awaitSignIn = true;

        // login request
        this.$store
          .dispatch(`auth/${authActions.login}`, this.user)
          .then(
            (res) => {
              if (res.status === 200) {
                this.$router.push("/");

                // do not trigger validation
                this.$refs.observer.reset();

                // reset form
                this.user.email = this.user.password = null;
              }
            },
            (error) => {
              if (error.response && error.response.status) {
                // show other message
                if (error.response.status !== 500) {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              }
            }
          )
          .then(() => {
            this.awaitSignIn = false;
          });
      },
    },
  };
</script>