<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <img :src="image.url" alt="Image =)" style="max-width: 1440px" />
  </q-dialog>
</template>

<script>
  export default {
    name: "SingleImageDialog",

    props: {
      image: {
        type: Object,
        required: true,
      },
    },

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>