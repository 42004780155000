<template>
  <div class="row justify-center q-pb-lg">
    <div class="col-24">
      <ProjectHeader :project="project" :viewers="viewers" />
    </div>

    <div class="col-24 relative-position">
      <div
        v-if="!awaitResponse && history.length"
        class="rounded-borders shadow-2 q-pa-md bg-white"
        style="max-height: 800px; overflow: auto"
      >
        <div class="absolute-top-right q-px-lg q-py-sm" style="z-index: 1">
          <q-btn
            color="primary"
            dense
            flat
            label="Список сохраняемых параметров"
            no-caps
            @click="showHistoryParameterListDialog"
          />
        </div>

        <q-list>
          <q-item v-for="item in history" :key="item.id" class="q-pa-none">
            <q-item-section top>
              <q-item-label>
                <span class="q-mr-sm">{{
                  item.creator ? item.creator.name : "" | shortness(20)
                }}</span>
                <q-icon
                  v-if="!item.creator"
                  class="q-mr-md"
                  name="mdi-account-question"
                  size="sm"
                />
                <span class="text-grey-9 q-mr-md">{{ item.created_at }}</span>
                <q-btn
                  color="white"
                  dense
                  flat
                  label="Детализация"
                  text-color="primary"
                  @click="showHistoryDetailsModal(item)"
                />
              </q-item-label>

              <q-item-label class="text-body2 q-mb-sm">
                <div
                  v-for="(value, key) in item.formatted_diff"
                  v-if="
                    !(value == null && item.formatted_diff_old[key] == null)
                  "
                  :key="key"
                  class="q-mb-sm"
                >
                  <span
                    class="text-primary cursor-pointer"
                    @click="showProjectParameterHistoryModal(key)"
                  >
                    {{ getReadableKey(key) }}
                  </span>
                  :
                  <span class="bg-deep-orange-2">{{
                    item.formatted_diff_old[key]
                  }}</span>
                  <q-icon name="mdi-arrow-right" />
                  <span class="bg-green-2">{{ value }}</span>
                </div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <q-banner
        v-if="!history.length && !awaitResponse"
        class="bg-grey-4 full-width"
      >
        Нет данных
      </q-banner>
    </div>
  </div>
</template>

<script>
  import ProjectHeader from "@/components/projects/ProjectHeader";
  import ProjectHistoryDetailsDialog from "@/components/dialogs/ProjectHistoryDetailsDialog";
  import ProjectParameterHistoryDialog from "@/components/dialogs/ProjectParameterHistoryDialog";

  const KEY_MAPPING = {
    name: "Название",
    links: "Ссылки",
    polygon: "Участок",
    rightholder: "Правообладатель",
    sales_start: "Старт продаж",
    source_note: "Примечание к источникам",
    source_detail: "Уточнение источников",
    infrastructure: "Инфраструктура",
    broker_bn: "Брокеридж",
    bnmap_note: "Заметка для bnMAP",
    ap_project_serial: "АП проект",
    development_stage_note: "Заметка к стадии проработки",
    max_floor: "Предельная этажность",
    max_height: "Предельная высота",
    square: "S участка, га",
    psn_qty: "ПСН, шт",
    flat_qty: "Квартир, шт",
    psn_square: "S ПСН, м²",
    area_square: "Общая S проекта, м²",
    flat_square: "S квартир, м²",
    floor_square: "S сумм. поэтажная, м²",
    apartment_qty: "Апартаменты, шт",
    living_square: "S жилая, м²",
    renovation_qty: "Реновация, шт",
    surface_square: "S наземная, м²",
    apartment_square: "S апартаментов, м²",
    building_density: "Плотность застройки, м²/га",
    non_living_square: "S нежилая, м²",
    renovation_square: "S реновации, м²",
    underground_square: "S подземная, м²",
    surface_parking_qty: "Наземн. паркинг, м/м",
    surface_parking_square: "S наземн. паркинга, м²",
    underground_parking_qty: "Подземн. паркинг, м/м",
    underground_parking_square: "S подземного паркинга, м²",
    addressName: "Адрес",
    addressCoordinates: "Координаты",
    projectStatusName: "Статус проекта",
    privacyLevelName: "Конфиденциальность",
    developmentStageName: "Стадия проработки",
    estateSubjectName: "Формат проекта",
    estateTypes: "Типы недвижимости",
    estateClasses: "Классы недвижимости",
    sourceInfos: "Источники информации",
    developers: "Девелоперы",
    cadastrals: "Кадастры",
    transportStations: "Транспортные остановки",
    actualizationStatus: "Актуальность",
    actualizationActualizedAt: "Актуализировано",
    actualizationActualizedBy: "Кто актуализировал ID",
    projectKindName: "Тип проекта",
  };

  export default {
    name: "ProjectHistory",

    props: {
      project: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    components: {
      ProjectHeader,
    },

    data() {
      return {
        awaitResponse: false,
        history: [],
      };
    },

    async created() {
      await this.setHistory();
    },

    methods: {
      async setHistory() {
        this.awaitResponse = true;

        const res = await this.$api.project.findById(
          this.project.id,
          "history.creator"
        );

        if (res.status === 200) {
          this.history = res.data.history ? res.data.history.reverse() : [];
        }

        this.awaitResponse = false;
      },

      showHistoryDetailsModal(history) {
        this.$q.dialog({
          component: ProjectHistoryDetailsDialog,
          parent: this,
          // props
          history,
        });
      },

      showProjectParameterHistoryModal(key) {
        const paramName = this.getReadableKey(key);
        const paramHistory = this.history
          .filter((obj) => Object.keys(obj.formatted_diff).includes(key))
          .map((item) => {
            return {
              new_value: item.formatted_diff[key],
              old_value: item.formatted_diff_old[key]
                ? item.formatted_diff_old[key]
                : null,
              created_at: item.created_at,
            };
          });

        this.$q.dialog({
          component: ProjectParameterHistoryDialog,
          parent: this,
          // props
          paramHistory,
          paramName,
        });
      },

      showHistoryParameterListDialog() {
        const content =
          '<div style="white-space: pre;">' +
          Object.values(KEY_MAPPING).sort().join("\n") +
          "</div>";

        this.$q.dialog({
          title: "Список сохраняемых параметров",
          message: content,
          html: true,
          ok: {
            label: "Ясно",
          },
        });
      },

      getReadableKey(key) {
        return KEY_MAPPING[key];
      },
    },
  };
</script>