export const MOSCOW_CENTER_COORDINATES = [37.63, 55.74]; // lng, lat
export const RUSSIA_CENTER_COORDINATES = [92.776336, 63.455846];

/**
 *
 * @param {Array} coords
 * @param {Boolean} reverse
 * @return {string|null}
 */
export function coordsToString(coords, reverse = false) {
  if (!(coords && coords[0] && coords[1])) {
    return "";
  }

  if (reverse) {
    coords.reverse();
  }

  return coords[0] + ", " + coords[1];
}

export function coordsToArray(string, reverse = false) {
  if (!string) {
    return null;
  }

  if (!/[,]\s/.test(string)) {
    return null;
  }

  let parts = string.replace(",", "").split(" ");
  let result = [Number(parts[0]), Number(parts[1])];

  if (reverse) {
    result.reverse();
  }

  return result;
}
