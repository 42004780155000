<template>
  <div class="col col-md-12">
    <ListsTable entity="metro" />
  </div>
</template>

<script>
  import ListsTable from "@/components/MetroListsTable.vue";

  export default {
    name: "MetroList",

    components: {
      ListsTable,
    },

    meta: {
      title: "Метро",
    },
  };
</script>