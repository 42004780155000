/**
 * Children of Settings Routes
 */

import RoleEdit from "../../../pages/settings/roles/RoleEdit";
import RoleList from "../../../pages/settings/roles/RoleList";
import multiguard from "vue-router-multiguard";
import checkPermissions from "../../../middleware/checkPermissions";

const roleRoutes = [
  {
    path: "",
    name: "RoleList",
    component: RoleList,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["role.view"],
      crumbs: "/Настройки/Роли",
    },
  },
  {
    path: ":id",
    redirect: ":id/edit",
  },
  {
    path: ":id/edit",
    name: "RoleEdit",
    component: RoleEdit,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["role.update"],
      crumbs: "/Настройки/Роли/{:}/Изменить",
    },
  },
];

export default roleRoutes;
