import axios from "axios";
import { lf } from "./localforage";
import Notify from 'quasar/src/plugins/Notify.js';;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/v1",
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async (config) => {
  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";
  config.headers["Accept-Language"] = "ru";

  const token = await lf.getItem("access_token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === "development") {
      console.log("res", res);
    }

    return res;
  },
  (error) => {
    if (error.response && error.response.status) {
      const status = error.response.status;

      if (status === 429) {
        Notify.create({
          color: "warning",
          textColor: "black",
          message:
            "Превышено количество запросов. Пожалуйста, подождите 1 мин.",
          timeout: 60000,
        });
      }

      // always show server error notification
      if (status === 500) {
        Notify.create({
          color: "negative",
          message: "Ошибка сервера.",
          timeout: 5000,
        });
      }
    }

    return Promise.reject(error);
  }
);

export { axiosInstance };
