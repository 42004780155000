<template>
  <div
    v-if="Object.keys(bizRegion).length"
    class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2"
  >
    <ValidationObserver ref="observer" slim>
      <form
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        autocorrect="off"
      >
        <fieldset :disabled="awaitResponse" class="no-border q-px-none">
          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Название</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Название"
                rules="required|min:2|max:191"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="bizRegion.name"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>

            <div class="col-9 col-md-6 text-grey-8">Коды</div>
            <div class="col-15 col-md-18">
              <q-select
                v-model="bizRegion.codes"
                multiple
                use-input
                use-chips
                new-value-mode="add-unique"
                label="Коды"
              />
            </div>

            <div class="flex column q-py-md">
              <q-checkbox v-model="bizRegion.settings.metro" label="Метро" />
              <q-checkbox
                v-model="bizRegion.settings.railway"
                label="Ж/Д станции"
              />
              <q-checkbox
                v-model="bizRegion.settings.renovation"
                label="Программа реновации"
              />
              <q-checkbox
                v-model="bizRegion.settings.address_city_area"
                label="Административный округ"
              />
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>

    <div class="row">
      <q-btn
        label="Сохранить"
        color="primary"
        :loading="awaitSaving"
        :disable="awaitSaving"
        @click="save"
      />
      <q-space />
      <q-btn
        v-if="+$can(['biz-region.delete'])"
        :loading="awaitDeleting"
        :disable="awaitDeleting"
        icon="mdi-delete-outline"
        outline
        round
        color="negative"
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "BizRegionEdit",

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    meta() {
      return {
        title: this.title,
      };
    },

    data() {
      return {
        awaitResponse: false,
        awaitSaving: false,
        awaitDeleting: false,
        title: "",
        bizRegion: {},
      };
    },

    async created() {
      this.awaitResponse = true;

      let res = await this.$api.bizRegion.find();

      this.bizRegion = res.data.filter((i) => i.id === this.id)[0];
      this.title = "Редактирование " + res.data.name;

      this.awaitResponse = false;
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            let data = {
              name: this.bizRegion.name,
              codes: this.bizRegion.codes,
              settings: this.bizRegion.settings,
            };

            this.$api.bizRegion
              .update(this.bizRegion.id, data)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.bizRegion = res.data.bizRegion;
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      remove() {
        this.$q
          .dialog({
            title: `Удаление бизнес-региона ${this.bizRegion.name}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await this.$api.bizRegion
              .delete(this.bizRegion.id)
              .then(
                (res) => {
                  if (res.status === 200) {
                    this.$q.notify({
                      color: "positive",
                      message: res.data.message,
                    });

                    this.$router.push({ name: "BizRegionList" });
                  }
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },
    },
  };
</script>