<template>
  <div class="col col-md-12">
    <ListsTable entity="estate-class" />
  </div>
</template>

<script>
  import ListsTable from "@/components/ListsTable";

  export default {
    name: "EstateClassList",

    components: {
      ListsTable,
    },

    meta: {
      title: "Классы недвижимости",
    },
  };
</script>