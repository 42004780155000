<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card
      class="l-project-upload-files-card"
      style="width: 960px; max-width: 960px"
    >
      <div class="flex items-center justify-between q-px-md">
        <div>Загрузка файлов для проекта</div>
        <q-btn v-close-popup flat icon="mdi-close" size="lg" />
      </div>

      <q-card-section>
        <q-uploader
          ref="uploader"
          :disable="awaitUploading"
          :max-file-size="256000000"
          :max-total-size="256000000"
          class="l-project-upload-files-card__uploader full-width"
          color="transparent"
          flat
          multiple
          @added="onAdded"
        >
          <template v-slot:header="scope">
            <div class="row no-wrap items-center justify-between q-py-sm">
              <q-btn
                :disable="awaitUploading"
                color="primary"
                icon="mdi-plus"
                label="Выбрать файлы"
                title="Загрузить файл с компьютера"
              >
                <q-uploader-add-trigger v-if="scope.canAddFiles" />
              </q-btn>

              <q-btn
                :disable="$_isEmpty(scope.queuedFiles)"
                icon="mdi-delete-outline"
                label="Очистить список"
                text-color="black"
                title="Убрать все файлы из списка"
                @click="deleteAll"
              />
            </div>
          </template>

          <template v-slot:list="scope">
            <q-item v-for="(file, index) in scope.queuedFiles" :key="index">
              <q-item-section>
                <div style="word-break: break-word">
                  <q-icon name="mdi-file" />
                  {{ file.name }}
                </div>
              </q-item-section>

              <q-item-section>
                <q-input v-model="files[index].custom_name" label="Название" />
              </q-item-section>

              <q-item-section>
                <q-input v-model="files[index].source" label="Источник" />
              </q-item-section>

              <q-item-section>
                <q-input
                  v-model="files[index].relevance_date"
                  clearable
                  filled
                  label="Дата актуальности"
                  @click="$refs[`datePicker${index}`][0].show()"
                >
                  <template v-slot:append>
                    <q-icon class="cursor-pointer" name="mdi-calendar">
                      <q-popup-proxy :ref="`datePicker${index}`">
                        <q-date
                          v-model="files[index].relevance_date"
                          mask="YYYY-MM-DD"
                        >
                          <div class="row items-center justify-end q-gutter-sm">
                            <q-btn
                              v-close-popup
                              color="primary"
                              flat
                              label="Ок"
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </q-item-section>

              <q-item-section side>
                <q-btn
                  :disable="awaitUploading"
                  dense
                  flat
                  icon="mdi-delete"
                  title="Убрать из списка"
                  @click="deleteFile(file, index)"
                />
              </q-item-section>
            </q-item>
          </template>
        </q-uploader>
      </q-card-section>

      <q-card-actions class="q-pa-md justify-between">
        <q-btn
          v-close-popup
          :disable="awaitUploading"
          label="Отмена"
          title="Закрыть окно загрузки файлов"
        />
        <q-btn
          :disable="awaitUploading"
          :loading="awaitUploading"
          color="primary"
          label="Загрузить"
          title="Загрузить"
          @click="uploadNow"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import { extractFileExtension } from "@/utils/batch";

  export default {
    name: "ProjectUploadFilesDialog",

    props: {
      projectId: Number,
      isFileAttaching: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        awaitUploading: false,
        files: [],
        proxyDate: null,
      };
    },

    methods: {
      uploadNow() {
        // just additional guard
        if (this.awaitUploading) {
          return;
        }

        if (this.$_isEmpty(this.$refs.uploader.files)) {
          this.$q.notify({
            color: "negative",
            message: "Пожалуйста, выберите файлы.",
          });
        } else {
          this.awaitUploading = true;

          let formData = new FormData();

          this.files.forEach((item, index) => {
            if (item.hasOwnProperty("custom_name")) {
              this.files[index].custom_name = `${
                item.custom_name
              }.${extractFileExtension(item.name)}`;
            }
          });

          this.$refs.uploader.files.forEach((item, i) => {
            formData.append("files[]", this.$refs.uploader.files[i]);
            formData.append("meta", JSON.stringify(this.files));
          });

          this.$api.project
            .uploadFiles(this.projectId, formData)
            .then((res) => {
              this.$root.$emit("files-uploaded", {
                isFileAttaching: this.isFileAttaching,
                files: res.data.files,
              });

              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.hide();
            })
            .then(() => {
              this.awaitUploading = false;
            });
        }
      },

      onAdded(files) {
        if (this.$refs.uploader.files.length > 10) {
          this.$q.notify({
            color: "negative",
            message: "Можно прикрепить не более 10 файлов за раз.",
          });

          Array.from(files).forEach((item) => {
            this.$refs.uploader.removeFile(item);
          });
        } else {
          Array.from(files).forEach((file) => {
            this.files.push({
              name: file.name,
              source: null,
              relevance_date: null,
            });
          });
        }
      },

      deleteFile(file, index) {
        this.$refs.uploader.removeFile(file);
        this.files.splice(index, 1);
      },

      deleteAll() {
        if (this.$refs.uploader) {
          this.$refs.uploader.reset();
        }

        this.files = [];
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.deleteAll();
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.deleteAll();
        this.$emit("hide");
      },
    },
  };
</script>

<style lang="scss">
  .l-project-upload-files-card {
    .l-project-upload-files-card__uploader {
      max-height: 600px;
    }

    .q-uploader__list {
      padding: 0;
    }
  }
</style>