<template>
  <ValidationObserver ref="observer" disabled slim>
    <q-btn
      v-if="replyEntityId"
      :disable="awaitResponse"
      color="primary"
      dense
      flat
      label="отменить ответ"
      no-caps
      @click="resetSubmitForm"
    />

    <form ref="form">
      <ValidationProvider
        ref="textInputProvider"
        v-slot="{ errors }"
        mode="passive"
        name="Комментарий"
        rules="required|min:1|max:3000"
      >
        <q-input
          ref="commentInput"
          v-model="text"
          :error="errors && !!errors.length"
          :error-message="errors[0]"
          autogrow
          bottom-slots
          clearable
          label="Добавить комментарий..."
          type="textarea"
        >
          <template v-slot:append>
            <q-btn dense flat label="🙂" @click="isEmojiPickerShowed = true">
              <q-menu>
                <EmojiPicker @select="onSelectEmoji" />
              </q-menu>
            </q-btn>
          </template>

          <template v-slot:after>
            <q-btn
              :disable="
                awaitResponse ||
                !(typeof text === 'string' && text.length) ||
                awaitUploadFiles
              "
              :loading="awaitResponse"
              color="primary"
              dense
              flat
              icon="mdi-send"
              round
              type="button"
              @click="submitCommentForm"
            />
          </template>
        </q-input>

        <q-btn
          v-if="showAttachFilesBtn"
          dense
          flat
          icon="mdi-attachment"
          label="Прикрепить файлы"
          @click="showUploadFilesDialog"
        />
      </ValidationProvider>
    </form>
  </ValidationObserver>
</template>

<script>
  import ProjectUploadFilesDialog from "@/components/dialogs/ProjectUploadFilesDialog";
  import EmojiPicker from "@/components/EmojiPicker";

  export default {
    name: "WriteComment",

    props: {
      entity: String,
      entityId: Number,
      category: {
        type: Number,
        default: null,
      },
      showAttachFilesBtn: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      ProjectUploadFilesDialog,
      EmojiPicker,
    },

    data() {
      return {
        awaitResponse: false,
        awaitUploadFiles: false,
        isEmojiPickerShowed: false,
        text: null,
        replyEntity: null,
        replyEntityId: null,
      };
    },

    async created() {
      this.$root.$on("reply-to-comment", this.onReplyToComment);
      this.$root.$on("files-uploaded", this.onFilesUploaded);
      this.$root.$on("comment-file", this.onCommentFile);
    },

    destroyed() {
      this.$root.$off("reply-to-comment", this.onReplyToComment);
      this.$root.$off("files-uploaded", this.onFilesUploaded);
      this.$root.$off("comment-file", this.onCommentFile);
    },

    methods: {
      submitCommentForm() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitResponse = true;

            let data = {
              text: this.text,
              category: this.category,
            };

            let entity = this.replyEntity ? this.replyEntity : this.entity;
            let entityId = this.replyEntityId
              ? this.replyEntityId
              : this.entityId;

            this.$api.comment
              .create(entity, entityId, data, "commented")
              .then(
                (res) => {
                  this.$root.$emit("comment-added", {
                    comment: res.data.comment,
                  });

                  this.resetSubmitForm();
                },
                (error) => {
                  if (error.response.status !== 500) {
                    this.$q.notify({
                      color: "negative",
                      message: error.response.data.message,
                    });
                  }
                }
              )
              .then(() => {
                this.awaitResponse = false;
              });
          }
        });
      },

      resetSubmitForm() {
        this.replyEntity = null;
        this.replyEntityId = null;
        this.text = null;
        this.$refs.textInputProvider.reset();
        this.$refs.form.reset();
      },

      onReplyToComment(payload) {
        this.text = "";
        this.replyEntity = payload.comment.entity;
        this.replyEntityId = payload.comment.id;
        this.$refs.commentInput.focus();
      },

      onCommentFile(payload) {
        this.checkTextInputAndAddSpace();
        this.text += `[${payload.file.name}](${payload.file.url}) `;
        this.$refs.commentInput.focus();
      },

      onFilesUploaded(payload) {
        if (!payload.isFileAttaching) {
          return;
        }

        this.checkTextInputAndAddSpace();

        payload.files.forEach((file) => {
          this.text += `[${file.name}](${file.url}) `;
        });

        this.$refs.form.reset();
      },

      showUploadFilesDialog() {
        this.$q.dialog({
          component: ProjectUploadFilesDialog,
          parent: this,
          // props
          isFileAttaching: true,
          projectId: this.entityId,
        });
      },

      checkTextInputAndAddSpace() {
        if (!this.text) {
          this.text = "";
        } else {
          this.text += " ";
        }
      },

      onSelectEmoji(emoji) {
        this.checkTextInputAndAddSpace();

        this.text += `${emoji} `;

        this.isEmojiPickerShowed = false;
      },
    },
  };
</script>