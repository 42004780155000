import * as Sentry from "@sentry/vue";
import Vue from "vue";

if (process.env.VUE_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    debug: process.env.VUE_APP_SENTRY_DEBUG === "true",
  });
}
