import { axiosInstance as axios } from "@/plugins/axios";

const user = {
  findUserById(id, include = "", transformer = null) {
    return axios.get(`/users/${id}`, { params: { include, transformer } });
  },

  find(include = "") {
    return include
      ? axios.get(`/users`, { params: { include } })
      : axios.get(`/users`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/users", data, { params: { include } })
      : axios.post("/users", data);
  },

  update(id, data, include = "", transformer = null) {
    return include
      ? axios.patch(`/users/${id}`, data, { params: { include, transformer } })
      : axios.patch(`/users/${id}`, data);
  },

  updateSettings(id, data, include = "") {
    return include
      ? axios.patch(`/users/${id}/settings`, data, { params: { include } })
      : axios.patch(`/users/${id}/settings`, data);
  },

  delete(id) {
    return axios.delete(`/users/${id}`);
  },
};

export default user;
