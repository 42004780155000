import { axiosInstance as axios } from "@/plugins/axios";

const address = {
  findById(id, include = "") {
    return include
      ? axios.get(`addresses/${id}`, { params: { include } })
      : axios.get(`addresses/${id}`);
  },

  create(data, include = "") {
    return include
      ? axios.post("addresses", data, { params: { include } })
      : axios.post("addresses", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`addresses/${id}`, data, { params: { include } })
      : axios.patch(`addresses/${id}`, data);
  },

  geocodeByName(name, include = "") {
    return include
      ? axios.post(
          "addresses/geocode/by-name",
          { name },
          { params: { include } }
        )
      : axios.post("addresses/geocode/by-name", { name });
  },

  geocodeByCoordinates(longitude, latitude, include = "") {
    return include
      ? axios.post(
          "addresses/geocode/by-coordinates",
          { longitude, latitude },
          { params: { include } }
        )
      : axios.post("addresses/geocode/by-coordinates", { longitude, latitude });
  },
};

export default address;
