<template>
  <div class="col col-16">
    <q-table
      :columns="columns"
      :data="permissions"
      :filter="filter"
      :loading="loading"
      :pagination.sync="pagination"
      :selected.sync="selected"
      dense
      row-key="id"
      selection="multiple"
    >
      <template v-slot:top>
        <div v-show="!loading" class="row items-center q-gutter-md">
          <div>{{ role.readable_name }}</div>
          <q-btn
            :disable="!+$can(['role.update']) || awaitSaving"
            :loading="awaitSaving"
            color="positive"
            label="Сохранить"
            @click="saveRole"
          />
        </div>

        <q-space />

        <q-input v-model="filter" color="primary" debounce="300" dense outlined>
          <template v-slot:append>
            <q-icon name="mdi-magnify" />
          </template>
        </q-input>
      </template>
    </q-table>
  </div>
</template>

<script>
  export default {
    name: "RoleEdit",

    meta: {
      title: "Изменить роль",
    },

    data() {
      return {
        loading: false,
        awaitSaving: false,
        filter: "",
        role: {},
        selected: [],
        permissions: [],
        pagination: {
          rowsPerPage: 20,
        },
        columns: [
          {
            name: "readable_name",
            required: true,
            label: "Описание",
            align: "left",
            field: (row) => row.readable_name,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Название",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
        ],
      };
    },

    async created() {
      this.loading = true;

      await Promise.all([this.setRole(), this.setPermissions()]);

      this.loading = false;
    },

    methods: {
      async setRole() {
        const res = await this.$api.role.findById(
          this.$route.params.id,
          "permissions"
        );

        if (res.status === 200) {
          this.role = res.data;
          this.selected = res.data.permissions;
        }
      },

      async setPermissions() {
        const res = await this.$api.permission.find();

        if (res.status === 200) {
          this.permissions = res.data;
        }
      },

      saveRole() {
        this.awaitSaving = true;

        const ids = this.selected.map((item) => item.id);

        this.$api.role
          .update(
            this.role.id,
            {
              permissions: ids,
            },
            "permissions"
          )
          .then(
            (res) => {
              if (res.status === 200) {
                this.role = res.data.role;
                this.selected = res.data.role.permissions;

                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitSaving = false;
          });
      },
    },
  };
</script>