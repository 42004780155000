<template>
  <div
    v-if="actualizationStatistics"
    class="flex items-center q-pa-xs q-gutter-x-md"
  >
    <div>
      Проектов:
      <q-badge>{{ actualizationStatistics.total }}</q-badge>
    </div>
    <div>
      Актуальных:
      <q-badge>{{ actualizationStatistics.actual }}</q-badge>
    </div>

    <div
      class="flex q-px-md q-py-xs"
      style="border: 1px dashed darkgrey; border-radius: 4px"
    >
      <div class="flex q-gutter-x-md">
        <div>
          На актуализацию:
          <q-badge>{{ actualizationStatistics.toActualization }} </q-badge
          >&nbsp;
          <q-badge
            >{{ actualizationStatistics.toActualizationPercent }}%
          </q-badge>
        </div>
        <div>
          Актуализировано:
          <q-badge>{{ actualizationStatistics.actualized }} </q-badge>&nbsp;
          <q-badge>{{ actualizationStatistics.actualizedPercent }}%</q-badge>
        </div>
      </div>
    </div>

    <q-btn dense flat icon="mdi-information-outline">
      <q-tooltip>
        Статистика актуализации проектов.<br />
        Проектов, шт. Статический параметр (не изменяется от выбранных фильтров
        таблицы). Отображает общее количество проектов в базе по всем
        статусам.<br />
        Актуальных, шт. Статический параметр. Отображает общее количество
        актуальных проектов.<br />
        На актуализацию, шт. Динамический параметр (меняется в зависимости от
        выбранных фильтров таблицы). Число проектов с желтой и красной
        метками.<br />
        На актуализацию, %. Динамический параметр. = проектов на актуализацию /
        всего актуальных.<br />
        Актуализировано, шт. Динамический параметр. Число проектов с зеленой
        меткой.<br />
        Актуализировано, %. Динамический параметр. = проектов актуализировано /
        всего актуальных.<br />
      </q-tooltip>
    </q-btn>
  </div>
</template>

<script>
  export default {
    name: "ProjectTableStatistics",

    props: {
      actualizationStatistics: {
        type: Object,
        required: false,
      },
    },
  };
</script>