export const mutations = {
  SET_COMMENT_EDITING: "SET_COMMENT_EDITING",
  SET_WINDOW_INNER_HEIGHT: "SET_WINDOW_INNER_HEIGHT",
};

export default {
  [mutations.SET_COMMENT_EDITING](state, payload) {
    state.commentEditing = {
      state: payload.state,
      id: payload.id,
    };
  },
  [mutations.SET_WINDOW_INNER_HEIGHT](state, height) {
    state.windowInnerHeight = height;
  },
};
