<template>
  <div
    class="row q-py-lg"
    :class="$q.screen.xs || $q.screen.sm ? 'q-px-md' : ''"
  >
    <div class="col-24 col-md-12">
      <ProjectTitle :project="project" />
    </div>
    <div class="col-24 col-md-12 flex items-center justify-end">
      <ProjectViewers :viewers="viewers" />
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
  import ProjectTitle from "@/components/projects/ProjectTitle";
  import ProjectViewers from "@/components/projects/ProjectViewers";

  export default {
    name: "ProjectHeader",

    props: {
      project: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    components: {
      ProjectTitle,
      ProjectViewers,
    },
  };
</script>