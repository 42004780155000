import BizRegionEdit from "../../../pages/settings/bizRegions/BizRegionEdit";
import BizRegionList from "../../../pages/settings/bizRegions/BizRegionList";
import BizRegionAdd from "../../../pages/settings/bizRegions/BizRegionAdd";

const bizRegionRoutes = [
  {
    path: "",
    name: "BizRegionList",
    component: BizRegionList,
    meta: {
      layout: "main",
      permissions: ["biz-region.update"],
      crumbs: "/Настройки/Регионы (бизнес)",
    },
  },
  {
    path: "add",
    name: "BizRegionAdd",
    component: BizRegionAdd,
    meta: {
      layout: "main",
      permissions: ["biz-region.add"],
      crumbs: "/Настройки/Регионы (бизнес)/Добавить",
    },
  },
  {
    path: ":id",
    redirect: ":id/edit",
  },
  {
    path: ":id/edit",
    name: "BizRegionEdit",
    component: BizRegionEdit,
    props: (route) => ({ id: Number(route.params.id) }),
    meta: {
      layout: "main",
      permissions: ["biz-region.update"],
      crumbs: "/Настройки/Регионы (бизнес)/{:}/Изменить",
    },
  },
];

export default bizRegionRoutes;
