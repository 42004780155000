<template>
  <div class="col col-md-14">
    <q-table
      :columns="columns"
      :data="permissions"
      :filter="filter"
      :loading="loading"
      :pagination.sync="pagination"
      dense
      row-key="id"
    >
      <template v-slot:top>
        <q-input v-model="filter" color="primary" debounce="300" dense outlined>
          <template v-slot:append>
            <q-icon name="mdi-magnify" />
          </template>
        </q-input>

        <q-space />

        <q-btn
          v-if="+$can(['permission.add'])"
          :to="{ name: 'PermissionAdd' }"
          color="positive"
          icon="mdi-plus"
        />
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="readable_name" :props="props"
            >{{ props.row.readable_name }}
          </q-td>
          <q-td key="name" :props="props">{{ props.row.name }}</q-td>
          <q-td key="actions" :props="props">
            <q-btn
              :disable="!+$can(['role.delete']) || awaitDeleting"
              :loading="awaitDeleting"
              dense
              flat
              icon="mdi-delete-outline"
              @click="confirmDelete(props.row.id)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
  export default {
    name: "PermissionList",

    meta: {
      title: "Разрешения",
    },

    data() {
      return {
        loading: false,
        awaitDeleting: false,
        filter: "",
        permissions: [],
        pagination: {
          rowsPerPage: 15,
        },
      };
    },

    computed: {
      columns() {
        return [
          {
            name: "readable_name",
            required: true,
            label: "Описание",
            align: "left",
            field: (row) => row.readable_name,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Название",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
          {
            name: "actions",
            label: "Действия",
            align: "right",
          },
        ];
      },
    },

    created() {
      this.setPermissions();
    },

    methods: {
      async setPermissions() {
        this.loading = true;

        let res = await this.$api.permission.find();

        if (res.status === 200) {
          this.permissions = res.data;
        }

        this.loading = false;
      },

      confirmDelete(id) {
        this.$q
          .dialog({
            title: "Удаление разрешения",
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            await this.deleteOne(id);
          });
      },

      async deleteOne(id) {
        this.awaitDeleting = true;

        await this.$api.permission
          .delete(id)
          .then(
            (res) => {
              if (res.status === 200) {
                const index = this.permissions.findIndex(
                  (item) => item.id === id
                );

                this.permissions.splice(index);

                this.$q.notify({
                  color: "positive",
                  message: res.data.message,
                });
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitDeleting = false;
          });
      },
    },
  };
</script>