import { axiosInstance as axios } from "@/plugins/axios";

/**
 * Analytic Platform API gate
 */
const ap = {
  findBuildings(options) {
    return axios.get("/ap/buildings", { params: { ...options } });
  },

  findProjects(options) {
    return axios.get("/ap/projects", { params: { ...options } });
  },
};

export default ap;
