<template>
  <div class="col">
    <div :style="getStylesForSubmenu()" class="row">
      <div class="col-24 col-md-5 col-lg-3 bg-white">
        <q-list
          :disabled="loading"
          class="l-menu q-pa-md rounded-borders text-primary"
        >
          <q-item
            v-for="item in menuItems"
            :key="item.component"
            :active="component === item.component"
            active-class="l-menu__item--active"
            clickable
            @click="component = item.component"
          >
            <q-item-section>{{ item.label }}</q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="col-24 col-md-19 col-lg-21">
        <div class="row justify-center">
          <div
            v-if="Object.keys(project).length && !loading"
            class="col-24"
            :class="!($q.screen.xs || $q.screen.sm) ? 'q-px-xl' : ''"
          >
            <component
              :is="component"
              :lists="{
                projectStatuses,
                privacyLevels,
                developmentStages,
                estateClasses,
                estateSubjects,
                estateTypes,
                developers,
                sourceInfos,
                projectKinds,
              }"
              :project="project"
              :viewers="viewers"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectCommon from "@/components/projects/edit/ProjectCommon";
  import ProjectHistory from "@/components/projects/edit/ProjectHistory";
  import ProjectAddress from "@/components/projects/edit/ProjectAddress";
  import ProjectSpecification from "@/components/projects/edit/ProjectSpecification";
  import ProjectPhoto from "@/components/projects/edit/ProjectPhoto";
  import ProjectDocument from "@/components/projects/edit/ProjectDocument";
  import { customSort } from "@/utils/batch";
  import { dom } from "quasar";

  const { height } = dom;

  export default {
    name: "ProjectEdit",

    components: {
      ProjectCommon,
      ProjectHistory,
      ProjectAddress,
      ProjectSpecification,
      ProjectPhoto,
      ProjectDocument,
    },

    meta() {
      return {
        title: this.title,
      };
    },

    data() {
      return {
        loading: false,
        title: "Редактирование проекта",
        component: "ProjectCommon",
        viewers: [],
        project: {},
        projectStatuses: [],
        privacyLevels: [],
        developmentStages: [],
        estateClasses: [],
        estateSubjects: [],
        estateTypes: [],
        developers: [],
        sourceInfos: [],
        projectKinds: [],
      };
    },

    computed: {
      menuItems() {
        return [
          {
            component: "ProjectCommon",
            label: "Общее",
          },
          {
            component: "ProjectAddress",
            label: "Местоположение",
          },
          {
            component: "ProjectSpecification",
            label: "Показатели",
          },
          {
            component: "ProjectDocument",
            label: "Документы",
          },
          {
            component: "ProjectPhoto",
            label: "Фото",
          },
          {
            component: "ProjectHistory",
            label: "История",
          },
        ];
      },
    },

    async mounted() {
      this.loading = true; // is used for hide page data before initial data loaded

      await Promise.all([this.setProjectLists(), this.getProject()]);

      // if user click back button fast
      if (this.$route.name !== "ProjectEdit") {
        return;
      }

      const channel = `project-${this.project.id}`;

      await this.setViewers(channel); // set viewers for first time
      this.checkChannelPresenceInterval(channel); // update viewers by interval

      this.subscription = this.subscribeOnChannel(channel);

      await this.$api.centrifugo.publish(channel, {
        action: "join",
        user: {
          id: this.$store.getters["auth/userId"],
          name: this.$store.getters["auth/user"].name,
        },
      });

      this.loading = false;
    },

    beforeDestroy() {
      clearTimeout(this.checkChannelPresenceIntervalId);

      // we cant publish after logout
      if (!this.$store.getters["auth/isAuth"]) {
        return;
      }

      this.$api.centrifugo.publish(`project-${this.project.id}`, {
        action: "leave",
        user: {
          id: this.$store.getters["auth/userId"],
        },
      });

      // if user click back button fast
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription.removeAllListeners();
      }
    },

    methods: {
      getProject() {
        const include = [
          "address.country",
          "address.federal_region",
          "address.specific_region",
          "address.region",
          "address.area",
          "address.city",
          "address.city_area",
          "address.city_district",
          "project_status",
          "privacy_level",
          "development_stage",
          "estate_classes",
          "estate_types",
          "estate_subject",
          "developers",
          "source_infos",
          "cadastrals",
          "transport_stations.station",
          "project_kind",
          "actualization",
          "biz_region",
        ];

        return this.$api.project
          .findById(this.$route.params.id, include.join(","))
          .then((res) => {
            if (res.status === 200) {
              this.project = res.data;
              this.title = res.data.name || "Редактирование проекта";
            }

            if (res.status === 204) {
              this.$router.push({ name: "ProjectList" });
            }
          });
      },

      async setProjectLists() {
        const res = await this.$api.lists.findBatchForEntity("project");

        this.projectStatuses = res.data.projectStatuses;
        this.privacyLevels = res.data.privacyLevels;
        this.developmentStages = res.data.developmentStages;
        this.estateClasses = res.data.estateClasses;
        this.estateSubjects = res.data.estateSubjects;
        this.estateTypes = res.data.estateTypes;
        this.developers = customSort(res.data.developers, "name");
        this.sourceInfos = res.data.sourceInfos;
        this.projectKinds = res.data.projectKinds;
      },

      getQPageHeight() {
        return height(document.getElementsByClassName("q-page")[0]);
      },

      getStylesForSubmenu() {
        let styles = "";

        if (this.$q.screen.md || this.$q.screen.lg || this.$q.screen.xl) {
          styles += "min-height:" + this.getQPageHeight() + "px";
        }

        return styles;
      },

      subscribeOnChannel(channel) {
        return this.$centrifuge.subscribe(channel, (payload) => {
          if (payload.data.action === "join") {
            const index = this.viewers.findIndex(
              (item) => item.id === payload.data.user.id
            );

            if (
              index === -1 &&
              payload.data.user.id !== this.$store.getters["auth/userId"]
            ) {
              this.viewers.push(payload.data.user);
            }
          }

          if (payload.data.action === "leave") {
            const index = this.viewers.findIndex(
              (item) => item.id === payload.data.user.id
            );

            if (index > -1) {
              this.viewers.splice(index, 1);
            }
          }
        });
      },

      setViewers(channel) {
        return this.$api.centrifugo.presence(channel).then((res) => {
          if (res.data.users) {
            // dont show yourself
            res.data.users = res.data.users.filter(
              (item) => item.id !== this.$store.getters["auth/userId"]
            );

            this.viewers = res.data.users.map((item) => {
              return { id: item.id, name: item.name };
            });
          }
        });
      },

      checkChannelPresenceInterval(channel) {
        this.checkChannelPresenceIntervalId = setTimeout(() => {
          this.setViewers(channel);

          this.checkChannelPresenceInterval(channel);
        }, 15000);
      },
    },
  };
</script>