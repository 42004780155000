<template>
  <div class="l-table__row" role="rowgroup">
    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.id.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.id.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('biz_region')"
      :style="getCellStyle('biz_region')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.biz_region_ids"
        v-bind="searchSelectProps"
        :options="bizRegions"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        use-input
        @filter="filterBizRegions"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('name')"
      :style="getCellStyle('name')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.name.v"
        v-bind="searchInputProps"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.name.c"
            :options="compareStringOptions"
            @input="onSearchCompareInput(search.name.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('address')"
      :style="getCellStyle('address')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.address.v"
        v-bind="searchInputProps"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.address.c"
            :options="compareStringOptions"
            @input="onSearchCompareInput(search.address.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('coordinates')"
      :style="getCellStyle('coordinates')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.trim="search.coordinates"
        type="text"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('specific_region')"
      :style="getCellStyle('specific_region')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.specific_region_ids"
        v-bind="searchSelectProps"
        :options="specificRegions"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        use-input
        @filter="filterSpecificRegions"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('city_area')"
      :style="getCellStyle('city_area')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.city_area_ids"
        v-bind="searchSelectProps"
        :options="cityAreas"
        class="lq-select-chip-multiline"
        multiple
        option-label="short_name"
        use-chips
        @input="onSearchInput"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
            <q-item-section>
              {{ scope.opt.short_name }}<br />
              <span class="text-body3 text-grey-6">{{ scope.opt.name }}</span>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('city_district')"
      :style="getCellStyle('city_district')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.city_district_ids"
        v-bind="searchSelectProps"
        :options="cityDistricts"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        use-input
        @filter="filterCityDistricts"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('project_status')"
      :style="getCellStyle('project_status')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.project_status_ids"
        v-bind="searchSelectProps"
        :options="projectStatuses"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('project_kind')"
      :style="getCellStyle('project_kind')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.project_kind_ids"
        v-bind="searchSelectProps"
        :options="projectKinds"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('developers')"
      :style="getCellStyle('developers')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.developer_ids"
        v-bind="searchSelectProps"
        :options="developers"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        use-input
        @filter="filterDevelopers"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('rightholder')"
      :style="getCellStyle('rightholder')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.rightholder.v"
        v-bind="searchInputProps"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.rightholder.c"
            :options="compareStringOptions"
            @input="onSearchCompareInput(search.rightholder.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('development_stage')"
      :style="getCellStyle('development_stage')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.development_stage_ids"
        v-bind="searchSelectProps"
        :options="developmentStages"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('privacy_level')"
      :style="getCellStyle('privacy_level')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.privacy_level_ids"
        v-bind="searchSelectProps"
        :options="privacyLevels"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('sales_start')"
      :style="getCellStyle('sales_start')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.sales_start"
        v-bind="searchInputProps"
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('estate_types')"
      :style="getCellStyle('estate_types')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.estate_type_ids"
        v-bind="searchSelectProps"
        :options="estateTypes"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('estate_subject')"
      :style="getCellStyle('estate_subject')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.estate_subject_ids"
        v-bind="searchSelectProps"
        :options="estateSubjects"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('estate_classes')"
      :style="getCellStyle('estate_classes')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.estate_class_ids"
        v-bind="searchSelectProps"
        :options="estateClasses"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('source_infos')"
      :style="getCellStyle('source_infos')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.source_info_ids"
        v-bind="searchSelectProps"
        :options="sourceInfos"
        class="lq-select-chip-multiline"
        multiple
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('square')"
      :style="getCellStyle('square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('area_square')"
      :style="getCellStyle('area_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.area_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.area_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.area_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('surface_square')"
      :style="getCellStyle('surface_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.surface_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.surface_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.surface_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('underground_square')"
      :style="getCellStyle('underground_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.underground_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.underground_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.underground_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floor_square')"
      :style="getCellStyle('floor_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.floor_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floor_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.floor_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.living_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.living_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('non_living_square')"
      :style="getCellStyle('non_living_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.non_living_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.non_living_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.non_living_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('renovation_square')"
      :style="getCellStyle('renovation_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.renovation_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.renovation_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.renovation_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('flat_square')"
      :style="getCellStyle('flat_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.flat_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.flat_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.flat_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('apartment_square')"
      :style="getCellStyle('apartment_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.apartment_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.apartment_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.apartment_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('psn_square')"
      :style="getCellStyle('psn_square')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.psn_square.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.psn_square.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.psn_square.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('surface_parking_qty')"
      :style="getCellStyle('surface_parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.surface_parking_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.surface_parking_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.surface_parking_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('underground_parking_qty')"
      :style="getCellStyle('underground_parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.underground_parking_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.underground_parking_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.underground_parking_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('renovation_qty')"
      :style="getCellStyle('renovation_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.renovation_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.renovation_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.renovation_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('flat_qty')"
      :style="getCellStyle('flat_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.flat_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.flat_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.flat_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('apartment_qty')"
      :style="getCellStyle('apartment_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.apartment_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.apartment_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.apartment_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('psn_qty')"
      :style="getCellStyle('psn_qty')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.psn_qty.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.psn_qty.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.psn_qty.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('max_height')"
      :style="getCellStyle('max_height')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.max_height.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.max_height.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.max_height.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('max_floor')"
      :style="getCellStyle('max_floor')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.max_floor.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.max_floor.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.max_floor.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('building_density')"
      :style="getCellStyle('building_density')"
      class="l-table__cell"
    >
      <q-input
        v-bind="searchInputProps"
        v-model.number="search.building_density.v"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.building_density.c"
            :options="compareNumberOptions"
            @input="onSearchCompareInput(search.building_density.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.cadastral.v"
        v-bind="searchInputProps"
        @input="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.cadastral.c"
            :options="compareStringOptions"
            @input="onSearchCompareInput(search.cadastral.v)"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('metro')"
      :style="getCellStyle('metro')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.metroSelected"
        :option-value="(opt) => opt"
        :options="metro"
        class="lq-select-chip-multiline"
        dense
        multiple
        options-dense
        use-input
        @filter="filterMetro"
        @input="onSearchInput"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
            <q-item-section avatar>
              <div
                :style="getBgColorStyleForMetroLine(scope.opt)"
                class="l-metro-line-circle"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ scope.opt.name
                }}<span v-if="scope.opt.short_name"
                  >&nbsp({{ scope.opt.short_name }})</span
                >
              </q-item-label>
              <q-item-label caption>{{ scope.opt.line }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon
                v-if="scope.opt.status === 'строится'"
                name="mdi-sign-caution"
                title="Строится"
              />
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:selected-item="scope">
          <q-chip
            :tabindex="scope.tabindex"
            color="secondary"
            dense
            outline
            removable
            @remove="scope.removeAtIndex(scope.index)"
          >
            <div
              :style="getBgColorStyleForMetroLine(scope.opt)"
              class="l-metro-line-circle q-mr-sm"
            />
            {{ scope.opt.name | shortness(15) }}
            <span v-if="scope.opt.short_name" class="q-ml-sm"
              >({{ scope.opt.short_name }})</span
            >
            <q-icon
              v-if="scope.opt.status === 'строится'"
              class="q-ml-sm"
              name="mdi-sign-caution"
              title="Строится"
            />
          </q-chip>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('railway')"
      :style="getCellStyle('railway')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.railwaySelected"
        :option-value="(opt) => opt"
        :options="railway"
        class="lq-select-chip-multiline"
        dense
        multiple
        option-label="name"
        options-dense
        use-chips
        use-input
        @filter="filterRailway"
        @input="onSearchInput"
      >
        <template v-slot:selected-item="scope">
          <q-chip
            :tabindex="scope.tabindex"
            color="secondary"
            dense
            outline
            removable
            @remove="scope.removeAtIndex(scope.index)"
          >
            {{ scope.opt.name | shortness(15) }}
          </q-chip>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('actualization_status')"
      :style="getCellStyle('actualization_status')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.actualization_statuses"
        :options="actualizationStatuses"
        class="lq-select-chip-multiline"
        dense
        emit-value
        map-options
        multiple
        options-dense
        use-chips
        @input="onSearchInput"
      >
        <template v-slot:selected-item="scope">
          <q-chip
            :tabindex="scope.tabindex"
            dense
            removable
            @remove="scope.removeAtIndex(scope.index)"
          >
            <div :class="scope.opt.className"></div>
          </q-chip>
        </template>

        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
            <q-item-section side>
              <div :class="scope.opt.className" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ scope.opt.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('actualized_at')"
      :style="getCellStyle('actualized_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.actualized_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.actualized_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.actualized_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerActualizedFrom">
                <q-date
                  v-model="search.actualized_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerActualizedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.actualized_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.actualized_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.actualized_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerActualizedTo">
                <q-date
                  v-model="search.actualized_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerActualizedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('actualized_by')"
      :style="getCellStyle('actualized_by')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.actualized_by_ids"
        v-bind="searchSelectProps"
        :options="users"
        class="lq-select-chip-multiline"
        emit-value
        multiple
        option-label="name"
        option-value="id"
        use-chips
        @input="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('comment')"
      :style="getCellStyle('comment')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('created_at')"
      :style="getCellStyle('created_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedFrom">
                <q-date
                  v-model="search.created_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedTo">
                <q-date
                  v-model="search.created_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import { METRO_LINE_COLOR_MAPPING, customSort } from "@/utils/batch";
  import ComparisonSelect from "@/components/projects/ComparisonSelect";

  const SEARCH_SELECT_PROPS = {
    "option-value": "id",
    "option-label": "name",
    "options-dense": true,
    "emit-value": true,
    "map-options": true,
    dense: true,
  };

  const SEARCH_INPUT_PROPS = {
    debounce: 500,
    dense: true,
    color: "primary",
    clearable: true,
  };

  export default {
    name: "ProjectTableFiltersRow",

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      pagination: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        projectStatuses: [],
        privacyLevels: [],
        developmentStages: [],
        developers: [],
        estateClasses: [],
        estateSubjects: [],
        estateTypes: [],
        sourceInfos: [],
        metro: [],
        railway: [],
        projectKinds: [],
        specificRegions: [],
        cityAreas: [],
        cityDistricts: [],
        bizRegions: [],
        users: [],
      };
    },

    computed: {
      actualizationStatuses() {
        return [
          {
            value: 9,
            label: "Актуален",
            className: "l-actualization-status l-actualization-status--green",
          },
          {
            value: 6,
            label: "Нужна актуализация",
            className: "l-actualization-status l-actualization-status--yellow",
          },
          {
            value: 3,
            label: "Просрочено",
            className: "l-actualization-status l-actualization-status--red",
          },
        ];
      },

      compareNumberOptions() {
        return [
          {
            label: "Равно",
            icon: "mdi-equal",
            value: "eq",
          },
          {
            label: "Не равно",
            icon: "mdi-not-equal-variant",
            value: "neq",
          },
          {
            label: "Больше",
            icon: "mdi-greater-than",
            value: "gt",
          },
          {
            label: "Больше или равно",
            icon: "mdi-greater-than-or-equal",
            value: "gte",
          },
          {
            label: "Меньше",
            icon: "mdi-less-than",
            value: "lt",
          },
          {
            label: "Меньше или равно",
            icon: "mdi-less-than-or-equal",
            value: "lte",
          },
        ];
      },

      compareStringOptions() {
        return [
          {
            label: "Содержит",
            icon: "mdi-contain",
            value: "ctn",
          },
          {
            label: "Начинается с",
            icon: "mdi-contain-start",
            value: "sw",
          },
          {
            label: "Заканчивается на",
            icon: "mdi-contain-end",
            value: "ew",
          },
          {
            label: "Равно",
            icon: "mdi-equal",
            value: "eq",
          },
          {
            label: "Не равно",
            icon: "mdi-not-equal-variant",
            value: "neq",
          },
        ];
      },
    },

    watch: {
      "search.actualized_at.from"() {
        this.$emit("filter-projects");
      },
      "search.actualized_at.to"() {
        this.$emit("filter-projects");
      },
      "search.created_at.from"() {
        this.$emit("filter-projects");
      },
      "search.created_at.to"() {
        this.$emit("filter-projects");
      },
    },

    async mounted() {
      await this.setLists();
      this.$emit("set-projects", { pagination: this.pagination });
    },

    methods: {
      async setLists() {
        const [
          resBatch,
          resMetro,
          resRailway,
          resSpecificRegion,
          resCityArea,
          resCityDistrict,
          resUser,
        ] = await Promise.all([
          this.$api.lists.findBatchForEntity("project"),
          this.$api.metro.find(),
          this.$api.railway.find(),
          this.$api.specificRegion.find(),
          this.$api.cityArea.find(),
          this.$api.cityDistrict.find(),
          +this.$can(["user.list.view"])
            ? this.$api.user.find()
            : new Promise((resolve) => resolve({ status: 403 })),
        ]);

        this.bizRegions = this.$store.getters["auth/user"].biz_regions;
        this.projectStatuses = resBatch.data.projectStatuses;
        this.privacyLevels = resBatch.data.privacyLevels;
        this.developmentStages = resBatch.data.developmentStages;
        this.estateClasses = resBatch.data.estateClasses;
        this.estateSubjects = resBatch.data.estateSubjects;
        this.estateTypes = resBatch.data.estateTypes;
        this.sourceInfos = resBatch.data.sourceInfos;
        this.developersData = resBatch.data.developers;
        this.developers = this.developersData;
        this.projectKinds = resBatch.data.projectKinds;
        this.metroData = customSort(resMetro.data, "line_alias");
        this.metro = this.metroData;
        this.railwayData = customSort(resRailway.data, "name");
        this.railway = this.railwayData;
        this.specificRegionsData = resSpecificRegion.data;
        this.specificRegions = this.specificRegionsData;
        this.cityAreas = customSort(resCityArea.data, "short_name");
        this.cityDistrictsData = resCityDistrict.data;
        this.cityDistricts = this.cityDistrictsData;
        this.users = resUser.data;
      },

      filterMetro(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.metro = this.metroData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterRailway(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.railway = this.railwayData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * @param {String} val
       * @param {function} update
       * @return {void}
       */
      filterCityDistricts(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.cityDistricts = this.cityDistrictsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterDevelopers(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.developers = this.developersData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterSpecificRegions(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.specificRegions = this.specificRegionsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterBizRegions(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.bizRegions = this.$store.getters["auth/user"].biz_regions.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      /**
       * Metro line circle color
       */
      getBgColorStyleForMetroLine(metro) {
        if (!metro.line_alias) {
          return;
        }

        return "background-color:" + METRO_LINE_COLOR_MAPPING[metro.line_alias];
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      onSearchInput(val) {
        this.$emit("filter-projects");
      },

      onSearchCompareInput(val) {
        if (!val) {
          return;
        }

        this.$emit("filter-projects");
      },
    },
  };
</script>
