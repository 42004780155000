import multiguard from "vue-router-multiguard";
import auth from "../../middleware/auth";
import checkPermissions from "../../middleware/checkPermissions";

import Users from "../../pages/settings/users/Users";
import Permissions from "../../pages/settings/permissions/Permissions";
import Roles from "../../pages/settings/roles/Roles";
import Settings from "../../pages/settings/Settings";
import BizRegions from "../../pages/settings/bizRegions/BizRegions";

// nested
import userRoutes from "./settings/userRoutes";
import permissionRoutes from "./settings/permissionRoutes";
import roleRoutes from "./settings/roleRoutes";
import bizRegionRoutes from "./settings/bizRegionRoutes";

const settingsRoutes = [
  {
    path: "/settings",
    component: Settings,
    beforeEnter: multiguard([auth, checkPermissions]),
    children: [
      {
        path: "",
        redirect: "users",
      },
      {
        path: "biz-regions",
        component: BizRegions,
        meta: {
          layout: "main",
          permissions: ["biz-region.update"],
          crumbs: "/Настройки/Регионы (бизнес)",
        },
        children: bizRegionRoutes,
      },
      {
        path: "users",
        component: Users,
        meta: {
          layout: "main",
          permissions: ["user.view"],
          crumbs: "/Настройки/Пользователи",
        },
        children: userRoutes,
      },
      {
        path: "roles",
        component: Roles,
        meta: {
          layout: "main",
          permissions: ["role.view"],
          crumbs: "/Настройки/Роли",
        },
        children: roleRoutes,
      },
      {
        path: "permissions",
        component: Permissions,
        meta: {
          layout: "main",
          permissions: ["permission.view"],
          crumbs: "/Настройки/Разрешения",
        },
        children: permissionRoutes,
      },
    ],
  },
];

export default settingsRoutes;
