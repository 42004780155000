import { axiosInstance as axios } from "@/plugins/axios";

const estateType = {
  find(include = "") {
    return include
      ? axios.get(`/estate-types`, { params: { include } })
      : axios.get(`/estate-types`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/estate-types", data, { params: { include } })
      : axios.post("/estate-types", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/estate-types/${id}`, data, { params: { include } })
      : axios.patch(`/estate-types/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/estate-types/${id}`);
  },
};

export default estateType;
