/**
 * @param projectName
 * @param addressName
 * @return String
 */
export function getPointHintContent(projectName, addressName) {
  if (projectName && addressName) {
    return projectName + " | " + addressName;
  } else if (projectName || addressName) {
    return projectName || addressName;
  }

  return "нет данных";
}
