<template>
  <ValidationObserver ref="observer" slim>
    <div class="row q-col-gutter-x-md q-pb-lg justify-center">
      <div class="col-24">
        <ProjectHeader :project="project" :viewers="viewers">
          <template v-slot:buttons>
            <q-btn
              v-if="+$can(['project.update'])"
              :disable="awaitSaving"
              :loading="awaitSaving"
              class="q-ml-md"
              color="positive"
              icon="mdi-content-save"
              label="Сохранить Показатели"
              no-caps
              @click="save"
            />
          </template>
        </ProjectHeader>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--blue rounded-borders shadow-2 bg-white q-mb-md"
        >
          <div class="q-pa-md">Проект</div>

          <q-separator />

          <div class="q-pa-md">
            <form
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
            >
              <fieldset
                :disabled="awaitSaving"
                class="no-border q-pa-none q-ma-none"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    decimal: true,
                    max_value_decimal: 9999999.99,
                    gte_then_sum_of_2: {
                      target1: 'living_square',
                      target2: 'non_living_square',
                      fieldNames: 'S жилая + S нежилая',
                    },
                    gte_then_sum_of_2_1: {
                      target1: 'underground_square',
                      target2: 'surface_square',
                      fieldNames: 'S наземная + S подземная',
                    },
                  }"
                  name="Общая S проекта"
                >
                  <q-input
                    v-model="project.area_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Общая S проекта, м²"
                    @input="formatInput('area_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S наземная"
                  rules="decimal|max_value_decimal:9999999.99"
                  vid="surface_square"
                >
                  <q-input
                    v-model="project.surface_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S наземная, м²"
                    @input="formatInput('surface_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S подземная"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="underground_square"
                >
                  <q-input
                    v-model="project.underground_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S подземная, м²"
                    @input="formatInput('underground_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S сумм. поэтажная"
                  rules="decimal|max_value_decimal:9999999.99"
                >
                  <q-input
                    v-model="project.floor_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S сумм. поэтажная, м²"
                    @input="formatInput('floor_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Предельная этажность"
                  rules="integer|max_value_integer:999"
                >
                  <q-input
                    v-model="project.max_floor"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Предельная этажность"
                    @input="formatInput('max_floor')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Предельная высота"
                  rules="decimal|max_value_decimal:999.99"
                >
                  <q-input
                    v-model="project.max_height"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Предельная высота, м"
                    @input="formatInput('max_height')"
                  />
                </ValidationProvider>
              </fieldset>
            </form>
          </div>
        </div>

        <div
          class="l-card l-card--green rounded-borders shadow-2 bg-white q-mb-md"
        >
          <div class="q-pa-md">Участок</div>
          <q-separator />

          <div class="q-pa-md">
            <form
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
            >
              <fieldset
                :disabled="awaitSaving"
                class="no-border q-pa-none q-ma-none"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="S участка"
                  rules="decimal|max_value_decimal:999.99"
                >
                  <q-input
                    v-model="project.square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S участка, га"
                    @input="formatInput('square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Плотность застройки"
                  rules="decimal"
                >
                  <q-input
                    v-model="project.building_density"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Плотность застройки, м²/га"
                    @input="formatInput('building_density')"
                  />
                </ValidationProvider>

                <q-input
                  v-model="cadastralPrice"
                  disable
                  label="Общая кадастровая стоимость участков"
                />

                <q-input
                  v-model="cadastralSquare"
                  disable
                  label="S участка по кадастру, га."
                />
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--deep-orange rounded-borders shadow-2 bg-white q-mb-md"
        >
          <div class="q-pa-md">Начинка. Жилое</div>
          <q-separator />

          <div class="q-pa-md">
            <form
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
            >
              <fieldset
                :disabled="awaitSaving"
                class="no-border q-pa-none q-ma-none"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    decimal: true,
                    max_value_decimal: 9999999.99,
                    gte_then_sum_of_2: {
                      target1: 'flat_square',
                      target2: 'renovation_square',
                      fieldNames: 'S квартир + S реновации',
                    },
                  }"
                  name="S жилая"
                  vid="living_square"
                >
                  <q-input
                    v-model="project.living_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S жилая, м²"
                    @input="formatInput('living_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S квартир"
                  rules="decimal|max_value_decimal:9999999.99"
                  vid="flat_square"
                >
                  <q-input
                    v-model="project.flat_square"
                    :disable="disableFlatFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S квартир, м²"
                    @input="formatInput('flat_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Квартир, шт"
                  rules="integer|max_value_integer:99999"
                >
                  <q-input
                    v-model="project.flat_qty"
                    :disable="disableFlatFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Квартир, шт"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-show="project.biz_region.settings.renovation"
                  v-slot="{ errors }"
                  name="S реновации"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="renovation_square"
                >
                  <q-input
                    v-model="project.renovation_square"
                    :disable="disableRenovationFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S реновации, м²"
                    @input="formatInput('renovation_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-show="project.biz_region.settings.renovation"
                  v-slot="{ errors }"
                  name="Реновация, шт"
                  rules="integer|max_value_integer:99999"
                >
                  <q-input
                    v-model="project.renovation_qty"
                    :disable="disableRenovationFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Реновация, шт"
                    @input="formatInput('renovation_qty')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S апартаментов"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="apartment_square"
                >
                  <q-input
                    v-model="project.apartment_square"
                    :disable="disableApartmentFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S апартаментов, м²"
                    @input="formatInput('apartment_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Апартаменты, шт"
                  rules="integer|max_value_integer:99999"
                >
                  <q-input
                    v-model="project.apartment_qty"
                    :disable="disableApartmentFields"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Апартаменты, шт"
                    @input="formatInput('apartment_qty')"
                  />
                </ValidationProvider>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      <div class="col-24 col-md-8">
        <div
          class="l-card l-card--brown rounded-borders shadow-2 bg-white q-mb-md"
        >
          <div class="q-pa-md">Начинка. Нежилое</div>
          <q-separator />

          <div class="q-pa-md">
            <form
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
            >
              <fieldset
                :disabled="awaitSaving"
                class="no-border q-pa-none q-ma-none"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    decimal: true,
                    max_value_decimal: 999999.99,
                    gte_then_sum_of_4: {
                      target1: 'underground_parking_square',
                      target2: 'surface_parking_square',
                      target3: 'psn_square',
                      target4: 'apartment_square',
                      fieldNames:
                        'S подземн. паркинга + S наземн. паркинга + S ПСН + S апартаментов',
                    },
                  }"
                  name="S нежилая"
                  vid="non_living_square"
                >
                  <q-input
                    v-model="project.non_living_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S нежилая, м²"
                    @input="formatInput('non_living_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S ПСН"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="psn_square"
                >
                  <q-input
                    v-model="project.psn_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S ПСН, м²"
                    @input="formatInput('psn_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="ПСН, шт"
                  rules="integer|max_value_integer:999"
                >
                  <q-input
                    v-model="project.psn_qty"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="ПСН, шт"
                    @input="formatInput('psn_qty')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S подземн. паркинга"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="underground_parking_square"
                >
                  <q-input
                    v-model="project.underground_parking_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S подземного паркинга, м²"
                    @input="formatInput('underground_parking_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Подземн. паркинг, м/м"
                  rules="integer|max_value_integer:99999"
                >
                  <q-input
                    v-model="project.underground_parking_qty"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Подземн. паркинг, м/м"
                    @input="formatInput('underground_parking_qty')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="S наземн. паркинга"
                  rules="decimal|max_value_decimal:999999.99"
                  vid="surface_parking_square"
                >
                  <q-input
                    v-model="project.surface_parking_square"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="S наземн. паркинга, м²"
                    @input="formatInput('surface_parking_square')"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Наземн. паркинг, м/м"
                  rules="integer|max_value_integer:99999"
                >
                  <q-input
                    v-model="project.surface_parking_qty"
                    :error="errors && !!errors.length"
                    :error-message="errors[0]"
                    bottom-slots
                    debounce="500"
                    hide-bottom-space
                    label="Наземн. паркинг, м/м"
                    @input="formatInput('surface_parking_qty')"
                  />
                </ValidationProvider>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import ProjectHeader from "@/components/projects/ProjectHeader";
  import {
    numberFormat,
    numberFormatReverse,
    rubFormat,
  } from "@/utils/numberFormatter";

  export default {
    name: "ProjectSpecification",

    props: {
      project: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    components: {
      ProjectHeader,
    },

    data() {
      return {
        awaitSaving: false,
        disableFlatFields: true,
        disableRenovationFields: true,
        disableApartmentFields: true,
        cadastralPrice: null,
        cadastralSquare: null,
      };
    },

    created() {
      this.calcCadastralPrice();
      this.calcCadastralSquare();

      const toFormat = [
        "square",
        "area_square",
        "surface_square",
        "surface_parking_square",
        "underground_square",
        "underground_parking_square",
        "floor_square",
        "living_square",
        "non_living_square",
        "renovation_square",
        "flat_square",
        "apartment_square",
        "psn_square",
        "surface_parking_qty",
        "underground_parking_qty",
        "renovation_qty",
        "flat_qty",
        "apartment_qty",
        "psn_qty",
        "max_height",
        "max_floor",
        "building_density",
      ];

      toFormat.forEach((property) => {
        if (typeof this.project[property] === "number") {
          this.project[property] = numberFormat(this.project[property]);
        }
      });
    },

    mounted() {
      if (this.project.estate_types && this.project.estate_types.length) {
        const aliases = this.project.estate_types.map((item) => item.alias);

        if (aliases.includes("kvartiry")) {
          this.disableFlatFields = false;
        }

        if (aliases.includes("kvartiry-pod-renovaciyu")) {
          this.disableRenovationFields = false;
        }

        if (aliases.includes("apartamenty")) {
          this.disableApartmentFields = false;
        }
      }
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitSaving = true;

            let data = {
              square: this.project.square,
              area_square: this.project.area_square,
              underground_square: this.project.underground_square,
              surface_square: this.project.surface_square,
              floor_square: this.project.floor_square,
              non_living_square: this.project.non_living_square,
              living_square: this.project.living_square,
              underground_parking_square:
                this.project.underground_parking_square,
              surface_parking_square: this.project.surface_parking_square,
              psn_square: this.project.psn_square,
              apartment_square: this.project.apartment_square,
              flat_square: this.project.flat_square,
              renovation_square: this.project.renovation_square,
              underground_parking_qty: this.project.underground_parking_qty,
              surface_parking_qty: this.project.surface_parking_qty,
              psn_qty: this.project.psn_qty,
              renovation_qty: this.project.renovation_qty,
              apartment_qty: this.project.apartment_qty,
              flat_qty: this.project.flat_qty,
              building_density: this.project.building_density,
              max_floor: this.project.max_floor,
              max_height: this.project.max_height,
            };

            for (let [key, val] of Object.entries(data)) {
              if (typeof val === "string") {
                data[key] = numberFormatReverse(val);
              }
            }

            this.$api.project
              .updateSpecification(this.project.id, data)
              .then(
                (res) => {
                  this.$q.notify({
                    color: "positive",
                    message: res.data.message,
                  });
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitSaving = false;
              });
          }
        });
      },

      /**
       * Simple decimal "mask"
       * @param propName
       */
      formatInput(propName) {
        const number = numberFormatReverse(this.project[propName]);
        this.project[propName] = numberFormat(number);
      },

      calcCadastralPrice() {
        if (!this.project.cadastrals) {
          return;
        }

        // check if all cadastrals have price.
        // p.s. just business, nothing personal.
        const bool = this.project.cadastrals.every(
          (item) => item.price && item.price > 0
        );

        if (bool) {
          this.cadastralPrice = this.project.cadastrals.reduce((sum, item) => {
            return sum + item.price;
          }, 0);

          this.cadastralPrice = rubFormat(this.cadastralPrice);
        }
      },

      calcCadastralSquare() {
        if (!this.project.cadastrals) {
          return;
        }

        // check if all cadastrals have square.
        // p.s. just business, nothing personal.
        const bool = this.project.cadastrals.every(
          (item) => item.square && item.square > 0
        );

        if (bool) {
          this.cadastralSquare = this.project.cadastrals.reduce((sum, item) => {
            // PKK have square in m^2, we need ha.
            return sum + item.square / 10000;
          }, 0);

          this.cadastralSquare = numberFormat(this.cadastralSquare);
        }
      },
    },
  };
</script>