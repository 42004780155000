<template>
  <div class="row bg-white">
    <div
      class="col-24 col-md-6 col-lg-4"
      style="border-right: 1px solid lightgrey"
    >
      <div class="q-pa-md q-gutter-y-md">
        <q-item-label class="q-pa-none" header>Общие фильтры</q-item-label>

        <q-select
          v-model="search.biz_region_ids"
          :options="authUserBizRegions"
          :disable="awaitProjects || awaitApBuildings"
          emit-value
          filled
          label="Регионы"
          map-options
          multiple
          option-label="name"
          option-value="id"
          options-dense
          use-chips
          @input="onInputBizRegions"
        >
          <template v-slot:selected-item="scope">
            <q-chip
              :tabindex="scope.tabindex"
              dense
              removable
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt.name | shortness(20) }}
            </q-chip>
          </template>
        </q-select>
      </div>

      <q-separator spaced />

      <div class="q-pa-md q-gutter-y-md">
        <q-item-label class="q-pa-none" header>Фильтры Проектов</q-item-label>

        <q-select
          v-model="search.project_status_ids"
          :options="projectStatuses"
          emit-value
          filled
          label="Статус"
          map-options
          multiple
          option-label="name"
          option-value="id"
          options-dense
          use-chips
          @input="setProjects"
        >
          <template v-slot:selected-item="scope">
            <q-chip
              :color="getChipHexColor(scope.opt.alias)"
              :tabindex="scope.tabindex"
              dense
              removable
              text-color="white"
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt.name | shortness(20) }}
            </q-chip>
          </template>
        </q-select>
      </div>

      <q-separator spaced />

      <div class="q-pa-md relative-position">
        <q-list>
          <q-item-label class="q-pa-none" header
            >Фильтры АП корпусов
          </q-item-label>

          <q-item class="q-pa-none">
            <q-item-section>
              <q-item-label>Показать на карте</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle
                v-model="showApBuildings"
                color="blue"
                @input="toggleApBuildings"
              />
            </q-item-section>
          </q-item>
        </q-list>

        <q-select
          v-model="searchAp.sale_statuses"
          :disable="!showApBuildings || awaitApBuildings"
          :options="apSaleStatuses"
          emit-value
          filled
          label="Статус"
          map-options
          multiple
          option-label="name"
          option-value="id"
          options-dense
          use-chips
          @input="filterBlueCollectionBySaleStatus"
        >
          <template v-slot:selected-item="scope">
            <q-chip
              :color="getChipHexColor(scope.opt.id)"
              :tabindex="scope.tabindex"
              dense
              removable
              text-color="white"
              @remove="scope.removeAtIndex(scope.index)"
            >
              {{ scope.opt.name | shortness(20) }}
            </q-chip>
          </template>
        </q-select>

        <q-inner-loading :showing="awaitApBuildings">
          <q-spinner size="50px" />
        </q-inner-loading>
      </div>
    </div>

    <div class="col-24 col-md-18 col-lg-20">
      <div
        id="map-projects"
        class="relative-position"
        style="height: calc(100vh - 50px)"
      >
        <div class="l-map-search absolute-left q-ma-md">
          <q-input
            v-model.trim="searchOnMap"
            class="l-map-search__input bg-white"
            clearable
            color="primary"
            debounce="500"
            dense
            label="название или адрес"
            outlined
            @input="onInputSearchOnMap"
            @mouseenter="isSearchOnMapOptionsShowed = true"
          >
            <template v-slot:append>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>

          <div
            v-show="isSearchOnMapOptionsShowed"
            @mouseleave="isSearchOnMapOptionsShowed = false"
          >
            <q-virtual-scroll
              v-if="searchOnMapOptions.length"
              :items="searchOnMapOptions"
              class="l-map-search__options"
            >
              <template v-slot="{ item, index }">
                <q-item
                  :key="index"
                  clickable
                  @click="onSearchOnMapOptionClick(item)"
                >
                  <q-item-section>
                    <q-item-label>{{ item.title }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-virtual-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { RUSSIA_CENTER_COORDINATES } from "@/utils/coords";
  import { getPointHintContent } from "@/utils/map";
  import { getProjectsTableQueryForRequest } from "@/utils/project";

  const RED_OBJECT_MANAGER_NAME = "projects";
  const BLUE_OBJECT_MANAGER_NAME = "apBuildings";

  const RED_OBJECT_MANAGER_COLORS = {
    perspektivnyy: "#DC3545",
    anonsirovannyy: "#7B1FA2",
    zamorozhennyy: "#5D4037",
    "ne-opredeleno": "#F57C00",
    "v-realizacii": "#009688",
  };

  const BLUE_OBJECT_MANAGER_COLORS = {
    0: "#000000", // Удален
    1: "#007BFF", // В реализации
    2: "#3949AB", // На запуске
    3: "#17A2B8", // Сделки
    4: "#757575", // Архив
    5: "#8BCF6E", // Закрытые продажи
    6: "#263238", // Заморожен
  };

  const QUASAR_PALETTE_MAPPING = {
    perspektivnyy: "red",
    anonsirovannyy: "purple-8",
    zamorozhennyy: "brown-8",
    "ne-opredeleno": "orange-8",
    "v-realizacii": "teal-6",
    0: "black",
    1: "blue",
    2: "indigo-7",
    3: "cyan",
    4: "grey-7",
    5: "light-green-5",
    6: "blue-grey-10",
  };

  export default {
    name: "Map",

    meta: {
      title: "Карта",
    },

    data() {
      return {
        awaitProjects: false,
        awaitApBuildings: false,
        showApBuildings: false,
        isSearchOnMapOptionsShowed: false,
        projects: [],
        projectStatuses: [],
        searchOnMap: null,
        searchOnMapOptions: [],
        search: {
          id: null,
          project_status_ids: [],
          biz_region_ids: [],
        },
        searchAp: {
          sale_statuses: [],
        },
      };
    },

    computed: {
      apSaleStatuses() {
        return [
          { id: 0, name: "Удален" },
          { id: 1, name: "В реализации" },
          { id: 2, name: "На запуске" },
          { id: 3, name: "Сделки" },
          { id: 4, name: "Архив" },
          { id: 5, name: "Закрытые продажи" },
          { id: 6, name: "Заморожен" },
        ];
      },

      authUserBizRegions() {
        return this.$store.getters["auth/user"].biz_regions;
      },
    },

    async created() {
      await this.setLists();

      this.setDefaultFilters();
      this.initNonReactiveProperties();

      await this.initMap();
      await this.setProjects();
    },

    methods: {
      initMap() {
        return new Promise((resolve) => {
          ymaps.ready(() => {
            // if user clicks menu fast
            if (!document.getElementById("map-projects")) {
              return;
            }

            this.myMap = new ymaps.Map(
              "map-projects",
              {
                center: RUSSIA_CENTER_COORDINATES,
                zoom: 4,
                controls: [
                  "zoomControl",
                  "rulerControl",
                  "typeSelector",
                  "fullscreenControl",
                ],
              },
              {
                minZoom: 4,
                maxZoom: 17,
              }
            );

            this.createObjectManagerByName(RED_OBJECT_MANAGER_NAME);
            this.createObjectManagerByName(BLUE_OBJECT_MANAGER_NAME);

            resolve();
          });
        });
      },

      /**
       * Reactivity costs too many memory. Dont push it to data()
       */
      initNonReactiveProperties() {
        this.objectManagers = {
          [RED_OBJECT_MANAGER_NAME]: null,
          [BLUE_OBJECT_MANAGER_NAME]: null,
        };

        this.apBuildingsData = [];
        this.myMap = {};
      },

      /**
       * Get and set Projects
       */
      async setProjects() {
        this.awaitProjects = true;

        let options = {
          q: getProjectsTableQueryForRequest(this.search),
          transformer: "ProjectShortTransformer",
        };

        const res = await this.$api.project.find(
          options,
          "address,project_status"
        );

        if (res.status === 200) {
          this.projects = res.data;
        }

        if (res.status === 204) {
          this.projects = [];
          this.$q.notify({
            color: "warning",
            textColor: "black",
            message: "Проекты не найдены.",
          });
        }

        await this.updateRedObjectManagerCollection(this.projects);
        this.awaitProjects = false;
      },

      /**
       * Set AP Buildings
       */
      async setApBuildings() {
        if (!this.showApBuildings) {
          return;
        }

        this.awaitApBuildings = true;

        const res = await this.$api.ap.findBuildings({
          biz_region_ids: this.search.biz_region_ids,
        });
        this.apBuildingsData = res.data;

        this.filterBlueCollectionBySaleStatus(this.searchAp.sale_statuses);
        this.awaitApBuildings = false;
      },

      /**
       * @param {Boolean} value
       * @return {Promise<void>}
       */
      async toggleApBuildings(value) {
        if (!value) {
          this.objectManagers[BLUE_OBJECT_MANAGER_NAME].removeAll();
          this.searchAp.sale_statuses = [];

          return;
        }

        this.setApDefaultFilters();
        await this.setApBuildings();
      },

      updateBlueObjectManagerCollection(data) {
        return new Promise((resolve) => {
          this.objectManagers[BLUE_OBJECT_MANAGER_NAME].removeAll();

          let featureCollection = this.createFeatureCollection();

          data.forEach((item) => {
            let coordinates = item.coordinates ? item.coordinates : [];

            if (coordinates.length) {
              let point = this.createPointForBlueObjectManager(
                item,
                coordinates
              );

              featureCollection.features.push(point);
            }
          });

          this.objectManagers[BLUE_OBJECT_MANAGER_NAME].add(featureCollection);

          if (this.myMap.geoObjects.getBounds()) {
            this.myMap.setBounds(this.myMap.geoObjects.getBounds());
          }

          return resolve();
        });
      },

      /**
       * Reinitialize red collection
       */
      updateRedObjectManagerCollection(data) {
        return new Promise((resolve) => {
          this.objectManagers[RED_OBJECT_MANAGER_NAME].removeAll();

          let featureCollection = this.createFeatureCollection();

          data.forEach((item) => {
            let coordinates =
              item.address &&
              item.address.coordinates &&
              item.address.coordinates[0]
                ? item.address.coordinates
                : [];

            if (coordinates.length) {
              let point = this.createPointForRedObjectManager(
                item,
                coordinates
              );

              featureCollection.features.push(point);
            }
          });

          this.objectManagers[RED_OBJECT_MANAGER_NAME].add(featureCollection);

          if (this.myMap.geoObjects.getBounds()) {
            this.myMap.setBounds(this.myMap.geoObjects.getBounds());
          }

          return resolve();
        });
      },

      /**
       * @param name
       */
      createObjectManagerByName(name) {
        if (!this.objectManagers[name]) {
          this.objectManagers[name] = new ymaps.ObjectManager({
            clusterize: true,
            gridSize: 32,
            clusterDisableClickZoom: true,
            clusterHideIconOnBalloonOpen: false,
            clusterIconLayout: "default#pieChart",
            clusterIconPieChartRadius: 25,
            clusterIconPieChartCoreRadius: 15,
            clusterIconPieChartStrokeWidth: 3,
          });

          this.myMap.geoObjects.add(this.objectManagers[name]);
        }
      },

      /**
       * @return {{type: string, features: Array}}
       */
      createFeatureCollection() {
        return {
          type: "FeatureCollection",
          features: [],
        };
      },

      /**
       * @param item
       * @param coordinates
       * @return {{geometry: {coordinates: *, type: string}, id: *, type: string, properties: {balloonContentBody: string, hintContent: (*|string), balloonContentHeader: (*|string)}}}
       */
      createPointForBlueObjectManager(item, coordinates) {
        let balloonContentBody = `
        <div>ID: ${item.id}</div>
        <div>Проект: ${
          item.project_name ? item.project_name : "<em>нет данных</em>"
        }</div>
        <div>Адрес: ${
          item.address_name ? item.address_name : "<em>нет данных</em>"
        }</div>
        <div>Старт продаж: ${
          item.sales_start ? item.sales_start : "<em>нет данных</em>"
        }</div>`;

        const hintContent = getPointHintContent(
          item.project_name,
          item.address_name
        );

        return {
          id: item.id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
          properties: {
            hintContent: hintContent,
            balloonContentHeader: item.name || "нет данных",
            balloonContentBody: balloonContentBody,
          },
          options: {
            iconColor: BLUE_OBJECT_MANAGER_COLORS[item.sale_status],
          },
          // custom
          sale_status: item.sale_status,
        };
      },

      /**
       * @param item
       * @param coordinates
       * @return {{geometry: {coordinates: *, type: string}, id: *, type: string, properties: {balloonContentBody: string, hintContent: (*|string), balloonContentHeader: (*|string), balloonContentFooter: string}}}
       */
      createPointForRedObjectManager(item, coordinates) {
        let balloonContentBody = `
        <div>Адрес: ${
          item.address ? item.address.name : "<em>нет данных</em>"
        }</div>
        <div>Старт продаж: ${
          item.sales_start ? item.sales_start : "<em>нет данных</em>"
        }</div>`;

        let balloonContentFooter = `
        <a type="button" href="/projects/${
          item.id || ""
        }/edit" target="_blank" class="l-link">Редактировать</a>`;

        const hintContent = getPointHintContent(
          item.name,
          item.address && item.address.name
        );

        return {
          id: item.id,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
          properties: {
            hintContent: hintContent,
            balloonContentHeader: item.name || "нет данных",
            balloonContentBody: balloonContentBody,
            balloonContentFooter: balloonContentFooter,
          },
          options: {
            iconColor: RED_OBJECT_MANAGER_COLORS[item.project_status.alias],
          },
        };
      },

      /**
       * https://github.com/quasarframework/quasar/issues/5141
       * @return {String}
       */
      getChipHexColor(alias) {
        return QUASAR_PALETTE_MAPPING[alias];
      },

      /**
       * @param {Array} statuses
       */
      filterBlueCollectionBySaleStatus(statuses) {
        if (!statuses.length) {
          this.updateBlueObjectManagerCollection(this.apBuildingsData); // show all
        } else {
          let data = this.apBuildingsData.filter((item) =>
            statuses.includes(item.sale_status)
          );

          this.updateBlueObjectManagerCollection(data);
        }
      },

      /**
       * Default filters for Projects (Red ObjectManager)
       */
      setDefaultFilters() {
        const statusObject = this.projectStatuses.filter(
          (row) => row.name.toLowerCase() === "перспективный"
        )[0];

        if (!statusObject) {
          console.error('"перспективный" not found in projectStatuses');
        } else {
          this.search.project_status_ids = [statusObject.id];
        }
      },

      /**
       * Default filters for AP Buildings (Blue ObjectManager)
       */
      setApDefaultFilters() {
        this.searchAp.sale_statuses = [2];
      },

      /**
       * @return {Promise<void>}
       */
      async setLists() {
        const res = await this.$api.projectStatus.find();

        this.projectStatuses = res.data;
      },

      /**
       * Handles click on list item to filter placemarks and move map view to found item
       * @param item
       */
      onSearchOnMapOptionClick(item) {
        this.isSearchOnMapOptionsShowed = false;
        this.searchOnMap = item.title;
        this.filterMapObjectsBySearch(item.title.toLowerCase().trim());

        this.myMap.panTo(item.coordinates).then(() => {
          this.myMap.setZoom(16);
        });
      },

      /**
       * Handles user input
       * @param val
       */
      onInputSearchOnMap(val) {
        const needle = val ? val.toLowerCase().trim() : "";

        this.filterMapObjectsBySearch(needle);

        this.isSearchOnMapOptionsShowed = true;
      },

      /**
       * ReRender all placemarks accordingly to search query
       * @param string
       */
      filterMapObjectsBySearch(string) {
        this.filterBlueCollectionBySaleStatus(this.searchAp.sale_statuses);
        this.updateRedObjectManagerCollection(this.projects);

        this.searchOnMapOptions = [];

        const blueObjects =
          this.objectManagers[BLUE_OBJECT_MANAGER_NAME].objects.getAll();
        const redObjects =
          this.objectManagers[RED_OBJECT_MANAGER_NAME].objects.getAll();

        const blueResults = blueObjects.filter(
          (i) => i.properties.hintContent.toLowerCase().indexOf(string) > -1
        );
        const redResults = redObjects.filter(
          (i) => i.properties.hintContent.toLowerCase().indexOf(string) > -1
        );

        let mergedData = [...blueResults, ...redResults];

        for (let item of mergedData) {
          this.searchOnMapOptions.push({
            id: item.id,
            title: item.properties.hintContent,
            coordinates: item.geometry.coordinates,
          });
        }

        const blueResultIds = blueResults.map((item) => item.id);
        const redResultIds = redResults.map((item) => item.id);

        const blueData = this.apBuildingsData.filter((item) =>
          blueResultIds.includes(item.id)
        );
        const redData = this.projects.filter((item) =>
          redResultIds.includes(item.id)
        );

        this.updateBlueObjectManagerCollection(blueData);
        this.updateRedObjectManagerCollection(redData);
      },

      onInputBizRegions() {
        this.setProjects();
        this.setApBuildings();
      },
    },
  };
</script>

<style lang="scss">
  .l-map-search {
    z-index: 1;
    height: fit-content;
    width: 320px;

    &__input {
      border-radius: 0.25rem;
    }

    &__options {
      max-height: 300px;
      background-color: white;
      border-radius: 0.25rem;
      border: 1px solid darkgray;
      overflow: auto;
    }
  }
</style>