<template>
  <ValidationObserver ref="observer" disabled slim>
    <form ref="form">
      <ValidationProvider
        ref="textInputProvider"
        v-slot="{ errors }"
        mode="passive"
        name="Комментарий"
        rules="required|min:1|max:3000"
      >
        <q-input
          ref="commentInput"
          v-model="text"
          :error="errors && !!errors.length"
          :error-message="errors[0]"
          autogrow
          bottom-slots
          dense
          hide-bottom-space
          type="textarea"
        >
          <template v-slot:append>
            <q-btn dense flat label="🙂" @click="isEmojiPickerShowed = true">
              <q-menu>
                <EmojiPicker @select="onSelectEmoji" />
              </q-menu>
            </q-btn>
          </template>
        </q-input>

        <div class="col-24 q-py-sm q-gutter-x-md">
          <q-btn
            :disable="
              awaitResponse || !(typeof text === 'string' && text.length)
            "
            :loading="awaitResponse"
            dense
            flat
            label="Отмена"
            type="button"
            @click="
              $store.commit('SET_COMMENT_EDITING', {
                state: false,
                id: commentId,
              })
            "
          />

          <q-btn
            :disable="
              awaitResponse || !(typeof text === 'string' && text.length)
            "
            :loading="awaitResponse"
            color="positive"
            dense
            label="Сохранить"
            type="button"
            @click="submitCommentForm"
          />
        </div>
      </ValidationProvider>
    </form>
  </ValidationObserver>
</template>

<script>
  import { mutations as rootMutations } from "@/store/mutations";
  import EmojiPicker from "@/components/EmojiPicker";

  export default {
    name: "EditComment",

    props: {
      commentId: Number,
      commentText: String,
    },

    components: {
      EmojiPicker,
    },

    data() {
      return {
        awaitResponse: false,
        text: null,
        isEmojiPickerShowed: false,
      };
    },

    async created() {
      this.text = this.commentText;
    },

    methods: {
      submitCommentForm() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitResponse = true;

            let data = {
              text: this.text,
            };

            this.$api.comment
              .update(this.commentId, data, "commented")
              .then(
                (res) => {
                  this.$root.$emit("comment-updated");
                  this.$store.commit(rootMutations.SET_COMMENT_EDITING, {
                    state: false,
                    id: null,
                  });
                  this.$refs.textInputProvider.reset();
                },
                (error) => {
                  if (error.response.status !== 500) {
                    this.$q.notify({
                      color: "negative",
                      message: error.response.data.message,
                    });
                  }
                }
              )
              .then(() => {
                this.awaitResponse = false;
              });
          }
        });
      },

      onSelectEmoji(emoji) {
        this.text += `${emoji} `;

        this.isEmojiPickerShowed = false;
      },
    },
  };
</script>