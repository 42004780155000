<template>
  <div class="col">
    <div class="row justify-center">
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {
    name: "Roles",

    meta: {
      title: "Роли",
    },
  };
</script>