import { axiosInstance as axios } from "@/plugins/axios";

const specificRegion = {
  find(include = "") {
    return include
      ? axios.get(`/specific-regions`, { params: { include } })
      : axios.get(`/specific-regions`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/specific-regions", data, { params: { include } })
      : axios.post("/specific-regions", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/specific-regions/${id}`, data, { params: { include } })
      : axios.patch(`/specific-regions/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/specific-regions/${id}`);
  },
};

export default specificRegion;
