import { axiosInstance as axios } from "@/plugins/axios";

const cityDistrict = {
  find(include = "") {
    return include
      ? axios.get(`/city-districts`, { params: { include } })
      : axios.get(`/city-districts`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/city-districts", data, { params: { include } })
      : axios.post("/city-districts", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/city-districts/${id}`, data, { params: { include } })
      : axios.patch(`/city-districts/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/city-districts/${id}`);
  },
};

export default cityDistrict;
