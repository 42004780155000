import { axiosInstance as axios } from "@/plugins/axios";
import { deleteKeysWithNullValues } from "@/utils/batch";

const project = {
  find(params, include = "") {
    deleteKeysWithNullValues(params.q);

    return axios.get("/projects", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        transformer: params.transformer ? params.transformer : null,
        include: include,
      },
    });
  },

  findById(id, include = "") {
    return axios.get(`/projects/${id}`, { params: { include } });
  },

  create(data) {
    return axios.post("/projects", data);
  },

  updateCommon(id, data, include = "") {
    return include
      ? axios.patch(`/projects/${id}/common`, data, { params: { include } })
      : axios.patch(`/projects/${id}/common`, data);
  },

  updateAddress(id, data, include = "") {
    return include
      ? axios.patch(`/projects/${id}/address`, data, { params: { include } })
      : axios.patch(`/projects/${id}/address`, data);
  },

  deleteAddress(id) {
    return axios.delete(`/projects/${id}/address`);
  },

  updatePolygon(id, data, include = "") {
    return include
      ? axios.patch(`/projects/${id}/polygon`, data, { params: { include } })
      : axios.patch(`/projects/${id}/polygon`, data);
  },

  updateCadastral(id, data, include = "") {
    return include
      ? axios.patch(`/projects/${id}/cadastral`, data, { params: { include } })
      : axios.patch(`/projects/${id}/cadastral`, data);
  },

  updateSpecification(id, data, include = "") {
    return include
      ? axios.patch(`/projects/${id}/specification`, data, {
          params: { include },
        })
      : axios.patch(`/projects/${id}/specification`, data);
  },

  uploadImages(id, formData) {
    return axios.post(`/projects/${id}/images`, formData);
  },

  deleteImage(id, imageId) {
    return axios.delete(`/projects/${id}/images/${imageId}`);
  },

  uploadFiles(id, formData, include) {
    return include
      ? axios.post(`/projects/${id}/files`, formData, { params: { include } })
      : axios.post(`/projects/${id}/files`, formData);
  },

  updateFile(id, fileId, data) {
    return axios.patch(`/projects/${id}/files/${fileId}`, data);
  },

  deleteFiles(id, fileIds) {
    return axios.delete(`/projects/${id}/files?cdn_file_ids=${fileIds}`);
  },

  delete(id) {
    return axios.delete(`/projects/${id}`);
  },

  downloadExcel(params) {
    deleteKeysWithNullValues(params.q);

    return axios.get("/projects/download/excel", {
      params: {
        q: params.q,
        sort_by: params.sort_by,
        descending: params.descending,
        page: params.page,
        limit: params.limit,
        columns: params.columns,
        include: params.include,
      },
      responseType: "arraybuffer",
    });
  },

  actualize(id, include) {
    return include
      ? axios.patch(`/projects/${id}/actualize`, {}, { params: { include } })
      : axios.patch(`/projects/${id}/actualize`);
  },

  actualizationStatistics(params, include = "") {
    deleteKeysWithNullValues(params.q);

    return axios.get("/projects/actualization-statistics", {
      params: {
        q: params.q,
        include: include,
      },
    });
  },
};

export default project;
