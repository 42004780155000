import { axiosInstance as axios } from "@/plugins/axios";

const sourceInfo = {
  find(include = "") {
    return include
      ? axios.get(`/source-infos`, { params: { include } })
      : axios.get(`/source-infos`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/source-infos", data, { params: { include } })
      : axios.post("/source-infos", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/source-infos/${id}`, data, { params: { include } })
      : axios.patch(`/source-infos/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/source-infos/${id}`);
  },
};

export default sourceInfo;
