import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
} from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import * as rules from "vee-validate/dist/rules";
import { numberFormatReverse } from "../utils/numberFormatter";

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: ru.messages[rule],
  });
}

const maxValueSchema = {
  validate: (value, { maxValue }) => {
    if (typeof value === "string") {
      value = numberFormatReverse(value);
    }

    return value <= maxValue;
  },
  params: [{ name: "maxValue" }],
  message: "Значение должно быть меньше или равно {maxValue}.",
};

const gteThenSumOf2Schema = {
  validate: (value, params) => {
    if (typeof value === "string") {
      value = numberFormatReverse(value);
    }

    const targetNames = ["target1", "target2"];
    let sum = 0;

    for (let target of targetNames) {
      if (typeof params[target] === "string") {
        params[target] = numberFormatReverse(params[target]);
      }

      sum += params[target];
    }

    return value >= sum;
  },
  params: [
    { name: "target1", isTarget: true },
    { name: "target2", isTarget: true },
    { name: "fieldNames" },
  ],
  message: "Значение должно быть больше или равно сумме {fieldNames}.",
};

extend("decimal", {
  validate: (val) => /^[\s0-9]+([.,][0-9]+)?$/.test(val),
  message: "Поле {_field_} должно быть числом и может содержать запятую.",
});

extend("integer", {
  validate: (val) => /^[\s0-9]+$/.test(val),
  message: "Поле {_field_} должно быть числом.",
});

extend("max_value_integer", maxValueSchema);
extend("max_value_decimal", maxValueSchema);

extend("less_then", {
  validate: (value, { target }) => value < target,
  params: [{ name: "target", isTarget: true }, { name: "fieldName" }],
  message: "Значение должно быть меньше или равно {fieldName}.",
});

extend("gte_then_sum_of_2", gteThenSumOf2Schema);
extend("gte_then_sum_of_2_1", gteThenSumOf2Schema);

extend("gte_then_sum_of_4", {
  validate: (value, params) => {
    if (typeof value === "string") {
      value = numberFormatReverse(value);
    }

    const targetNames = ["target1", "target2", "target3", "target4"];
    let sum = 0;

    for (let target of targetNames) {
      if (typeof params[target] === "string") {
        params[target] = numberFormatReverse(params[target]);
      }

      sum += params[target];
    }

    return value >= sum;
  },
  params: [
    { name: "target1", isTarget: true },
    { name: "target2", isTarget: true },
    { name: "target3", isTarget: true },
    { name: "target4", isTarget: true },
    { name: "fieldNames" },
  ],
  message: "Значение должно быть больше или равно сумме {fieldNames}.",
});

setInteractionMode("eager");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
