import { render, staticRenderFns } from "./ProjectDocument.vue?vue&type=template&id=393f85a5&"
import script from "./ProjectDocument.vue?vue&type=script&lang=js&"
export * from "./ProjectDocument.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDocument.vue?vue&type=style&index=0&id=393f85a5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QSeparator,QBadge,QBtn,QImg,QInput,QIcon,QPopupProxy,QDate});qInstall(component, 'directives', {ClosePopup});
