import cloneDeep from "lodash.clonedeep";

/**
 * Modify search params for use in GET request
 */
export function getProjectsTableQueryForRequest(values) {
  let q = cloneDeep(values);

  // q-select map-options slows down ui
  if (q.metroSelected) {
    q.metro_ids = q.metroSelected.map((item) => item.id);
    delete q["metroSelected"];
  }

  // q-select map-options slows down ui
  if (q.railwaySelected) {
    q.railway_ids = q.railwaySelected.map((item) => item.id);
    delete q["railwaySelected"];
  }

  if (q.coordinates) {
    let coordinates = cloneDeep(q.coordinates);

    if (coordinates && coordinates.length) {
      // table "coordinates" field format is lat-lng
      const splitted = coordinates.split(", ");

      q.coordinates = { longitude: null, latitude: null };
      q.coordinates.longitude = +splitted[1];
      q.coordinates.latitude = +splitted[0];
    }
  }

  // clear queries with NULL "v" properties
  Object.keys(q).forEach((prop) => {
    if (!q[prop]) {
      return;
    }

    if (
      q[prop].hasOwnProperty("c") &&
      q[prop].hasOwnProperty("v") &&
      q[prop].v === null
    ) {
      delete q[prop];
    }
  });

  // remove all empty arrays
  Object.keys(q).forEach((prop) => {
    if (Array.isArray(q[prop]) && !q[prop].length) {
      delete q[prop];
    }
  });

  return q;
}
