import Vue from "vue";
import VueRouter from "vue-router";
import { isAccessTokenExpiresSoon } from "../utils/batch";
import routes from "./routes";
import store from "../store";
import { actions as authActions } from "../store/auth/actions";
import { lf } from "../plugins/localforage";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (
      to.name === from.name &&
      to.matched.some((routeDef) => routeDef.meta.preventScroll)
    ) {
      return false;
    }

    return { y: 0 };
  },
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  if (await isAccessTokenExpiresSoon()) {
    const refreshToken = await lf.getItem("refresh_token");

    if (refreshToken) {
      await store.dispatch(`auth/${authActions.refreshToken}`, refreshToken);
    }
  }

  return next();
});

export default router;
