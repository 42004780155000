import { axiosInstance as axios } from "@/plugins/axios";

const metro = {
  find(include = "") {
    return include
      ? axios.get(`/metro`, { params: { include } })
      : axios.get(`/metro`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/metro", data, { params: { include } })
      : axios.post("/metro", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/metro/${id}`, data, { params: { include } })
      : axios.patch(`/metro/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/metro/${id}`);
  },
};

export default metro;
