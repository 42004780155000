import { axiosInstance as axios } from "@/plugins/axios";

const area = {
  find(include = "") {
    return include
      ? axios.get(`/areas`, { params: { include } })
      : axios.get(`/areas`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/areas", data, { params: { include } })
      : axios.post("/areas", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/areas/${id}`, data, { params: { include } })
      : axios.patch(`/areas/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/areas/${id}`);
  },
};

export default area;
