<template>
  <q-dialog
    ref="dialog"
    transition-hide="slide-up"
    transition-show="slide-down"
    @hide="onDialogHide"
  >
    <q-card class="q-pa-md bg-white" style="width: 400px; max-width: 400px">
      <draggable
        v-model="myColumns"
        @change="onChange"
        @end="drag = false"
        @start="drag = true"
      >
        <transition-group name="flip-list" type="transition">
          <div
            v-for="item in myColumns"
            :key="item.name"
            class="bg-white q-pa-xs full-width rounded-borders"
            style="border: 1px solid #c0c0c0; cursor: move"
          >
            {{ item.label }}
          </div>
        </transition-group>
      </draggable>
    </q-card>
  </q-dialog>
</template>

<script>
  import { mutations as authMutations } from "@/store/auth/mutations";
  import { customSort } from "@/utils/batch";
  import draggable from "vuedraggable";

  export default {
    name: "TableColumnOrderDialog",

    props: {
      columns: {
        type: Array,
        required: true,
      },
    },

    components: {
      draggable,
    },

    data() {
      return {
        awaitResponse: false,
        drag: false,
        myColumns: [],
      };
    },

    created() {
      this.myColumns = customSort(this.columns, "order");
    },

    methods: {
      save() {
        this.awaitResponse = true;

        this.$root.$emit("change-column-order", { columns: this.myColumns });

        let settings = {
          projectsColumnOrder: this.myColumns.map((item) => item.name),
        };

        this.$api.user
          .updateSettings(this.$store.getters["auth/userId"], { settings })
          .then(
            (res) => {
              this.$store.commit(
                `auth/${authMutations.SET_USER_SETTINGS}`,
                res.data.user.settings
              );
            },
            (error) => {
              if (error.response && error.response.status !== 500) {
                this.$q.notify({
                  color: "negative",
                  message: error.response.data.message,
                });
              }
            }
          )
          .then(() => {
            this.awaitResponse = false;
          });
      },

      onChange(event) {
        // update all column order property
        this.myColumns = this.myColumns.map((item, index) => {
          item.order = index;

          return item;
        });

        this.save();
      },

      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>

<style lang="scss">
  .flip-list-move {
    transition: transform 0.5s;
  }
</style>