import { axiosInstance as axios } from "@/plugins/axios";

const centrifugo = {
  token() {
    return axios.post("/centrifugo/token");
  },

  publish(channel, payload) {
    return axios.post("/centrifugo/publish", { channel, payload });
  },

  presence(channel) {
    return axios.get(`/centrifugo/presence/${channel}`);
  },
};

export default centrifugo;
