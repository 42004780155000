import { lf } from "@/plugins/localforage";
import Notify from 'quasar/src/plugins/Notify.js';;

/**
 * @return {Promise<void>}
 */
export async function removeAuthDataFromStorage() {
  try {
    await lf.removeItem("access_token");
    await lf.removeItem("refresh_token");
    await lf.removeItem("expires_in");
  } catch (e) {
    Notify.create({
      color: "negative",
      message:
        "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
      timeout: 60000,
    });
  }
}

/**
 * @param {String} accessToken
 * @param {String} refreshToken
 * @param {Number} expiresIn
 * @return {Promise<void>}
 */
export async function setAuthDataToStorage(
  accessToken,
  refreshToken,
  expiresIn
) {
  try {
    await lf.setItem("access_token", accessToken);
    await lf.setItem("refresh_token", refreshToken);
    await lf.setItem("expires_in", expiresIn);
  } catch (e) {
    Notify.create({
      color: "negative",
      message:
        "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
      timeout: 60000,
    });
  }
}

/**
 * Is access token expires soon or expired
 * @returns {boolean}
 */
export async function isAccessTokenExpiresSoon() {
  const expiresIn = 1800; // update tokens if access token expires soon (less then 30 min)
  const accessTokenExpiresIn = await lf.getItem("expires_in"); // expires_in as timestamp
  const timeLeft = accessTokenExpiresIn - Math.ceil(Date.now() / 1000);

  return timeLeft < expiresIn;
}

/**
 * @returns {Promise<void>}
 */
export async function reloadPageIfTokenExpiresSoon() {
  const accessToken = await lf.getItem("access_token");
  const refreshToken = await lf.getItem("refresh_token");

  if (accessToken && refreshToken && (await isAccessTokenExpiresSoon())) {
    window.location.reload();
  }
}

/**
 * Reload page if access token expire soon.
 */
export function reloadPageIfTokenExpireSoonInterval() {
  setInterval(reloadPageIfTokenExpiresSoon, 60000);
}

/**
 *
 * @param {Object} obj
 */
export function deleteKeysWithNullValues(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
}

/**
 * @type {Object}
 */
export const METRO_LINE_COLOR_MAPPING = {
  "moskovskoe-centralnoe-kolco": "#E4B6DF",
  "sokolnicheskaya-liniya": "#E42518",
  "kolcevaya-liniya": "#925233",
  "zamoskvoreckaya-liniya": "#4BAF4F",
  "bolshaya-kolcevaya-liniya": "#88CBCE",
  "arbatsko-pokrovskaya-liniya": "#0572B9",
  "filevskaya-liniya": "#24BCEF",
  "kaluzhsko-rizhskaya-liniya": "#EF7E24",
  "tagansko-krasnopresnenskaya-liniya": "#943F90",
  "kalininskaya-liniya": "#FFCD17",
  "serpuhovsko-timiryazevskaya-liniya": "#ADABAB",
  "moskovskaya-monorelsovaya-transportnaya-sistema": "#93FDF1",
  "lyublinsko-dmitrovskaya-liniya": "#BED12E",
  "kahovskaya-liniya": "#88CBCE",
  "butovskaya-liniya-legkogo-metro": "#BAC6E6",
  "solncevskaya-liniya": "#FFCD17",
  "nekrasovskaya-liniya": "#F871AC",
};

/**
 * @type {string[]}
 */
export const hexColors = [
  "#027f70",
  "#739105",
  "#0c8714",
  "#065a84",
  "#542178",
  "#b73f10",
  "#0ba82d",
  "#0a1868",
  "#169108",
  "#b25d01",
  "#270d91",
  "#09a56c",
  "#d69b13",
  "#5e7502",
  "#5d8904",
  "#047c3c",
  "#0c737c",
  "#992133",
  "#9f10d3",
  "#aa1153",
  "#0e9e17",
  "#06656d",
  "#099304",
  "#b26e00",
  "#024d6d",
];

/**
 *
 * @param {String} fileName
 * @return {string|null}
 */
export function extractFileExtension(fileName) {
  if (/[.]/.test(fileName)) {
    return fileName.split(".").reverse()[0].toLowerCase();
  }

  return null;
}

/**
 * @param {String} fileName
 * @return {boolean}
 */
export function isImage(fileName) {
  const ext = extractFileExtension(fileName);

  if (!ext) {
    return false;
  }

  const imageExtensions = ["jpg", "jpeg", "png", "bmp", "gif"];

  return imageExtensions.includes(ext.toLowerCase());
}

/**
 * @param {Array} data
 * @param {String} property
 * @param {String} direction
 * @return {Array}
 */
export function customSort(data, property, direction = "desc") {
  if (direction === "asc") {
    return data.sort((a, b) => {
      return a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
    });
  }

  return data.sort((a, b) => {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  });
}

export function normalizeNumber(number) {
  let temp = number.split(":");

  temp.forEach((part, index) => {
    let splitted = part.split("");

    for (let i = 0; i < splitted.length; i++) {
      if (Number(splitted[i]) > 0 || i === splitted.length - 1) {
        break;
      }

      delete splitted[i];
    }

    temp[index] = splitted.join("");
  });

  return temp.join(":");
}

export function camelCase(str) {
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, " ")
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
}

export function sepCase(str, sep = "-") {
  return str
    .replace(/[A-Z]/g, (letter, index) => {
      const lcLet = letter.toLowerCase();
      return index ? sep + lcLet : lcLet;
    })
    .replace(/([-_ ]){1,}/g, sep);
}
