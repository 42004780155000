// endpoints
import centrifugo from "./endpoints/centrifugo";
import auth from "./endpoints/auth";
import user from "./endpoints/user";
import lists from "./endpoints/lists";
import role from "./endpoints/role";
import permission from "./endpoints/permission";
import project from "./endpoints/project";
import comment from "./endpoints/comment";
import address from "./endpoints/address";
import ap from "./endpoints/ap";
import bizRegion from "./endpoints/bizRegion";
import area from "./endpoints/area";
import city from "./endpoints/city";
import cityArea from "./endpoints/cityArea";
import cityDistrict from "./endpoints/cityDistrict";
import country from "./endpoints/country";
import developer from "./endpoints/developer";
import developmentStage from "./endpoints/developmentStage";
import estateClass from "./endpoints/estateClass";
import estateSubject from "./endpoints/estateSubject";
import estateType from "./endpoints/estateType";
import federalRegion from "./endpoints/federalRegion";
import metro from "./endpoints/metro";
import privacyLevel from "./endpoints/privacyLevel";
import projectKind from "./endpoints/projectKind";
import projectStatus from "./endpoints/projectStatus";
import railway from "./endpoints/railway";
import region from "./endpoints/region";
import sourceInfo from "./endpoints/sourceInfo";
import specificRegion from "./endpoints/specificRegion";

const api = {
  centrifugo,
  auth,
  user,
  lists,
  role,
  permission,
  project,
  comment,
  address,
  ap,
  bizRegion,
  area,
  city,
  cityArea,
  cityDistrict,
  country,
  developer,
  developmentStage,
  estateClass,
  estateSubject,
  estateType,
  federalRegion,
  metro,
  privacyLevel,
  projectKind,
  projectStatus,
  railway,
  region,
  sourceInfo,
  specificRegion,
};

export default api;
