<template>
  <div class="col col-md-10 q-pa-md bg-white rounded-borders shadow-2">
    <ValidationObserver ref="observer" slim>
      <form
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        autocorrect="off"
      >
        <fieldset :disabled="awaitResponse" class="no-border q-px-none">
          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Имя</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Имя"
                rules="required|min:2|max:50"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="name"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Email</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Email"
                rules="required|min:2|max:50"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="email"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Пароль</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Пароль"
                rules="required|min:5|max:256"
                v-slot="{ errors }"
              >
                <q-input
                  v-model="password"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  dense
                />
              </ValidationProvider>
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Роль</div>
            <div class="col-15 col-md-18">
              <q-select
                v-model="roleSelected"
                :options="roles"
                :option-value="(opt) => opt"
                option-label="readable_name"
                bottom-slots
                dense
              />
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Статус</div>
            <div class="col-15 col-md-18">
              <q-select
                v-model="status"
                :options="userStatuses"
                bottom-slots
                map-options
                emit-value
                dense
              />
            </div>
          </div>

          <div class="row flex items-center">
            <div class="col-9 col-md-6 text-grey-8">Регионы</div>
            <div class="col-15 col-md-18">
              <ValidationProvider
                name="Регионы"
                rules="required"
                v-slot="{ errors }"
              >
                <q-select
                  v-model="bizRegionsSelected"
                  :options="authUserBizRegions"
                  :error="errors && !!errors.length"
                  :error-message="errors[0]"
                  bottom-slots
                  map-options
                  dense
                  options-dense
                  use-chips
                  multiple
                  option-label="name"
                  option-value="id"
                />
              </ValidationProvider>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>

    <div class="row">
      <q-btn
        label="Добавить"
        color="primary"
        :loading="awaitCreating"
        :disable="awaitCreating"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: "UserAdd",

    meta() {
      return {
        title: this.title,
      };
    },

    data() {
      return {
        awaitResponse: false,
        awaitCreating: false,
        name: null,
        email: null,
        password: null,
        roleSelected: null,
        bizRegionsSelected: null,
        status: 3,
        roles: [],
        bizRegions: [],
      };
    },

    computed: {
      authUserBizRegions() {
        return this.$store.getters["auth/user"].biz_regions;
      },

      userStatuses() {
        return [
          {
            label: "Активен",
            value: 3,
          },
          {
            label: "Заблокирован",
            value: 5,
          },
        ];
      },
    },

    async created() {
      this.awaitResponse = true;

      let [resRoles] = await Promise.all([this.$api.role.find()]);

      this.roles = resRoles.data.filter(
        (role) => this.$store.getters["auth/firstRole"].level > role.level
      );
      this.bizRegionsSelected = [this.authUserBizRegions[0]];

      this.roleSelected = this.roles[0];
      this.awaitResponse = false;
    },

    methods: {
      save() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.awaitCreating = true;

            let data = {
              name: this.name,
              email: this.email,
              password: this.password,
              role_id: this.roleSelected.id,
              status: this.status,
              biz_region_ids: this.bizRegionsSelected.map((r) => r.id),
            };

            this.$api.user
              .create(data)
              .then(
                (res) => {
                  this.$q.notify({
                    color: "positive",
                    message: res.data.message,
                  });

                  this.$router.push({ name: "UserList" });
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });

                  if (error.response.status === 422) {
                    Object.keys(error.response.data.errors).forEach(
                      (name, i) => {
                        this.$q.notify({
                          color: "negative",
                          message: error.response.data.errors[name][i],
                          timeout: 10000,
                        });
                      }
                    );
                  }
                }
              )
              .then(() => {
                this.awaitCreating = false;
              });
          }
        });
      },
    },
  };
</script>