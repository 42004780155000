import multiguard from "vue-router-multiguard";
import auth from "../middleware/auth";
import checkPermissions from "../middleware/checkPermissions";

// base components
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Map from "../pages/Map";

// route batches
import projectRoutes from "./routes/projectRoutes";
import listRoutes from "./routes/listRoutes";
import settingsRoutes from "./routes/settingsRoutes";

const baseRoutes = [
  {
    path: "/",
    redirect: "projects",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: multiguard([auth]),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/map",
    name: "Map",
    component: Map,
    beforeEnter: multiguard([auth, checkPermissions]),
    meta: {
      layout: "main",
      crumbs: "/Карта",
      permissions: ["project.view"],
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

let routes = baseRoutes.concat(projectRoutes, listRoutes, settingsRoutes);

export default routes;
