/**
 * Children of Settings Routes
 */

import PermissionAdd from "../../../pages/settings/permissions/PermissionAdd";
import PermissionList from "../../../pages/settings/permissions/PermissionList";
import multiguard from "vue-router-multiguard";
import checkPermissions from "../../../middleware/checkPermissions";

const permissionRoutes = [
  {
    path: "",
    name: "PermissionList",
    component: PermissionList,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["permission.view"],
      crumbs: "/Настройки/Разрешения",
    },
  },
  {
    path: "add",
    name: "PermissionAdd",
    component: PermissionAdd,
    beforeEnter: multiguard([checkPermissions]),
    meta: {
      layout: "main",
      permissions: ["permission.add"],
      crumbs: "/Настройки/Разрешения/Добавить",
    },
  },
];

export default permissionRoutes;
