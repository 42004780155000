<template>
  <div class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="loading" indeterminate size="2px" />
    </div>

    <div class="row q-pa-md">
      <div class="col-24 col-md-16">
        <ProjectTableStatistics
          v-if="+$can(['actualization.view'])"
          :actualizationStatistics="actualizationStatistics"
        />
      </div>

      <div class="col-24 col-md-8 flex justify-end">
        <div class="flex q-gutter-x-md justify-end">
          <q-btn
            v-if="+$can(['project.add'])"
            :to="{ name: 'ProjectAdd' }"
            icon="mdi-plus"
            label="Новый проект"
          />

          <q-btn
            v-if="+$can(['project.export'])"
            :disable="awaitDownloadExcel"
            :loading="awaitDownloadExcel"
            icon="mdi-file-download-outline"
            label="Excel"
            @click="downloadExcel"
          />

          <q-btn icon="mdi-cog" label="Параметры">
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columns"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @input="onInputVisibleColumns"
                    >
                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item
                          v-bind="scope.itemProps"
                          v-on="scope.itemEvents"
                        >
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>

                <q-item
                  v-close-popup
                  clickable
                  @click="showTableColumnOrderDialog"
                >
                  <q-item-section side>
                    <q-icon name="mdi-numeric" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Порядок столбцов</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Проекты"
          class="l-table l-table--sticky-column"
          role="table"
          :style="getStylesForProjectsTable()"
        >
          <div
            class="l-table__row l-sticky bg-white"
            role="rowgroup"
            style="top: 0; z-index: 2"
          >
            <div
              v-for="item in columns"
              v-if="visibleColumns.includes(item.name)"
              :key="item.name"
              :style="getCellStyle(item.name)"
              class="l-table__cell"
              role="columnheader"
            >
              <div
                :class="item.sortName && 'cursor-pointer'"
                class="text-body3"
                @click="item.sortName && sortByColumn(item.sortName)"
              >
                {{ item.label }}
                <q-icon
                  v-if="pagination.sortBy === item.sortName"
                  :name="
                    pagination.descending ? 'mdi-arrow-down' : 'mdi-arrow-up'
                  "
                />
              </div>
            </div>
          </div>

          <ProjectTableFiltersRow
            :cellStyles="cellStyles"
            :pagination="pagination"
            :search="search"
            :visibleColumns="visibleColumns"
            @filter-projects="filterProjects"
            @set-projects="setProjectsAndStatistics"
          />

          <div
            v-for="item in projects"
            :key="item.id"
            class="l-table__row"
            role="rowgroup"
          >
            <q-menu
              context-menu
              touch-position
              transition-hide=""
              transition-show=""
            >
              <q-list separator>
                <q-item class="q-pa-none" clickable>
                  <router-link
                    :title="item.name"
                    :to="`/projects/${item.id}/edit`"
                    class="l-link flex self-stretch items-center cursor-pointer text-black q-px-md"
                    target="_blank"
                  >
                    В новой вкладке
                  </router-link>
                </q-item>
                <q-item
                  clickable
                  @click="
                    $router.push({
                      name: 'ProjectEdit',
                      params: { id: item.id },
                    })
                  "
                >
                  <q-item-section>Перейти</q-item-section>
                </q-item>
              </q-list>
            </q-menu>

            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell"
            >
              {{ item.id }}
            </div>

            <div
              v-if="visibleColumns.includes('biz_region')"
              :style="getCellStyle('biz_region')"
              class="l-table__cell"
            >
              {{ item.biz_region.name }}
            </div>

            <div
              v-if="visibleColumns.includes('name')"
              :style="getCellStyle('name')"
              class="l-table__cell"
            >
              <router-link
                :title="item.name"
                :to="`/projects/${item.id}/edit`"
                class="l-link cursor-pointer text-primary"
              >
                {{ item.name }}
              </router-link>
            </div>

            <div
              v-if="visibleColumns.includes('address')"
              :style="getCellStyle('address')"
              class="l-table__cell"
            >
              {{ item.address && item.address.name }}
            </div>

            <div
              v-if="visibleColumns.includes('coordinates')"
              :style="getCellStyle('coordinates')"
              class="l-table__cell"
            >
              {{ formatCoordinates(item.address) }}
            </div>

            <div
              v-if="visibleColumns.includes('specific_region')"
              :style="getCellStyle('specific_region')"
              class="l-table__cell"
            >
              {{
                item.address &&
                item.address.specific_region &&
                item.address.specific_region.name
              }}
            </div>

            <div
              v-if="visibleColumns.includes('city_area')"
              :style="getCellStyle('city_area')"
              class="l-table__cell"
            >
              {{
                item.address &&
                item.address.city_area &&
                item.address.city_area.short_name
              }}
            </div>

            <div
              v-if="visibleColumns.includes('city_district')"
              :style="getCellStyle('city_district')"
              class="l-table__cell"
            >
              {{
                item.address &&
                item.address.city_district &&
                item.address.city_district.name
              }}
            </div>

            <div
              v-if="visibleColumns.includes('project_status')"
              :style="getCellStyle('project_status')"
              class="l-table__cell"
            >
              {{ item.project_status && item.project_status.name }}
            </div>

            <div
              v-if="visibleColumns.includes('project_kind')"
              :style="getCellStyle('project_kind')"
              class="l-table__cell"
            >
              {{ item.project_kind && item.project_kind.name }}
            </div>

            <div
              v-if="visibleColumns.includes('developers')"
              :style="getCellStyle('developers')"
              class="l-table__cell"
            >
              {{
                item.developers &&
                item.developers.map((item) => item.name).join(", ")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('rightholder')"
              :style="getCellStyle('rightholder')"
              class="l-table__cell"
            >
              {{ item.rightholder }}
            </div>

            <div
              v-if="visibleColumns.includes('development_stage')"
              :style="getCellStyle('development_stage')"
              class="l-table__cell"
            >
              {{ item.development_stage && item.development_stage.name }}
            </div>

            <div
              v-if="visibleColumns.includes('privacy_level')"
              :style="getCellStyle('privacy_level')"
              class="l-table__cell"
            >
              {{ item.privacy_level && item.privacy_level.name }}
            </div>

            <div
              v-if="visibleColumns.includes('sales_start')"
              :style="getCellStyle('sales_start')"
              class="l-table__cell"
            >
              {{ item.sales_start }}
            </div>

            <div
              v-if="visibleColumns.includes('estate_types')"
              :style="getCellStyle('estate_types')"
              class="l-table__cell"
            >
              {{
                item.estate_types &&
                item.estate_types.map((item) => item.name).join(", ")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('estate_subject')"
              :style="getCellStyle('estate_subject')"
              class="l-table__cell"
            >
              {{ item.estate_subject && item.estate_subject.name }}
            </div>

            <div
              v-if="visibleColumns.includes('estate_classes')"
              :style="getCellStyle('estate_classes')"
              class="l-table__cell"
            >
              {{
                item.estate_classes &&
                item.estate_classes.map((item) => item.name).join(", ")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('source_infos')"
              :style="getCellStyle('source_infos')"
              class="l-table__cell"
            >
              {{
                item.source_infos &&
                item.source_infos.map((item) => item.name).join(", ")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('square')"
              :style="getCellStyle('square')"
              class="l-table__cell"
            >
              {{ item.square }}
            </div>

            <div
              v-if="visibleColumns.includes('area_square')"
              :style="getCellStyle('area_square')"
              class="l-table__cell"
            >
              {{ item.area_square }}
            </div>

            <div
              v-if="visibleColumns.includes('surface_square')"
              :style="getCellStyle('surface_square')"
              class="l-table__cell"
            >
              {{ item.surface_square }}
            </div>

            <div
              v-if="visibleColumns.includes('underground_square')"
              :style="getCellStyle('underground_square')"
              class="l-table__cell"
            >
              {{ item.underground_square }}
            </div>

            <div
              v-if="visibleColumns.includes('floor_square')"
              :style="getCellStyle('floor_square')"
              class="l-table__cell"
            >
              {{ item.floor_square }}
            </div>

            <div
              v-if="visibleColumns.includes('living_square')"
              :style="getCellStyle('living_square')"
              class="l-table__cell"
            >
              {{ item.living_square }}
            </div>

            <div
              v-if="visibleColumns.includes('non_living_square')"
              :style="getCellStyle('non_living_square')"
              class="l-table__cell"
            >
              {{ item.non_living_square }}
            </div>

            <div
              v-if="visibleColumns.includes('renovation_square')"
              :style="getCellStyle('renovation_square')"
              class="l-table__cell"
            >
              {{ item.renovation_square }}
            </div>

            <div
              v-if="visibleColumns.includes('flat_square')"
              :style="getCellStyle('flat_square')"
              class="l-table__cell"
            >
              {{ item.flat_square }}
            </div>

            <div
              v-if="visibleColumns.includes('apartment_square')"
              :style="getCellStyle('apartment_square')"
              class="l-table__cell"
            >
              {{ item.apartment_square }}
            </div>

            <div
              v-if="visibleColumns.includes('psn_square')"
              :style="getCellStyle('psn_square')"
              class="l-table__cell"
            >
              {{ item.psn_square }}
            </div>

            <div
              v-if="visibleColumns.includes('surface_parking_qty')"
              :style="getCellStyle('surface_parking_qty')"
              class="l-table__cell"
            >
              {{ item.surface_parking_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('underground_parking_qty')"
              :style="getCellStyle('underground_parking_qty')"
              class="l-table__cell"
            >
              {{ item.underground_parking_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('renovation_qty')"
              :style="getCellStyle('renovation_qty')"
              class="l-table__cell"
            >
              {{ item.renovation_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('flat_qty')"
              :style="getCellStyle('flat_qty')"
              class="l-table__cell"
            >
              {{ item.flat_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('apartment_qty')"
              :style="getCellStyle('apartment_qty')"
              class="l-table__cell"
            >
              {{ item.apartment_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('psn_qty')"
              :style="getCellStyle('psn_qty')"
              class="l-table__cell"
            >
              {{ item.psn_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('max_height')"
              :style="getCellStyle('max_height')"
              class="l-table__cell"
            >
              {{ item.max_height }}
            </div>

            <div
              v-if="visibleColumns.includes('max_floor')"
              :style="getCellStyle('max_floor')"
              class="l-table__cell"
            >
              {{ item.max_floor }}
            </div>

            <div
              v-if="visibleColumns.includes('building_density')"
              :style="getCellStyle('building_density')"
              class="l-table__cell"
            >
              {{ item.building_density }}
            </div>

            <div
              v-if="visibleColumns.includes('cadastrals')"
              :style="getCellStyle('cadastrals')"
              class="l-table__cell"
            >
              <span style="white-space: pre">{{
                formatCadastral(item.cadastrals)
              }}</span>
            </div>

            <div
              v-if="visibleColumns.includes('metro')"
              :style="getCellStyle('metro')"
              class="l-table__cell"
            >
              <span style="white-space: pre">{{
                formatTransportStations(item, "Metro")
              }}</span>
            </div>

            <div
              v-if="visibleColumns.includes('railway')"
              :style="getCellStyle('railway')"
              class="l-table__cell"
            >
              <span style="white-space: pre">{{
                formatTransportStations(item, "Railway")
              }}</span>
            </div>

            <div
              v-if="visibleColumns.includes('actualization_status')"
              :style="getCellStyle('actualization_status')"
              class="l-table__cell"
            >
              <div class="flex justify-center">
                <div
                  :class="getActualizationClass(item.actualization.status)"
                  class="l-actualization-status"
                />
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('actualized_at')"
              :style="getCellStyle('actualized_at')"
              class="l-table__cell"
            >
              {{ item.actualization.actualized_at }}
            </div>

            <div
              v-if="visibleColumns.includes('actualized_by')"
              :style="getCellStyle('actualized_by')"
              class="l-table__cell"
            >
              {{
                item.actualization.actualizer &&
                item.actualization.actualizer.name
              }}
            </div>

            <div
              v-if="visibleColumns.includes('comment')"
              :style="getCellStyle('comment')"
              class="l-table__cell"
            >
              {{
                (item.comments && item.comments.slice(-1)[0].comment)
                  | shortness(100)
              }}
            </div>

            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell"
            >
              {{ item.created_at }}
            </div>

            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell"
            >
              <div class="flex no-wrap">
                <q-btn
                  :to="{ name: 'ProjectEdit', params: { id: item.id } }"
                  flat
                  icon="mdi-pencil"
                />
                <q-btn
                  v-if="+$can(['actualization.update'])"
                  flat
                  label="Закрыть"
                  title="Актуализировать"
                  @click="actualizeProject(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$_isEmpty(projects)" class="q-pa-md">Нет данных</div>

      <div
        v-if="projects && projects.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <q-select
          v-model="pagination.rowsPerPage"
          :disable="loading"
          :options="[15, 50]"
          borderless
          dense
          @input="onRowsPerPageInput"
        >
          <template v-slot:before>
            <span class="text-body3 text-black">Строк на странице:</span>
          </template>
        </q-select>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          @input="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { exportFile } from "quasar";
  import ProjectTableStatistics from "@/components/projects/ProjectTableStatistics";
  import ProjectTableFiltersRow from "@/components/projects/ProjectTableFiltersRow";
  import TableColumnOrderDialog from "@/components/dialogs/TableColumnOrderDialog";
  import { getProjectsTableQueryForRequest } from "@/utils/project";
  import { customSort } from "@/utils/batch";
  import { mutations as authMutations } from "@/store/auth/mutations";

  /**
   * Для функционала изменения порядка колонок таблицы есть 2 распространенных способа:
   * 1. flexbox order;
   * 2. манипулирование dom элементами через удаление и создание элементов. Так работают
   *    jquery-based плагины - просто вырезают все колонки (el) и вставляют куда потребуется.
   *    Производительность такого подхода оставляет желать лучшего.
   *    Тот же devextreme использует jquery плагин для реализации такой фичи.
   *    https://js.devexpress.com/Documentation/Guide/Widgets/TreeList/Columns/Column_Reordering/
   *    Скорость отрисовки таблицы увеличивается на ~0.5 сек. Не знаю как вам, а меня такие
   *    затупы раздражают, особенно осозновая необходимость подключения jquery. Можно манипулировать
   *    dom вручную, но это заметно усложняет чтение и поддержку кода.
   *
   * Для порядка колонок был применен подход flexbox order.
   *
   * Почему мы не используем functional components & render function для table cell:
   * 1. С одной стороны мы бы избавились от дублирования кода типа v-if, class, style для каждой cell.
   *    С другой стороны, использование компонента обязало бы писать <template v-slot> и
   *    выносить visibleColumns в store (а иначе профит от отдельного компонента минимален),
   *    и не использовать functional components (cause computed для, как минимум, visible true\false),
   *    что очень негативно скажется на производительности, потому что на странице может быть
   *    до columns.length * rowsPerPage, т.е. в рядовом случае 20 * 50 = 1000 компонентов
   *    (а максимально до ~5000).
   * 2. Использование вложенных компонентов ухудшает поддержку кода. "Все явное лучше неявного".
   */
  export default {
    name: "ProjectList",

    components: {
      ProjectTableStatistics,
      ProjectTableFiltersRow,
      TableColumnOrderDialog,
    },

    meta: {
      title: "Проекты",
    },

    data() {
      return {
        loading: false,
        awaitActualization: false,
        awaitDownloadExcel: false,
        pagination: {
          rowsNumber: null,
          rowsPerPage: 15,
          sortBy: "created_at",
          descending: true,
          page: 1,
        },
        visibleColumns: [
          "id",
          "name",
          "address",
          "project_status",
          "privacy_level",
          "development_stage",
          "actions",
        ],
        projects: [],
        actualizationStatistics: null,
        columns: [
          {
            name: "id",
            label: "ID",
            sortName: "id",
            visible: true,
            order: 1,
            style: "width: 80px;",
          },
          {
            name: "biz_region",
            label: "Регион (бизнес)",
            sortName: "bizRegion.name",
            visible: true,
            order: 2,
            style: "width: 160px;",
          },
          {
            name: "name",
            label: "Название",
            sortName: "name",
            visible: true,
            order: 3,
            style: "width: 200px;",
          },
          {
            name: "address",
            label: "Адрес",
            sortName: "address.name",
            visible: true,
            order: 4,
            style: "width: 300px;",
          },
          {
            name: "coordinates",
            label: "Координаты",
            visible: true,
            order: 5,
            style: "width: 160px;",
          },
          {
            name: "specific_region",
            label: "Локация",
            sortName: "address.specificRegion.name",
            visible: true,
            order: 6,
            style: "width: 160px;",
          },
          {
            name: "city_area",
            label: "АО (адм. округ)",
            sortName: "address.cityArea.name",
            visible: this.visibleByRegionSetting("address_city_area"),
            order: 7,
            style: "width: 120px;",
          },
          {
            name: "city_district",
            label: "Район",
            sortName: "address.cityDistrict.name",
            visible: true,
            order: 8,
            style: "width: 200px;",
          },
          {
            name: "project_status",
            label: "Статус",
            sortName: "projectStatus.name",
            visible: true,
            order: 9,
            style: "width: 160px;",
          },
          {
            name: "project_kind",
            label: "Тип проекта",
            sortName: "projectKind.name",
            visible: true,
            order: 10,
            style: "width: 160px;",
          },
          {
            name: "developers",
            label: "Девелоперы",
            visible: true,
            order: 11,
            style: "width: 160px;",
          },
          {
            name: "rightholder",
            label: "Правообладатель",
            sortName: "rightholder",
            visible: true,
            order: 12,
            style: "width: 160px;",
          },
          {
            name: "development_stage",
            label: "Стадия проработки",
            sortName: "developmentStage.name",
            visible: true,
            order: 13,
            style: "width: 220px;",
          },
          {
            name: "privacy_level",
            label: "Конфиденциальность",
            sortName: "privacyLevel.name",
            visible: true,
            order: 14,
            style: "width: 160px;",
          },
          {
            name: "sales_start",
            label: "Старт продаж",
            sortName: "sales_start",
            visible: true,
            order: 15,
            style: "width: 120px;",
          },
          {
            name: "estate_types",
            label: "Типы недвижимости",
            visible: true,
            order: 16,
            style: "width: 160px;",
          },
          {
            name: "estate_subject",
            label: "Формат проекта",
            sortName: "estateSubject.name",
            visible: true,
            order: 17,
            style: "width: 160px;",
          },
          {
            name: "estate_classes",
            label: "Класс",
            visible: true,
            order: 18,
            style: "width: 160px;",
          },
          {
            name: "source_infos",
            label: "Источники инфы",
            visible: true,
            order: 19,
            style: "width: 200px;",
          },
          {
            name: "square",
            label: "S участка",
            sortName: "square",
            visible: true,
            order: 20,
            style: "width: 100px;",
          },
          {
            name: "area_square",
            label: "S проекта",
            sortName: "area_square",
            visible: true,
            order: 21,
            style: "width: 100px;",
          },
          {
            name: "surface_square",
            label: "S наземная",
            sortName: "surface_square",
            visible: true,
            order: 22,
            style: "width: 100px;",
          },
          {
            name: "underground_square",
            label: "S подземная",
            sortName: "underground_square",
            visible: true,
            order: 23,
            style: "width: 100px;",
          },
          {
            name: "floor_square",
            label: "S сумм. поэтажная",
            sortName: "floor_square",
            visible: true,
            order: 24,
            style: "width: 100px;",
          },
          {
            name: "living_square",
            label: "S жилая",
            sortName: "living_square",
            visible: true,
            order: 25,
            style: "width: 100px;",
          },
          {
            name: "non_living_square",
            label: "S нежилая",
            sortName: "non_living_square",
            visible: true,
            order: 26,
            style: "width: 100px;",
          },
          {
            name: "renovation_square",
            label: "S реновации",
            sortName: "renovation_square",
            visible: true,
            order: 27,
            style: "width: 100px;",
          },
          {
            name: "flat_square",
            label: "S квартир",
            sortName: "flat_square",
            visible: true,
            order: 28,
            style: "width: 100px;",
          },
          {
            name: "apartment_square",
            label: "S апартаментов",
            sortName: "apartment_square",
            visible: true,
            order: 29,
            style: "width: 100px;",
          },
          {
            name: "psn_square",
            label: "S ПСН",
            sortName: "psn_square",
            visible: true,
            order: 30,
            style: "width: 100px;",
          },
          {
            name: "surface_parking_qty",
            label: "Наземн. паркинг, м/м",
            sortName: "surface_parking_qty",
            visible: true,
            order: 31,
            style: "width: 100px;",
          },
          {
            name: "underground_parking_qty",
            label: "Подземн. паркинг, м/м",
            sortName: "underground_parking_qty",
            visible: true,
            order: 32,
            style: "width: 100px;",
          },
          {
            name: "renovation_qty",
            label: "Реновация, шт",
            sortName: "renovation_qty",
            visible: true,
            order: 33,
            style: "width: 100px;",
          },
          {
            name: "flat_qty",
            label: "Квартир, шт",
            sortName: "flat_qty",
            visible: true,
            order: 34,
            style: "width: 100px;",
          },
          {
            name: "apartment_qty",
            label: "Апартаментов, шт",
            sortName: "apartment_qty",
            visible: true,
            order: 35,
            style: "width: 100px;",
          },
          {
            name: "psn_qty",
            label: "ПСН, шт",
            sortName: "psn_qty",
            visible: true,
            order: 36,
            style: "width: 100px;",
          },
          {
            name: "max_height",
            label: "Предельная высота",
            sortName: "max_height",
            visible: true,
            order: 37,
            style: "width: 100px;",
          },
          {
            name: "max_floor",
            label: "Макс. этаж",
            sortName: "max_floor",
            visible: true,
            order: 38,
            style: "width: 100px;",
          },
          {
            name: "building_density",
            label: "Плотность застройки",
            sortName: "building_density",
            visible: true,
            order: 39,
            style: "width: 100px;",
          },
          {
            name: "cadastrals",
            label: "Кадастры",
            visible: true,
            order: 40,
            style: "width: 160px;",
          },
          {
            name: "metro",
            label: "Метро",
            visible: this.visibleByRegionSetting("metro"),
            order: 41,
            style: "width: 180px;",
          },
          {
            name: "railway",
            label: "ЖД станции",
            visible: this.visibleByRegionSetting("railway"),
            order: 42,
            style: "width: 180px;",
          },
          {
            name: "actualization_status",
            label: "Актуальность",
            sortName: "actualization.status",
            visible: +this.$can(["actualization.view"]),
            order: 43,
            style: "width: 120px; justify-content: center;",
          },
          {
            name: "actualized_at",
            label: "Дата актуализации",
            sortName: "actualization.actualized_at",
            visible: true,
            order: 44,
            style: "width: 160px;",
          },
          {
            name: "actualized_by",
            label: "Кто актуализировал",
            sortName: "actualization.actualized_by",
            visible: +this.$can(["user.list.view"]),
            order: 45,
            style: "width: 160px;",
          },
          {
            name: "comment",
            label: "Комментарий",
            visible: true,
            order: 46,
            style: "width: 250px;",
          },
          {
            name: "created_at",
            label: "Создан",
            sortName: "created_at",
            visible: true,
            order: 47,
            style: "width: 160px;",
          },
          {
            name: "actions",
            label: "Действия",
            visible: true,
            order: 48,
            style: "width: 160px;",
          },
        ],
        search: {
          id: { c: "eq", v: null },
          name: { c: "ctn", v: null },
          address: { c: "ctn", v: null },
          coordinates: null,
          biz_region_ids: null,
          specific_region_ids: null,
          city_district_ids: null,
          city_area_ids: null,
          project_status_ids: null,
          project_kind_ids: null,
          privacy_level_ids: null,
          development_stage_ids: null,
          estate_subject_ids: null,
          estate_type_ids: null,
          estate_class_ids: null,
          source_info_ids: null,
          developer_ids: null,
          rightholder: { c: "ctn", v: null },
          sales_start: null,
          square: { c: "lte", v: null },
          area_square: { c: "lte", v: null },
          surface_square: { c: "lte", v: null },
          underground_square: { c: "lte", v: null },
          floor_square: { c: "lte", v: null },
          living_square: { c: "lte", v: null },
          non_living_square: { c: "lte", v: null },
          renovation_square: { c: "lte", v: null },
          flat_square: { c: "lte", v: null },
          apartment_square: { c: "lte", v: null },
          psn_square: { c: "lte", v: null },
          surface_parking_qty: { c: "lte", v: null },
          underground_parking_qty: { c: "lte", v: null },
          renovation_qty: { c: "lte", v: null },
          flat_qty: { c: "lte", v: null },
          apartment_qty: { c: "lte", v: null },
          psn_qty: { c: "lte", v: null },
          max_height: { c: "lte", v: null },
          max_floor: { c: "lte", v: null },
          building_density: { c: "lte", v: null },
          cadastral: { c: "ctn", v: null },
          metroSelected: [],
          railwaySelected: [],
          actualization_statuses: [],
          actualized_at: {
            from: null,
            to: null,
          },
          actualized_by_ids: null,
          created_at: {
            from: null,
            to: null,
          },
        },
        cellStyles: {},
      };
    },

    created() {
      this.$on("filter-projects", this.filterProjects);
      this.$on("set-projects", this.setProjectsAndStatistics);
      this.$root.$on("change-column-order", this.onChangeColumnOrder);
    },

    async mounted() {
      // filter by permissions or something else
      this.columns = this.columns.filter((item) => item.visible);

      await this.checkVisibleColumns(); // checkVisibleColumns after filter columns
      await this.syncColumnSettings();

      this.updateColumnOrderByUserSettings();
      this.sortColumnsByOrder();
      this.updateColumnStyles(this.columns);

      await this.loadRowsPerPageParamFromStorage();
    },

    destroyed() {
      this.$off("filter-projects", this.filterProjects);
      this.$off("set-projects", this.setProjectsAndStatistics);
      this.$root.$off("change-column-order", this.onChangeColumnOrder);
    },

    methods: {
      filterProjects() {
        this.setProjectsAndStatistics(true);
      },

      async setProjectsAndStatistics(isFiltering = false) {
        this.loading = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        let paginateOptions = {
          q: getProjectsTableQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const include = [
          "address.specific_region",
          "address.city_area",
          "address.city_district",
          "project_status",
          "privacy_level",
          "development_stage",
          "estate_classes",
          "estate_types",
          "estate_subject",
          "source_infos",
          "developers",
          "cadastrals",
          "transport_stations.station",
          "actualization.actualizer",
          "project_kind",
          "comments",
          "biz_region",
        ];

        await Promise.all([
          this.$api.project.find(paginateOptions, include.join(",")),
          this.$api.project.actualizationStatistics(
            paginateOptions,
            "actualization"
          ),
        ])
          .then((results) => {
            if (results[0].status === 200 && results[0].data.projects) {
              this.projects = results[0].data.projects;
              this.pagination.rowsNumber =
                results[0].data.meta.pagination.total;
            }

            if (results[0].status === 204) {
              this.projects = [];
            }

            this.actualizationStatistics = results[1].data;
          })
          .then(() => {
            this.loading = false;
          });
      },

      actualizeProject(project) {
        this.awaitActualization = true;

        this.$api.project
          .actualize(project.id, "actualization.actualizer")
          .then((res) => {
            const index = this.projects.indexOf(project);
            this.projects[index].actualization = res.data.project.actualization;
          })
          .then(() => {
            let data = {
              q: getProjectsTableQueryForRequest(this.search),
            };

            return this.$api.project
              .actualizationStatistics(data, "actualization")
              .then((res) => {
                this.actualizationStatistics = res.data;
              });
          })
          .then(() => {
            this.awaitActualization = false;
          });
      },

      /**
       * Show formatted data in table
       */
      formatCadastral(data) {
        return data && data.map((item) => `${item.number}`).join("\n");
      },

      /**
       * Show formatted data in table
       */
      formatTransportStations(row, entity) {
        if (!row.transport_stations) {
          return null;
        }

        return row.transport_stations
          .filter((item) => item.station.entity === entity)
          .map((item) => item.station.name)
          .join("\n");
      },

      /**
       * Show formatted data in table
       */
      formatCoordinates(address) {
        if (!(address && address.coordinates && address.coordinates.length)) {
          return;
        }

        const coordinates = [...address.coordinates];

        return coordinates.reverse().join(", ");
      },

      getActualizationClass(status) {
        switch (status) {
          case 9:
            return "l-actualization-status--green";
          case 6:
            return "l-actualization-status--yellow";
          case 3:
            return "l-actualization-status--red";
        }
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns); // refresh sticky column
        this.saveVisibleColumnsInStorage(values);
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await this.$lf.setItem("projects_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      async checkVisibleColumns() {
        let visibleColumns = await this.$lf.getItem(
          "projects_table_visible_columns"
        );

        if (visibleColumns) {
          const columnNames = this.columns.map((item) => item.name);

          // remove old column names
          visibleColumns.forEach((name, index) => {
            if (!columnNames.includes(name)) {
              delete visibleColumns[index];
            }
          });

          this.visibleColumns = visibleColumns;
          await this.saveVisibleColumnsInStorage(visibleColumns);
        }
      },

      downloadExcel() {
        this.awaitDownloadExcel = true;

        const columns = this.visibleColumns.filter(
          (columnName) => columnName !== "actions"
        );

        const include = [
          "address.specific_region",
          "project_status",
          "project_kind",
          "privacy_level",
          "development_stage",
          "estate_classes",
          "estate_types",
          "estate_subject",
          "source_infos",
          "developers",
          "cadastrals",
          "transport_stations.station",
          "actualization.actualizer",
          "project_kind",
          "comments",
        ];

        let params = {
          q: getProjectsTableQueryForRequest(this.search),
          sort_by: this.pagination.sortBy,
          descending: this.pagination.descending,
          page: 1,
          columns: JSON.stringify(columns),
          include: include.join(","),
        };

        this.$api.project
          .downloadExcel(params)
          .then((res) => {
            const fileName = res.headers["content-disposition"].slice(21);

            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });

            exportFile(fileName, blob);
          })
          .then(() => {
            this.awaitDownloadExcel = false;
          });
      },

      onPaginationInput(page) {
        this.setProjectsAndStatistics();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      async onRowsPerPageInput(val) {
        try {
          await this.$lf.setItem("projects_table_rows_per_page", val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setProjectsAndStatistics();
      },

      sortByColumn(field) {
        this.pagination.sortBy = field;
        this.pagination.descending = !this.pagination.descending;

        this.setProjectsAndStatistics();
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      /**
       * Sync user column settings with frontend columns
       */
      async syncColumnSettings() {
        const user = this.$store.getters["auth/user"];

        if (user.settings && user.settings.projectsColumnOrder) {
          const userColumnNames = user.settings.projectsColumnOrder;
          const columnNames = this.columns.map((i) => i.name);

          if (
            userColumnNames.length !== columnNames.length ||
            columnNames.some((name) => !userColumnNames.includes(name))
          ) {
            // replace user settings by default columns order
            let settings = {
              projectsColumnOrder: this.columns.map((item) => item.name),
            };

            await this.$api.user
              .updateSettings(this.$store.getters["auth/userId"], { settings })
              .then((res) => {
                this.$store.commit(
                  `auth/${authMutations.SET_USER_SETTINGS}`,
                  res.data.user.settings
                );
              });
          }
        }
      },

      updateColumnOrderByUserSettings() {
        const user = this.$store.getters["auth/user"];

        if (user.settings && user.settings.projectsColumnOrder) {
          this.columns.forEach((item, index) => {
            const foundIndex = user.settings.projectsColumnOrder.findIndex(
              (colName) => colName === item.name
            );

            if (foundIndex === -1) {
              throw new Error(
                `can not find "${item.name}" column in user's column settings`
              );
            }

            this.$set(this.columns[index], "order", foundIndex);
          });
        }
      },

      onChangeColumnOrder(payload) {
        this.sortColumnsByOrder(); // sort to show actual order in visibleColumns select
        this.updateColumnStyles(payload.columns);
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style + `order:${item.order};`;
        });

        const firstVisibleColumn = this.columns.filter((item) =>
          this.visibleColumns.includes(item.name)
        )[0];

        obj[firstVisibleColumn.name] +=
          "position: sticky; left: 0; z-index: 1; background-color: #fff; border-right: 1px solid #e0e0e0;";

        this.cellStyles = obj;
      },

      sortColumnsByOrder() {
        customSort(this.columns, "order");
      },

      async loadRowsPerPageParamFromStorage() {
        const rowsPerPage = await this.$lf.getItem(
          "projects_table_rows_per_page"
        );

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      showTableColumnOrderDialog() {
        this.$q.dialog({
          component: TableColumnOrderDialog,
          parent: this,
          // props
          columns: this.columns,
        });
      },

      visibleByRegionSetting(name) {
        return this.$store.getters["auth/user"].biz_regions.some(
          (item) => item.settings[name]
        );
      },

      getStylesForProjectsTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 235 : 210);

        return `max-height: ${height}px`;
      },
    },
  };
</script>
