import store from "../store";
import router from "../router";
import { lf } from "../plugins/localforage";
import { actions as authActions } from "../store/auth/actions";

const serfRoutesDeniedForAuthUsers = ["Login"];
const routesDeniedForUnAuthUsers = [
  "ProjectList",
  "ProjectEdit",
  "ProjectAdd",
  "UserList",
  "UserEdit",
  "UserDetails",
  "Map",
  "AreaList",
  "DevelopmentStageList",
  "DistrictList",
  "EstateClassList",
  "EstateSubjectList",
  "EstateTypeList",
  "MetroList",
  "PrivacyLevelList",
  "ProjectStatusList",
  "RailwayList",
  "RegionList",
  "SourceDetailList",
  "SourceList",
  "PermissionList",
  "PermissionAdd",
  "RoleList",
  "RoleEdit",
];
const firstViewRoutesAllowedForAuthUsers = [];

/**
 * Auth.
 * return next() means "show requested page or next middleware"
 *
 * @param next
 * @param from
 * @param to
 * @return {Promise<*>}
 */
export default async function (to, from, next) {
  const accessToken = await lf.getItem("access_token");
  const refreshToken = await lf.getItem("refresh_token");
  const user = store.getters["auth/user"];

  if (accessToken && refreshToken) {
    if (user) {
      // serf auth
      // запрещаем авторизованному юзеру ходить по этим страницам
      if (serfRoutesDeniedForAuthUsers.includes(to.name)) {
        return router.push("/");
      } else {
        return next();
      }
    } else {
      // first view
      const res = await store.dispatch(`auth/${authActions.fetchUser}`);

      if (res && res.status === 200) {
        if (firstViewRoutesAllowedForAuthUsers.includes(to.name)) {
          return next();
        } else {
          return router.push({
            name: to.name,
            params: to.params,
            query: to.query,
          });
        }
      } else {
        // first view un auth
        return router.push({ name: "Login" });
      }
    }
  } else {
    // serf or first view unauthorized user
    // запрещаем НЕавторизованному юзеру ходить по этим страницам
    if (routesDeniedForUnAuthUsers.includes(to.name)) {
      return router.push({ name: "Login" });
    } else {
      return next();
    }
  }
}
