<template>
  <div class="flex justify-center items-center" style="height: 100vh">
    <div class="text-h4">404 Не найдено</div>
  </div>
</template>

<script>
  export default {
    name: "NotFound",

    meta: {
      title: "404 Не найдено",
    },
  };
</script>