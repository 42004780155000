import { axiosInstance as axios } from "@/plugins/axios";

const privacyLevel = {
  find(include = "") {
    return include
      ? axios.get(`/privacy-levels`, { params: { include } })
      : axios.get(`/privacy-levels`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/privacy-levels", data, { params: { include } })
      : axios.post("/privacy-levels", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/privacy-levels/${id}`, data, { params: { include } })
      : axios.patch(`/privacy-levels/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/privacy-levels/${id}`);
  },
};

export default privacyLevel;
