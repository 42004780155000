<template>
  <div class="col col-md-18">
    <div class="bg-white">
      <q-table
        :data="bizRegions"
        :columns="columns"
        :filter="filter"
        :loading="loading"
        :pagination.sync="pagination"
        hide-pagination
        row-key="id"
        dense
      >
        <template v-slot:top-left>
          <q-btn
            v-if="+$can(['biz-region.add'])"
            label="Добавить"
            icon="mdi-plus"
            @click="$router.push({ name: 'BizRegionAdd' })"
          />
        </template>

        <template v-slot:top-right>
          <q-input
            v-model="filter"
            dense
            outlined
            debounce="300"
            color="primary"
          >
            <template v-slot:append>
              <q-icon name="mdi-magnify" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="id" :props="props">{{ props.row.id }}</q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="codes" :props="props"
              >{{ props.row.codes.join(", ") }}
            </q-td>
            <q-td key="total_users" :props="props"
              >{{
                props.row.users && props.row.users.length
                  ? props.row.users.length
                  : 0
              }}
            </q-td>
            <q-td key="settings" style="white-space: pre" :props="props"
              >{{ formatSettings(props.row) }}
            </q-td>
            <q-td key="actions" :props="props">
              <q-btn
                :to="{ name: 'BizRegionEdit', params: { id: props.row.id } }"
                :v-if="+$can(['biz-region.update'])"
                flat
                dense
                icon="mdi-pencil"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BizRegionList",

    meta: {
      title: "Регионы (бизнес)",
    },

    async created() {
      this.loading = true;
      const res = await this.$api.bizRegion.find("users");
      this.bizRegions = res.data;
      this.loading = false;
    },

    computed: {
      columns() {
        return [
          {
            name: "id",
            required: true,
            label: "ID",
            align: "left",
            field: (row) => row.id,
            sortable: true,
          },
          {
            name: "name",
            required: true,
            label: "Название",
            align: "left",
            field: (row) => row.name,
            sortable: true,
          },
          {
            name: "codes",
            required: true,
            label: "Коды",
            align: "left",
            field: (row) => row.codes,
            sortable: true,
          },
          {
            name: "total_users",
            required: true,
            label: "Пользователей",
            align: "left",
            field: (row) => row.users,
            sortable: true,
          },
          {
            name: "settings",
            required: true,
            label: "Настройки",
            align: "left",
            field: (row) => row.settings,
            sortable: false,
          },
          {
            name: "actions",
            label: "Действия",
            align: "right",
          },
        ];
      },
    },

    data() {
      return {
        loading: false,
        filter: "",
        pagination: {
          rowsPerPage: 0,
        },
        bizRegions: [],
      };
    },

    methods: {
      formatSettings(row) {
        if (!row.settings) {
          return null;
        }

        let result = "";

        Object.keys(row.settings).forEach((key) => {
          if (!row.settings[key]) {
            return;
          }

          if (key === "metro") {
            result += "Метро\n";
          }

          if (key === "railway") {
            result += "Ж/Д станции\n";
            return "Ж/Д станции";
          }

          if (key === "renovation") {
            result += "Программа реновации\n";
          }

          if (key === "address_city_area") {
            result += "Административный округ\n";
          }
        });

        return result;
      },
    },
  };
</script>