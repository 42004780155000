<template>
  <div class="col col-md-12">
    <ListsTable entity="source-info" />
  </div>
</template>

<script>
  import ListsTable from "@/components/ListsTable";

  export default {
    name: "SourceInfoList",

    components: {
      ListsTable,
    },

    meta: {
      title: "Источники информации",
    },
  };
</script>