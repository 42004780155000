<template>
  <div class="row justify-center q-pb-lg">
    <div class="col-24">
      <ProjectHeader :project="project" :viewers="viewers">
        <template v-slot:buttons>
          <q-btn
            v-if="+$can(['media.add'])"
            class="q-ml-md"
            color="negative"
            icon="mdi-plus"
            label="Загрузить"
            @click="showUploadImagesDialog"
          />
        </template>
      </ProjectHeader>
    </div>

    <div class="col-24">
      <div class="row q-col-gutter-xs">
        <div
          v-for="item in media"
          :key="item.id"
          :class="$q.screen.xs || $q.screen.sm ? 'col-12' : 'col-6'"
          class="l-thumb cursor-pointer relative-position"
        >
          <q-img
            :ratio="1"
            :src="item.url_thumb || item.url"
            @click="showSingleImageDialog(item)"
          >
            <template v-slot:error>
              <div
                class="absolute-full flex flex-center text-center column bg-grey-6 text-white"
              >
                <q-icon name="mdi-image-broken-variant" size="48px" />
                <div class="text-subtitle2">Ошибка загрузки превью.</div>
              </div>
            </template>
          </q-img>

          <q-btn
            :disable="awaitDeleting"
            class="l-thumb__delete-btn absolute-top-right q-mx-xs q-mt-sm shadow-7"
            color="white"
            dense
            icon="mdi-delete-outline"
            round
            text-color="black"
            title="Удалить"
            @click="remove(item)"
          />
        </div>
      </div>

      <q-banner
        v-if="!media.length && !awaitResponse"
        class="bg-grey-4 full-width"
      >
        Нет данных
      </q-banner>
    </div>
  </div>
</template>

<script>
  import ProjectHeader from "@/components/projects/ProjectHeader";
  import ProjectUploadImagesDialog from "@/components/dialogs/ProjectUploadImagesDialog";
  import SingleImageDialog from "@/components/dialogs/SingleImageDialog";

  export default {
    name: "ProjectPhoto",

    props: {
      project: {
        type: Object,
        required: true,
      },
      viewers: {
        type: Array,
        required: true,
      },
    },

    components: {
      ProjectHeader,
      ProjectUploadImagesDialog,
      SingleImageDialog,
    },

    data() {
      return {
        awaitResponse: false,
        awaitDeleting: false,
        media: [],
      };
    },

    async created() {
      this.$root.$on("project-images-uploaded", this.projectImagesUploaded);

      await this.setMedia();
    },

    beforeDestroy() {
      this.$root.$off("project-images-uploaded", this.projectImagesUploaded);
    },

    methods: {
      remove(image) {
        this.awaitDeleting = true;

        this.$api.project
          .deleteImage(this.project.id, image.id)
          .then(
            (res) => {
              const index = this.media.indexOf(image);

              this.media.splice(index, 1);

              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitDeleting = false;
          });
      },

      async setMedia() {
        this.awaitResponse = true;

        const res = await this.$api.project.findById(this.project.id, "media");

        if (res.status === 200) {
          this.media = res.data.media ? res.data.media : [];
        }

        this.awaitResponse = false;
      },

      projectImagesUploaded(payload) {
        this.media.push(...payload.media);
      },

      showUploadImagesDialog() {
        this.$q.dialog({
          component: ProjectUploadImagesDialog,
          parent: this,
          // props
          project: this.project,
        });
      },

      showSingleImageDialog(image) {
        this.$q.dialog({
          component: SingleImageDialog,
          // props
          image: image,
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../styles/mq.scss";

  .l-thumb {
    &:hover .l-thumb__delete-btn {
      display: flex;
    }

    &__delete-btn {
      @include mq($from: sm) {
        display: none;
      }
    }
  }
</style>