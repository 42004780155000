import { axiosInstance as axios } from "@/plugins/axios";

const projectKind = {
  find(include = "") {
    return include
      ? axios.get(`/project-kinds`, { params: { include } })
      : axios.get(`/project-kinds`);
  },

  create(data, include = "") {
    return include
      ? axios.post("/project-kinds", data, { params: { include } })
      : axios.post("/project-kinds", data);
  },

  update(id, data, include = "") {
    return include
      ? axios.patch(`/project-kinds/${id}`, data, { params: { include } })
      : axios.patch(`/project-kinds/${id}`, data);
  },

  delete(id) {
    return axios.delete(`/project-kinds/${id}`);
  },
};

export default projectKind;
