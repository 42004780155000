export const mutations = {
  SET_USER: "SET_USER",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  UNSET_USER: "UNSET_USER",
  UNSET_PERMISSIONS: "UNSET_PERMISSIONS",
  SET_USER_SETTINGS: "SET_USER_SETTINGS",
};

export default {
  [mutations.SET_USER](state, user) {
    state.user = user;
  },

  [mutations.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },

  [mutations.UNSET_USER](state) {
    state.user = null;
  },

  [mutations.UNSET_PERMISSIONS](state) {
    state.permissions = [];
  },

  [mutations.SET_USER_SETTINGS](state, settings) {
    state.user.settings = settings;
  },
};
