var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"row",style:(_vm.getStylesForSubmenu())},[_c('div',{staticClass:"col-24 col-md-5 col-lg-3 bg-white"},[_c('q-list',{staticClass:"l-menu q-pa-md rounded-borders text-primary",attrs:{"disabled":_vm.loading}},_vm._l((_vm.menuItems),function(item){return _c('q-item',{key:item.component,attrs:{"active":_vm.component === item.component,"active-class":"l-menu__item--active","clickable":""},on:{"click":function($event){_vm.component = item.component}}},[_c('q-item-section',[_vm._v(_vm._s(item.label))])],1)}),1)],1),_c('div',{staticClass:"col-24 col-md-19 col-lg-21"},[_c('div',{staticClass:"row justify-center"},[(Object.keys(_vm.project).length && !_vm.loading)?_c('div',{staticClass:"col-24",class:!(_vm.$q.screen.xs || _vm.$q.screen.sm) ? 'q-px-xl' : ''},[_c(_vm.component,{tag:"component",attrs:{"lists":{
              projectStatuses: _vm.projectStatuses,
              privacyLevels: _vm.privacyLevels,
              developmentStages: _vm.developmentStages,
              estateClasses: _vm.estateClasses,
              estateSubjects: _vm.estateSubjects,
              estateTypes: _vm.estateTypes,
              developers: _vm.developers,
              sourceInfos: _vm.sourceInfos,
              projectKinds: _vm.projectKinds,
            },"project":_vm.project,"viewers":_vm.viewers}})],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }