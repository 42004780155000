import Vue from "vue";

import "../styles/app.scss";
import iconSet from "quasar/icon-set/mdi-v5.js";
import lang from "quasar/lang/ru.js";
import "@quasar/extras/mdi-v5/mdi-v5.css";
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';import Ripple from 'quasar/src/directives/Ripple.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';;

Vue.use(Quasar, {
  config: {
    notify: {
      message: "",
      timeout: 1000,
      actions: [{ icon: "mdi-close", color: "white" }],
      position: "top",
    },
  },
  directives: {
    Ripple,
    ClosePopup,
  },
  plugins: {
    Dialog,
    Meta,
    Notify,
  },
  lang: lang,
  iconSet: iconSet,
});
